import angular from 'angular';
import userStatisticsComponent from './user-statistics.component';
import bookmarkStatisticsComponent from './bookmark-statistics.component';
import groupStatistics from './group-statistics.component';
import favoriteStatistics from './favorite-statistics.component';
import marketingNotificationStatistics from './marketing-notification-statistics.component';

const module = angular.module('app.shell.modules.statistics.components', [
]);

module.component('userStatistics', userStatisticsComponent);
module.component('bookmarkStatistics', bookmarkStatisticsComponent);
module.component('favoriteStatistics', favoriteStatistics);
module.component('groupStatistics', groupStatistics);
module.component('marketingNotificationStatistics', marketingNotificationStatistics);

export default module.name;
