// @ngInject
export function refreshTokenConfig($httpProvider, jwtInterceptorProvider) {

    // @ngInject
    jwtInterceptorProvider.tokenGetter = tokenAccessorFactory => {
        return tokenAccessorFactory.tokenGetter();
    };

    $httpProvider.interceptors.push('jwtInterceptor');
}

// @ngInject
export function imgSrcSanitizationWhitelistConfig($compileProvider) {
    $compileProvider.imgSrcSanitizationWhitelist(/^(https?|\/api\/|\.\/studio|\.\/shell)/);
}
