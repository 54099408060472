import angular from 'angular';
import selectedAmountFilter from './selected-amount.filter';

const module = angular.module('app.shell.filters', [

]);

module.filter('selectedAmount', selectedAmountFilter);

export default module.name;
