import React from 'react';
import ImportPanel from './ImportPanel';
import ExportTab from './ExportPanel';

type MetaDataTabProps = {
    moduleId: string;
    ngDispatch: () => void;
};

export default function MetaDataTab({ moduleId, ngDispatch }: MetaDataTabProps): JSX.Element {
    return (
        <>
            <ImportPanel moduleId={moduleId} ngDispatch={ngDispatch} />
            <ExportTab moduleId={moduleId} />
        </>
    );
}
