import { AxiosPromise } from 'axios';
import shellApi from '../services/api';

export type Schedule = {
    id: string;
    name: string;
};

export type Publication = {
    id: string;
    name: string;
};

export type Part = {
    id: string;
    name: string;
    nodes: Part[];
    fullPath?: string;
};

export type AssessmentQExercise = {
    name: string;
    edumaticExercise: {
        showScore: boolean;
        publication: Publication;
        schedule: Schedule;
        publicationPart: Part;
    };
};

export type MediaLink = AssessmentQExercise & {
    id: string;
    createdAt: string;
};

export type AssessmentQExercisePostRequestBody = AssessmentQExercise[];

export const getSchedules = async (): Promise<Schedule[]> => {
    const {
        data: { data },
    } = await shellApi.get('/shell/assessmentq/schedules');

    return data;
};

export const getPublications = async (scheduleId: string): Promise<Publication[]> => {
    const {
        data: { data },
    } = await shellApi.get(`/shell/assessmentq/schedules/${scheduleId}/publications`);

    return data;
};

export const getParts = async (publicationId: string): Promise<Part[]> => {
    const {
        data: { data },
    } = await shellApi.get(`/shell/assessmentq/publications/${publicationId}/parts`);

    return data;
};

export const postAssessmentQExercises = async (moduleId: string, nodeId: string, assessmentQExercises: AssessmentQExercisePostRequestBody): Promise<AxiosPromise> => {
    const response = await shellApi.post(`/shell/modules/${moduleId}/table-of-content/${nodeId}/medialinks/assessmentq`, assessmentQExercises);

    return response;
};

export const patchAssessmentQExercises = async (moduleId: string, nodeId: string, id: string, payload: { name: string; showScore: boolean; }): Promise<AxiosPromise> => {
    const response = await shellApi.patch(`/shell/modules/${moduleId}/table-of-content/${nodeId}/medialinks/assessmentq/${id}`, payload);

    return response;
};
