import angular from 'angular';
import { httpErrorInterceptor, maintenanceInterceptor, apiInterceptor } from './httpInterceptors';
import authService from './auth.service';
import tokenAccessorFactory from './token-accessor-factory';
import featureToggleFactory from './feature-toggle.factory';

const module = angular.module('app.common.services', ['ngCookies']);

module.factory('httpErrorInterceptor', httpErrorInterceptor);
module.factory('apiInterceptor', apiInterceptor);
module.factory('maintenanceInterceptor', maintenanceInterceptor);
module.factory('featureToggleFactory', featureToggleFactory);
module.factory('tokenAccessorFactory', tokenAccessorFactory);

module.service('authService', authService);

export default module.name;
