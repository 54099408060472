class AppList {

    onAppEditClick(app) {
        return this.editApp({ app });
    }

    onAppDeleteClick(app) {
        return app.$destroy()
            .then(this.onAppDeleted);
    }

}

export default {
    template: `
        <div class="shell-app-list">
            <div class="shell-app-list__no-module shell-app-list__node" ng-if="!$ctrl.selectedModule">
                Please select a module.
            </div>
            <div class="shell-app-list__no-apps-found shell-app-list__node" ng-if="$ctrl.appList.length === 0 && $ctrl.selectedModule">
                No apps were found for this module.
            </div>
            <div class="shell-app-list__list shell-app-list__node" ng-if="$ctrl.appList.length > 0 && $ctrl.selectedModule" class="row">
                <ul>
                    <li ng-repeat="app in $ctrl.appList track by $index">
                        <shell-app-item
                            app="app"
                            delete-click="$ctrl.onAppDeleteClick(app)"
                            edit-click="$ctrl.onAppEditClick(app)">
                        </shell-app-item>
                    </li>
                </ul>
            </div>
        </div>
    `,
    controller: AppList,
    bindings: {
        appList: '<',
        selectedModule: '<',
        onAppDeleted: '&',
        editApp: '&',
    },
};
