import angular from 'angular';
import './klopotek-import.scss';
import component from './klopotek-import.view';
import importHistory from './components/import-history.component';
import importDetails from './components/import-details.component';

const module = angular.module('app.shell.modules.klopotekImport', []);

module
    .config(($stateProvider) => {
        // @ngInject
        $stateProvider.state('klopotek-import', {
            url: '/klopotek-import',
            template: `
           <div class="klopotek-import">
                <shell-klopotek-import></shell-klopotek-import>
            </div>
            `,
            security: (permissionService) => permissionService.canManageKlopotekImports,
        });
    })
    .component('shellKlopotekImport', component)
    .component('importHistory', importHistory)
    .component('importDetails', importDetails);

export default module.name;
