import React from 'react';
import TreeView, { flattenTree, INode } from 'react-accessible-treeview';
import { IFlatMetadata } from 'react-accessible-treeview/dist/TreeView/utils';
import { Part } from '../../../../../../api/MediaLinksApi';
import Arrow from './Arrow';
import Checkbox from './Checkbox';
import {
    determineCheckboxState, transformData, findParentIds,
} from './utils';

type TreeProps = {
    data: Part[];
    selectedParts?: string;
    onSelect: (id: string) => void;
};

function Tree({
    data,
    selectedParts, onSelect,
}: TreeProps): JSX.Element {
    const [expandedIds, setExpandedIds] = React.useState<string[]>([]);
    const isDisabled = selectedParts !== undefined;

    const handleNodeExpand = (element: INode<IFlatMetadata>, isSelected: boolean) => {
        setExpandedIds(prevExpandedIds => {
            if (!isSelected) return prevExpandedIds?.filter(id => id !== String(element.id));
            return [...prevExpandedIds, String(element.id)];
        });
    };

    return (
        <div className="form-group" data-testid="tree">
            <span className="col-md-12">Publication Parts</span>
            <div className="col-md-12 tree-container">
                {data.length > 0 && (
                    <TreeView
                        data={flattenTree({ name: 'root', children: transformData(data) })}
                        aria-label="Checkbox tree"
                        multiSelect
                        propagateSelect
                        propagateSelectUpwards
                        togglableSelect
                        onExpand={({
                            isExpanded,
                            element,
                        }) => {
                            const elementId = String(element.id);
                            setExpandedIds(prevExpandedIds => {
                                if (isExpanded) {
                                    return [...prevExpandedIds, elementId];
                                }
                                return prevExpandedIds.filter(id => elementId !== id);
                            });
                        }}
                        onSelect={({
                            element, isSelected, isHalfSelected, isBranch, isExpanded,
                        }) => {
                            if (isBranch && isExpanded) return null;
                            if (!isBranch) return onSelect(String(element.id));
                            if (!isHalfSelected) handleNodeExpand(element, isSelected);
                            return null;
                        }}
                        expandedIds={selectedParts ? undefined : expandedIds}
                        defaultSelectedIds={selectedParts ? [selectedParts] : undefined}
                        defaultExpandedIds={selectedParts ? findParentIds(data, selectedParts) : undefined}
                        nodeRenderer={({
                            element,
                            isBranch,
                            isExpanded,
                            isSelected,
                            isHalfSelected,
                            getNodeProps,
                            level,
                            handleSelect,
                            handleExpand,
                        }) => (
                            <div
                                {...getNodeProps({ onClick: handleExpand })}
                                style={{
                                    marginLeft: 40 * (level - 1),
                                }}
                            >
                                {isBranch && <Arrow isOpen={isExpanded} />}
                                <Checkbox
                                    disabled={isDisabled}
                                    label={element.name}
                                    value={determineCheckboxState(isSelected, isHalfSelected)}
                                    onClick={e => {
                                        handleSelect(e);
                                        e.stopPropagation();
                                    }}
                                />
                            </div>
                        )}
                    />
                )}

            </div>
        </div>
    );
}

export default Tree;
