import jwt from 'jwt-decode';
import api from '../services/api';
import LocalStorageKeys from '../constants/LocalStorageKeys';
import LocalStorage from './LocalStorage';

export const TOKEN_GRACE_PERIOD_IN_SECONDS = 120;

let refreshTokenPromise;

export class RefreshTokenFailedError extends Error {
    constructor(message) {
        super(message);
        this.name = this.constructor.name;
    }
}

export default function getAccessToken() {
    if (refreshTokenPromise) return refreshTokenPromise;

    const accessToken = LocalStorage.get(LocalStorageKeys.p_access_token);
    const refreshToken = LocalStorage.get(LocalStorageKeys.p_refresh_token);

    if (!accessToken || !refreshToken) return Promise.resolve(null);

    const parsedAccessToken = JSON.parse(accessToken);
    const parsedRefreshToken = JSON.parse(refreshToken);

    const decodedToken = jwt(parsedAccessToken);

    const isJwtTokenExpired = Math.floor(new Date().getTime() / 1000) + TOKEN_GRACE_PERIOD_IN_SECONDS > decodedToken.exp;

    if (isJwtTokenExpired) {
        const query = new URLSearchParams({ token: parsedRefreshToken }).toString();

        refreshTokenPromise = api
            .post(`/oauth/refresh_token?${query}`)
            .then(({ data: { access_token: newAccessToken, refresh_token: newRefreshToken } }) => {
                LocalStorage.set(LocalStorageKeys.p_access_token, JSON.stringify(newAccessToken));
                LocalStorage.set(LocalStorageKeys.p_refresh_token, JSON.stringify(newRefreshToken));

                return newAccessToken;
            })
            .catch(error => {
                LocalStorage.remove(LocalStorageKeys.p_access_token);
                LocalStorage.remove(LocalStorageKeys.p_refresh_token);

                throw (error.response?.status === 401) ? new RefreshTokenFailedError() : error;
            })
            .finally(() => {
                refreshTokenPromise = null;
            });

        return refreshTokenPromise;
    }

    return Promise.resolve(parsedAccessToken);
}
