import DeepDiff from 'deep-diff';

function cleanReq(data) {
    delete data.nodeId;
    delete data.moduleId;
    delete data.nodePath;

    data.actors.forEach(actor => {
        if (typeof actor.name !== 'undefined' && actor.name.trim() === '') {
            delete actor.name;
        }
    });
}

/* @ngInject */
export default function MiniDialog($modelFactory) {
    const model = $modelFactory('shell/modules/{moduleId}/table-of-content/{nodeId}/mini-dialogs', {
        actions: {
            post: {
                beforeRequest: request => {
                    cleanReq(request.data);
                },
            },
            update: {
                beforeRequest: request => {
                    cleanReq(request.data);
                },
            },
        },
        $getList: function (moduleId, nodeId, onlyPublishReview) { // eslint-disable-line object-shorthand
            return model.$call({
                method: 'GET',
                isArray: true,
                wrap: true,
                url: `/api/shell/modules/${moduleId}/table-of-content/${nodeId}/mini-dialogs${onlyPublishReview ? '?onlyPublishReview=true' : ''}`,
                afterRequest: response =>
                    // map inner methods array
                    response.data,
            });
        },
        $getSuggestions: function (moduleId, nodeId) { // eslint-disable-line object-shorthand

            return model.$call({
                method: 'GET',
                isArray: true,
                wrap: false,
                url: `/api/shell/modules/${moduleId}/table-of-content/${nodeId}/mini-dialogs/medialinks/suggestions`,
                afterRequest: response =>
                    // map inner methods array
                    response.data,
            });
        },
        instance: {
            $patchProductionalState: function (productionalState) { // eslint-disable-line object-shorthand
                return model.$call({
                    method: 'PATCH',
                    url: `/api/shell/modules/${this.moduleId}/table-of-content/${this.nodeId}/mini-dialogs/${this.id}/productional-state`,
                    data: this,
                    invalidateCache: true,
                    wrap: true,
                    beforeRequest: function (req) { // eslint-disable-line object-shorthand
                        req.data = { productionalState };
                    },
                });
            },
            $move: function (destinationNodeId) { // eslint-disable-line object-shorthand
                return model.$call({
                    method: 'PUT',
                    url: `/api/shell/modules/${this.moduleId}/table-of-content/${this.nodeId}/mini-dialogs/${this.id}/move`,
                    invalidateCache: true,
                    wrap: false,
                    data: {
                        destinationNodeId,
                    },
                });
            },
            $clone: function () { // eslint-disable-line object-shorthand
                return model.$call({
                    method: 'POST',
                    url: `/api/shell/modules/${this.moduleId}/table-of-content/${this.nodeId}/mini-dialogs/${this.id}/clone`,
                    invalidateCache: true,
                    wrap: true,
                });
            },
            $revert: function () { // eslint-disable-line object-shorthand
                const changes = this.$diff();
                if (changes && changes.length > 0) {
                    changes.forEach(change => DeepDiff.revertChange(this, {}, change));
                }
            },
        },
    });
    return model;
}
