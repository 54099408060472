import angular from 'angular';
import customUrlValidator from './custom-url-validator';
import customEmailValidator from './custom-email-validator';
import ngSecureHref from './ngSecureHref';
import markdownWysiwyg from './markdown-wysiwyg';

const module = angular.module('app.common.directives', []);

module.directive('customUrlValidator', customUrlValidator);
module.directive('customEmailValidator', customEmailValidator);
module.directive('ngSecureHref', ngSecureHref);
module.directive('markdownWysiwyg', markdownWysiwyg);

export default module.name;
