import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';

/* @ngInject */
export default function FAQTutorialModelFactory($modelFactory) {
    const model = $modelFactory('shell/faq/language/{languageId}/category/{categoryId}/tutorials/', {
        actions: {
            get: {
                method: 'GET',
                url: '/api/shell/faq/language/{languageId}/category/{categoryId}/tutorials/',
                override: true,
                wrap: true,
                isArray: true,
                beforeRequest: (req) => {
                    req.params = omitBy(req.data, isUndefined);
                    return req;
                },
                afterRequest: (response) => response.data,
            },
        },
        instance: {
        },
    });

    return model;
}
