import angular from 'angular';
import component from './home.view';
import subComponents from './components';

const module = angular.module('app.shell.modules.home', [
    subComponents,
    // add more modules here
]);

module
    .config($stateProvider => {
        // @ngInject
        $stateProvider
            .state('home', {
                url: '/home',
                title: 'Pakket-p SHELL',
                template: `
                    <shell-home></shell-home>
                `,
                // @ngInject
                onEnter: ($http, authService, permissionService) => {
                    $http.get('/api/my-account')
                        .then(() => {
                            if (!permissionService.canAccessHome) {
                                authService.logout();
                            }
                        });
                },
            })
            .state('auth-callback', {
                url: '/auth-callback?access_token&refresh_token&err&returnUrl',
                title: 'Login callback',
                // @ngInject
                onEnter: ($window, $state, $stateParams, authService) => {
                    authService.callback(
                        $stateParams.access_token,
                        $stateParams.refresh_token
                    );
                    if (!$stateParams.returnUrl) return $state.go('home');
                    $window.location.assign($stateParams.returnUrl);
                },
            });
    })
    .component('shellHome', component);

export default module.name;
