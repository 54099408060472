import licenseSettingsTemplate from './license-settings.view.html';

class ShellLicenseSettings {
    onFilterChange(filter) {
        this.filter = filter;
    }
}

export default {
    template: licenseSettingsTemplate,
    controller: ShellLicenseSettings,
};
