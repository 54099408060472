import './svg-image-selector-component.scss';

class SvgImageSelectorController {
    constructor() {
        this.allowedClassNames = [
            'icon-file-audio',
            'icon-file-image',
            'icon-file-info',
            'icon-file-media',
            'icon-download-exercise',
            'icon-file-video',
            'icon-mediatype-dialogue',
            'icon-site',
            'icon-online-exercise',
            'icon-target',
            'icon-wordlist',
        ];
    }
}

export default {
    template: `
        <script type="text/ng-template" id="bootstrap/match.tpl.html">
            <div class="ui-select-match" ng-hide="$select.open && $select.searchEnabled" ng-disabled="$select.disabled" ng-class="{'btn-default-focus':$select.focus}">
                <span tabindex="-1"
                    class="btn btn-default form-control ui-select-toggle"
                    aria-label="{{ $select.baseTitle }} activate"
                    ng-disabled="$select.disabled"
                    ng-click="$select.activate()"
                    style="outline: 0;">
                    <span ng-show="$select.isEmpty()" class="ui-select-placeholder text-muted">{{$select.placeholder}}</span>
                    <span ng-hide="$select.isEmpty()" class="ui-select-match-text pull-left" ng-class="{'ui-select-allow-clear': $select.allowClear && !$select.isEmpty()}" ng-transclude=""></span>
                    <i class="caret pull-right"></i>
                </span>
            </div>
        </script>
        <div class="input-group">
            <ui-select allow-clear ng-model="$ctrl.ngModel" class="qs-svg-select" search-enabled="false">
                <ui-select-match placeholder="-- Select an icon --">
                    <div>
                        <svg class="svg-sm">
                            <use xlink:href="" ng-attr-xlink:href="{{ '#' + $select.selected }}"></use>
                        </svg>
                        <span class="svg-name" ng-bind-html="$select.selected"></span>
                    </div>
                </ui-select-match>
                <ui-select-choices repeat="item in $ctrl.allowedClassNames">
                    <div>
                        <svg class="svg-sm svg-option">
                            <use xlink:href="" ng-attr-xlink:href="{{ '#' + item }}"></use>
                        </svg>
                        <span ng-bind-html="item"></span>
                    </div>
                </ui-select-choices>
            </ui-select>
            <div class="input-group-btn">
                <button class="btn btn-default qs-svg-clear-select" type="button" title="Clear" ng-click="$ctrl.ngModel=undefined"><span class="glyphicon glyphicon-ban-circle"></span></button>
            </div>
        </div>
    `,
    controller: SvgImageSelectorController,
    bindings: {
        ngModel: '=',
    },
};
