import get from 'lodash/get';

class InviteAuthorByEmailController {

    // @ngInject
    constructor($http, AuthorModel, $exceptionHandler, $element) {
        this.$http = $http;
        this.AuthorModel = AuthorModel;
        this.$exceptionHandler = $exceptionHandler;
        this.$element = $element;
    }

    $onInit() {
        this.setFocusOnEmail();
    }

    inviteAuthor(form) {
        this.alert = undefined;

        return this.$http
            .post(`/api/shell/modules/${this.moduleId}/authors`, {
                email: this.emailAddress,
            })
            .then(res => {
                this.resetForm(form);

                const author = new this.AuthorModel(res.data);
                author.$commit();

                if (this.authorCreated) this.authorCreated({ author });
            })
            .catch(err => {
                if (err.status === 404 && get(err, 'data.message', '').indexOf("'User'")) {
                    this.alert = {
                        message: `There is no user in the P database with the e-mailaddress "${this.emailAddress}". Ask the author to login on p.pelckmans.be first.`,
                        danger: true,
                    };
                } else if (err.status === 409) {
                    this.alert = {
                        message: 'This user is already linked to this module.',
                    };
                } else {
                    this.$exceptionHandler(err);
                }

                this.resetForm(form);
            });
    }

    resetForm(form) {
        form.$setPristine();
        this.emailAddress = undefined;
        this.setFocusOnEmail();
    }

    setFocusOnEmail() {
        this.$element[0].querySelector('#emailInput').focus();
    }
}

export default {
    template: `
        <div class="row">
            <div class="col-lg-12">
                <h4 class='qs-title'>Invite an author for this module.</h4>

                <form class="form" id="inviteAuthorByEmailForm" name="inviteAuthorByEmailForm" novalidate ng-submit="inviteAuthorByEmailForm.$valid && $ctrl.inviteAuthor(inviteAuthorByEmailForm)">
                        <div class="form-group" ng-class="{ 'has-error': inviteAuthorByEmailForm.email.$dirty && inviteAuthorByEmailForm.email.$invalid }">

                            <div class="input-group">
                                <input type="email" class="form-control" id="emailInput" name="email" ng-model="$ctrl.emailAddress" required custom-email-validator placeholder="Enter the EXACT e-mailaddress of the author">
                                <span class="input-group-btn">
                                  <button type="submit" class="btn btn-success">Invite</button>
                                </span>
                            </div>

                            <div ng-messages="inviteAuthorByEmailForm.email.$error" role="alert" class="help-block" ng-if="(inviteAuthorByEmailForm.email.$dirty || inviteAuthorByEmailForm.$submitted) && inviteAuthorByEmailForm.email.$invalid">
                                <div ng-message="email">Enter a valid email address.</div>
                            </div>

                        </div>

                </form>

                <div class="alert" ng-if="$ctrl.alert" ng-class="{ 'alert-danger': $ctrl.alert.danger, 'alert-warning': !$ctrl.alert.danger }">{{ $ctrl.alert.message }}</div>
            </div>
        </div>
    `,
    controller: InviteAuthorByEmailController,
    bindings: {
        moduleId: '<',
        authorCreated: '&?',
    },
};
