import angular from 'angular';
import './studio-languages.scss';
import component from './studio-languages.view';
import languagePanel from './component/language-panel';

const module = angular.module('app.shell.modules.studioLanguages', []);

module
    .config($stateProvider => {
        // @ngInject
        $stateProvider.state('studio-languages', {
            url: '/studio-languages',
            template: `
                <shell-studio-languages></shell-studio-languages>
            `,
            security: permissionService => permissionService.canManageStudioLanguages,
        });
    })
    .component('shellStudioLanguages', component)
    .component('languagePanel', languagePanel);


export default module.name;
