import React, {
    useState,
} from 'react';
import classNames from 'classnames';
import TocHierarchyFilter from './TocHierarchyFilter';

type CopyButtonProps = {
    publishingHouseId: string;
    selectedSubjectId: string;
    selectedMethodId: string;
    selectedModuleId: string;
    disabled?: boolean;
};

export default function CopyButton({
    disabled,
    publishingHouseId,
    selectedSubjectId,
    selectedMethodId,
    selectedModuleId,
}: CopyButtonProps): JSX.Element {
    const [showHierarchyFilter, setShowHierarchyFilter] = useState(false);

    return (
        <div
            className={classNames('btn-group', 'dropdown', 'copy-toc', {
                open: showHierarchyFilter,
            })}
        >
            <button
                type="button"
                className="btn btn-success dropdown-toggle"
                disabled={disabled}
                onClick={() => setShowHierarchyFilter(!showHierarchyFilter)}
            >
                Copy table of contents
                {' '}
                <span className="caret" />
            </button>
            {showHierarchyFilter && (
                <TocHierarchyFilter
                    publishingHouseId={publishingHouseId}
                    selectedSubjectId={selectedSubjectId}
                    selectedMethodId={selectedMethodId}
                    selectedModuleId={selectedModuleId}
                    onCopy={() => setShowHierarchyFilter(false)}
                />
            )}
        </div>
    );
}
