import isString from 'lodash/isString';
import slug from '../../../core/slug';

export default () => ({
    restrict: 'A',
    require: 'ngModel',
    link: (scope, element, attributes, ngModel) => {
        ngModel.$validators.slug = function (value) {
            if (!value) return true;
            if (!isString(value)) return false;

            return slug(value) === value;
        };
    },
});
