/* @ngInject */
export default function FAQModelFactory($modelFactory) {
    const model = $modelFactory('shell/faq', {
        actions: {
            query: {
                afterRequest: response => response.data,
            },
            queryVotes: {
                method: 'GET',
                url: 'votes',
                wrap: false,
                afterRequest: res => res.data,
            },
            update: {
                beforeRequest: req => {
                    if (req.data.question === '') delete req.data.question;
                    if (req.data.frenchQuestion === '') delete req.data.frenchQuestion;

                    if (req.data.answer === '') delete req.data.answer;
                    if (req.data.frenchAnswer === '') delete req.data.frenchAnswer;

                    if (req.data.vimeoId === '') delete req.data.vimeoId;
                    if (req.data.frenchVimeoId === '') delete req.data.frenchVimeoId;
                },
            },
        },

        instance: {
            $rank: function (before, after) { // eslint-disable-line object-shorthand
                return model.$call({
                    method: 'PUT',
                    url: `/api/shell/faq/${this.id}/rank`,
                    invalidateCache: true,
                    data: {
                        before,
                        after,
                    },
                });
            },
        },
    });

    return model;
}
