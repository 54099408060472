/* @ngInject */
export default function ImportHistoryModelFactory($modelFactory) {
    return $modelFactory('shell/importhistory', {
        actions: {
            query: {
                afterRequest: response =>
                    // map inner methods array
                    response.data,
            },
        },
    });
}
