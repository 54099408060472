class IncompleteModulesController {

    // @ngInject
    constructor($http) {
        this.$http = $http;
    }

    $onInit() {
        this.$http
            .get('/api/shell/modules/incomplete')
            .then(res => {
                this.modules = res.data.data;
            });
    }
}

export default {
    template: `
    <div class="panel panel-default system-info">
        <div class="panel-heading">
            <h3 class="panel-title">Incomplete Modules</h3>
        </div>
        <div class="panel-body incomplete-modules-list">
            <div class="row incomplete-modules-list__no-result" ng-if="!$ctrl.modules || $ctrl.modules.length === 0">
                <span class="text-muted">~</span>
            </div>
            <div class="qs-subject-header" ng-repeat="(key, modulesBySubject) in $ctrl.modules | orderBy: $root.natural('method.subject.name') | groupBy: 'method.subject.id'">
                <div class="row incomplete-modules-list__subject"><div class="col-xs-12">{{ modulesBySubject[0].method.subject.name }}</div></div>
                <div class="qs-method-header" ng-repeat="(key, modulesByMethod) in modulesBySubject | orderBy: $root.natural('method.name') | groupBy: 'method.id'">
                    <div class="row incomplete-modules-list__method"><div class="col-xs-12">{{ modulesByMethod[0].method.name }}</div></div>
                    <div class="row incomplete-modules-list__item" ng-repeat="module in modulesByMethod | orderBy: $root.natural('name')">
                        <div class="col-xs-12 qs-module">{{ module.name }}</div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    `,
    controller: IncompleteModulesController,
};
