class ColorPickerController {

    $onInit() {
        this.placeholder = this.defaultColor || '#D3D3D3';
        if (this.defaultColor && !this.ngModel) {
            this.ngModel = this.defaultColor;
        }
    }
}

export default {
    template: `<div class="color-picker-component">
    <div class="color-picker__parent">

        <div class="input-group">
            <span class="input-group-addon" id="basic-addon1">
                <div class="color-picker"
                     colorpicker
                     colorpicker-close-on-select
                     colorpicker-parent="true"

                     ng-style="{'background-color': $ctrl.ngModel}"
                     ng-model="$ctrl.ngModel"

                     color-validator
                >
                </div>

                </span>

            <input type="text" id="{{$ctrl.ngId}}" class="form-control" placeholder="{{$ctrl.placeholder}}" ng-model="$ctrl.ngModel" color-validator name="{{$ctrl.name}}" aria-describedby="basic-addon1" ng-required="$ctrl.required">
        </div>
    </div>
</div>
 `,
    controller: ColorPickerController,
    bindings: {
        ngModel: '=',
        ngId: '@?',
        required: '<?',
        name: '@',
        defaultColor: '<',
    },
};
