/* @ngInject */
export default function FAQCategoryModelFactory($modelFactory) {
    const model = $modelFactory('shell/faq/categories', {
        actions: {
            query: {
                afterRequest: (response) => {
                    return response.data;
                },
            },
        },
        instance: {
            $rank: function (before, after) { // eslint-disable-line object-shorthand
                return model.$call({
                    method: 'PUT',
                    url: `/api/shell/faq/categories/${this.id}/rank`,
                    invalidateCache: true,
                    data: {
                        before,
                        after,
                    },
                });
            },
        },
    });

    return model;
}
