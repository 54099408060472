class FileUploadController {
    // @ngInject
    constructor(FileUploader, fileUploadValidationService, toaster, store, $http, $scope, config) {
        this.FileUploader = FileUploader;
        this.fileUploadValidationService = fileUploadValidationService;
        this.toaster = toaster;
        this.store = store;
        this.$http = $http;
        this.$scope = $scope;
        this.progress;
        this.statusses = {
            EMPTY: 0,
            UPLOADING: 1,
            COMPLETE: 2,
        };
        this.status = this.statusses.EMPTY;
        this.config = config;
    }

    $onInit() {
        if (this.showRemoveButton === undefined) {
            this.showRemoveButton = true;
        }
        if (this.fileName === undefined) {
            this.fileName = '';
        }
        if (this.fileId) {
            this.status = this.statusses.COMPLETE;
        }
        return this.fileUploadValidationService.initialize();
    }

    initializeUploader() {
        this.fileUploader = new this.FileUploader({
            autoUpload: true,
            removeAfterUpload: false,
            url: `${this.config.baseUrl}/s3-files`,
            onBeforeUploadItem: item => {
                const token = this.store.get('p-access_token');
                this.status = this.statusses.UPLOADING;

                if (token) {
                    item.headers = {
                        Authorization: `Bearer ${token}`,
                    };
                }
                item.formData = [{
                    moduleId: this.module,
                }];
            },
            onProgressItem: (item, progress) => {
                this.progress = progress;
            },
            onCompleteItem: (item, response, status, headers) => {
                if (item.isSuccess && status === 201) {
                    this.status = this.statusses.COMPLETE;
                    this.progress = 0;
                    this.fileName = item.file.name;
                    this.fileId = headers.location.split('/')[headers.location.split('/').length - 1];
                    this.$scope.$apply();
                    this.onComplete({ fileId: this.fileId });

                } else {
                    this.status = this.statusses.EMPTY;
                }
            },
            onWhenAddingFileFailed: (item, filter) => {
                this.status = this.statusses.EMPTY;
                if (filter.name === 'extensionAndSize') {
                    const errorMessage = this.fileUploadValidationService.validate(item.name, item.size);
                    if (this.onFail) {
                        this.onFail({ errorMessage });
                    } else {
                        this.toaster.pop('warning', errorMessage);
                    }
                }
            },
        });

        // file extension and size filter
        this.fileUploader.filters.push({
            name: 'extensionAndSize',
            fn: item => this.fileUploadValidationService.isValid(item.name, item.size),
        });


    }

    deleteFile(e) {
        e.preventDefault();
        this.status = this.statusses.EMPTY;
        this.fileName = '';
        this.fileId = '';
    }
}

export default {
    template: `<div class="file-uploader">
        <label class="input-group">
            <div class="form-control file-uploader__input">
                <span class="file-uploader__filename" ng-show="$ctrl.status !== $ctrl.statusses.UPLOADING">{{ $ctrl.fileName }}</span>
                <div class="file-uploader__progress-bar"
                    ng-show="$ctrl.status === $ctrl.statusses.UPLOADING"
                    ng-style="{ 'width': $ctrl.progress + '%' }">
                </div>
            </div>
            <input
                class="file-uploader__nv-component"
                accept="{{$ctrl.accept}}"
                type="file"
                title="Upload a file"
                nv-file-select
                uploader="$ctrl.fileUploader"
                ng-disabled="$ctrl.status === $ctrl.statusses.UPLOADING"
                ng-init="$ctrl.initializeUploader()" />
            <span class="input-group-btn">
                <span ng-click="$ctrl.deleteFile($event)" class="btn btn-default file-uploader__button--delete" ng-show="$ctrl.status === $ctrl.statusses.COMPLETE && $ctrl.showRemoveButton">
                    <span class="glyphicon glyphicon-ban-circle"></span>
                </span>
                <span class="btn btn-default file-uploader__button--upload" ng-disabled="$ctrl.status === $ctrl.statusses.UPLOADING">
                    <div class="file-uploader__progress-percentage" style="min-width: 40px;" ng-show="$ctrl.status === $ctrl.statusses.UPLOADING">{{ $ctrl.progress }}%</div>
                    <span class="glyphicon glyphicon-upload" ng-show="$ctrl.status === $ctrl.statusses.EMPTY"></span>
                    <span class="glyphicon glyphicon-pencil" ng-show="$ctrl.status === $ctrl.statusses.COMPLETE"></span>
                </span>
            </span>
        </label>
    </div>`,
    controller: FileUploadController,
    bindings: {
        module: '@',
        onComplete: '&',
        fileId: '=?',
        fileName: '=?',
        onFail: '&?',
        accept: '@?',
        showRemoveButton: '<?',
    },
};
