import angular from 'angular';
import components from './components';
import directives from './directives';
import services from './services';
import filters from './filters';

const module = angular.module('app.common', [
    components,
    directives,
    services,
    filters,
]);

export default module.name;
