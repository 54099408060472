/*
 Overwrites angular email validator.
 Tests with a pattern that behaves when input is sander@goog.
 */

export const emailExpression = /^[a-z0-9.!#$%&'*+\-/=?^_`{|}~]+@[a-z0-9.-]+\.[a-z]{2,}$/i;

export default () => {
    return {
        require: '?ngModel',
        link: (scope, element, attr, ctrl) => {

            // only apply the validator if ngModel is present and Angular has added the url validator
            if (ctrl && ctrl.$validators.email) {

                // this will overwrite the default Angular url validator
                ctrl.$validators.email = function (modelValue) {
                    return ctrl.$isEmpty(modelValue) || emailExpression.test(modelValue);
                };
            }
        },
    };
};
