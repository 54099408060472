import React from 'react';
import useMedialinksHierarchy from './useMedialinksHierarchy';

type MedialinksHierarchyFilterProps = {
    publishingHouseId: string;
    subjectId: string;
    methodId: string;
    superModuleId: string;
    moduleId: string;
    onModuleSelected: (moduleId: string) => void;
};

export default function MedialinksHierarchyFilter({
    publishingHouseId,
    subjectId,
    methodId,
    superModuleId,
    moduleId,
    onModuleSelected,
}: MedialinksHierarchyFilterProps): JSX.Element {
    const {
        subjects, methods, superModules, modules, formValues, handleChange,
    } = useMedialinksHierarchy(publishingHouseId, subjectId, methodId, superModuleId, moduleId, onModuleSelected);

    return (
        <div className="medialinks-hierarchy-filter">
            <select
                className="form-control"
                name="targetSubjectId"
                value={formValues.targetSubjectId}
                onChange={e => handleChange('targetSubjectId', e.target.value)}
            >
                {subjects.map(subject => (
                    <option key={subject.id} value={subject.id}>
                        {subject.name}
                    </option>
                ))}
            </select>

            <select
                className="form-control"
                name="targetMethodId"
                value={formValues.targetMethodId}
                onChange={e => handleChange('targetMethodId', e.target.value)}
            >
                {methods.map(method => (
                    <option key={method.id} value={method.id}>
                        {method.name}
                    </option>
                ))}
            </select>

            <select
                className="form-control"
                name="targetSuperModuleId"
                value={formValues.targetSuperModuleId}
                onChange={e => handleChange('targetSuperModuleId', e.target.value)}
                disabled={superModules.length === 0}
            >
                <option value="" disabled>-- Select a super module --</option>
                {superModules.map(superModule => (
                    <option key={superModule.id} value={superModule.id}>
                        {superModule.name}
                    </option>
                ))}
            </select>

            <select
                className="form-control"
                name="targetModuleId"
                value={formValues.targetModuleId}
                onChange={e => handleChange('targetModuleId', e.target.value)}
                disabled={modules.length === 0}
            >
                <option value="" disabled>-- Select a module --</option>
                {modules.map(module => (
                    <option key={module.id} value={module.id}>
                        {module.name}
                    </option>
                ))}
            </select>
        </div>
    );
}
