/* @ngInject */
export default function PublishingHouseModelFactory($modelFactory) {
    return $modelFactory('shell/publishinghouses', {
        actions: {
            query: {
                afterRequest: response =>
                    // map inner publishinghouses array
                    response.data,
            },

            get: {
                afterRequest: response =>
                    // map inner publishinghouses array
                    response.data[0],
            },
        },
    });
}
