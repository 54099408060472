import './marketing-notification-statistics.component.scss';

class MarketingNotificationStatisticsController {

    // @ngInject
    constructor($http) {
        this.$http = $http;
    }

    $onInit() {
        return this.$http
            .get('/api/shell/statistics/marketing-notifications', { params: { limit: 20 } })
            .then(statistics => {
                this.statistics = statistics.data;
            });
    }
}

export default {
    template: `
    <div class="panel panel-default">
        <div class="panel-heading">
            <h3 class="panel-title">Last created notifications (max. 20)</h3>
        </div>
        <div class="panel-body">
             <div class="row panel-row">
                <table class="table table-condensed" ng-if="$ctrl.statistics.data.length">
                    <thead>
                        <tr>
                            <th>Event</th>
                            <th class="text-right"># Closed</th>
                            <th class="text-right"># Permanently closed</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr ng-repeat="notification in $ctrl.statistics.data">
                            <td>{{ notification.event }}</td>
                            <td class="text-right">{{ notification.timesClosed }}</td>
                            <td class="text-right">{{ notification.timesPermanentlyClosed }}</td>
                        </tr>
                    </tbody>
                </table>
        </div>
    </div>
    `,
    controller: MarketingNotificationStatisticsController,
};
