class ThemeComponentController {
    // @ngInject
    constructor(ExerciseCategoryModel, toaster, $q) {
        this.ExerciseCategoryModel = ExerciseCategoryModel;
        this.toaster = toaster;
        this.$q = $q;
    }

    toggleAdd(toggled) {
        if (toggled) {
            if (!this.newCategory) {
                this.newCategory = new this.ExerciseCategoryModel();
                this.newCategory.name = '';
                this.newCategory.theme = {
                    id: this.theme.id,
                };
                this.newCategory.method = this.theme.method;
            }
        } else {
            this.newCategory = undefined;
        }
    }

    saveCategory(category) {
        const isNew = !category.id;

        if (this.theme.id === 'general') delete category.theme;

        return (isNew ? category.$save() : category.$patch())
            .then(x => {
                if (this.theme.id === 'general') x.theme = { id: 'general' };

                if (isNew) {
                    this.categories.push(x);
                    this.toggleAdd(false);
                } else {
                    category.$update(x);
                    category.$commit();
                }

            })
            .catch(err => {
                return err.status === 404
                    ? this.handleNotFound(category, true)
                    : this.$q.reject(err);
            });
    }

    revert(category) {
        category.$revert();
        if (!category.theme) category.theme = this.theme;
    }

    deleteCategory(category) {
        return category.$destroy()
            .catch(err => {
                switch (err.status) {
                    case 404:
                        return this.handleNotFound(category);
                    case 409:
                        return this.toaster.pop('warning', 'Forbidden', 'The category has related medialinks');
                    default:
                        return this.$q.reject(err);
                }
            });
    }

    handleNotFound(category, warn) {
        if (warn === true) this.toaster.pop('warning', `The category '${category.name}' was removed`);

        const index = this.categories.indexOf(category);
        if (index > -1) this.categories.splice(index, 1);
    }

    onChange(category, module) {
        if (!category.modules.includes(module.id)) return category.$link(module);
        return category
            .$unlink(module)
            .catch(err => {
                category.modules.push(module.id);
                switch (err.status) {
                    case 404:
                        return this.toaster.pop('warning', 'Not Found', 'The category or related module was not found');
                    case 409:
                        return this.toaster.pop('warning', 'Forbidden', 'The category has related medialinks');
                    default:
                        return this.$q.reject(err);
                }
            });
    }

}

export default {
    template: `
    <table class="table table-condensed exercise-theme__table table-bordered">
        <thead>
            <tr ng-form="themeForm" ng-if="$ctrl.theme.editMode">
                <th ng-class="{ 'has-error': themeForm.name.$invalid }">
                    <div class="th__name">
                        <input type="text" class="form-control" name="name" ng-required="true" minlength="1" maxlength="35" ng-model="$ctrl.theme.name" focus-if ng-keydown="($event.which === 27) ? $ctrl.cancel() : ($event.which === 13) ? themeForm.$valid && $ctrl.save({theme: $ctrl.theme}) : 0"/>

                        <div class="th__actions th__actions--edit">
                            <button type="submit" class="btn btn-primary btn-xs qs-theme-save" title="Save" ng-click="themeForm.$valid && $ctrl.save({theme: $ctrl.theme})">
                                <span class="glyphicon glyphicon-ok" aria-hidden="true"></span>
                            </button>
                            &nbsp;
                            <button type="button" class="btn btn-default btn-xs qs-theme-cancel" title="Cancel" ng-click="$ctrl.cancel()">
                                <span class="glyphicon glyphicon-remove"></span>
                            </button>
                        </div>
                    </div>
                </th>
                <th class="th__difficulty">
                    <span class="qs-theme-name">Difficulty</span>
                </th>
                <th ng-repeat="module in $ctrl.modules">
                    <div class="th__module-name"/>
                </th>
            </tr>
            <tr ng-if="!$ctrl.theme.editMode">
                <th>
                    <div class="th__name">
                        <span class="glyphicon glyphicon-th-list th__name__handle" ng-if="$ctrl.theme.id !== 'general'" ui-tree-handle></span>
                        &nbsp;
                        <span class="qs-theme-name th__name__theme">{{$ctrl.theme.name}}</span>
                        &nbsp;

                        <div class="th__actions">
                            <button type="button" class="btn btn-success btn-xs qs-add-category" title="New category" ng-click="$ctrl.toggleAdd(true)">
                                <span class="glyphicon glyphicon-plus" aria-hidden="true"></span> category
                            </button>
                            &nbsp;
                            <button type="button" class="btn btn-default btn-xs qs-edit-theme" title="Edit theme" ng-click="$ctrl.theme.editMode=true">
                                <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                            </button>
                            &nbsp;
                            <button
                                type="button" class="btn btn-danger btn-xs qs-delete-theme" title="Delete theme"
                                ng-click-confirmed
                                confirmed="$ctrl.delete({theme: $ctrl.theme})"
                                confirmation-title='Delete Theme'
                                confirmation-message="Are you sure you want to delete : '{{ $ctrl.theme.name }}'?"
                            >
                                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                            </button>
                        </div>
                    </div>
                </th>
                <th class="th__difficulty">
                    <span class="qs-theme-name">Difficulty</span>
                </th>
                <th ng-repeat="module in $ctrl.modules">
                    <div class="th__module-name"/>
                </th>
            </tr>
        <thead/>
        <tbody>
            <tr ng-repeat="category in filteredCategories = ($ctrl.categories | filter: { theme: { id: $ctrl.theme.id } } )" ng-form="categoryForm">
                <td ng-if="!category.editMode">
                    <span class="qs-category-name category__name">{{category.name}}</span>

                    <div class="pull-right" role="group">
                        <button type="button" class="btn btn-default btn-xs qs-edit-category" title="Edit category" ng-click="category.editMode=true">
                            <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                        </button>
                        <button
                            type="button" class="btn btn-danger btn-xs qs-delete-category" title="Delete category"
                            ng-click-confirmed
                            confirmed="$ctrl.deleteCategory(category)"
                            confirmation-title='Delete Category'
                            confirmation-message="Are you sure you want to delete : '{{ category.name }}'?"
                            >
                            <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                        </button>
                    </div>
                </td>
                <td ng-if="category.editMode" ng-class="{ 'has-error': categoryForm.name.$invalid, 'td__name': true }">
                    <input type="text" class="form-control" name="name" ng-required="true" minlength="1" maxlength="55" ng-model="category.name" focus-if ng-keydown="($event.which === 27) ? $ctrl.revert(category) : ($event.which === 13) ? categoryForm.$valid && $ctrl.saveCategory(category) : 0"/>

                    <div role="group">
                        <button type="submit" class="btn btn-primary btn-xs qs-category-save" title="Save" ng-click="categoryForm.$valid && $ctrl.saveCategory(category)"">
                            <span class="glyphicon glyphicon-ok" aria-hidden="true"></span>
                        </button>
                        &nbsp;
                        <button type="button" class="btn btn-default btn-xs qs-category-cancel" title="Cancel" ng-click="$ctrl.revert(category)">
                            <span class="glyphicon glyphicon-remove"></span>
                        </button>
                    </div>
                </td>
                <td ng-if="!category.editMode">
                    <span class="qs-category-difficulty">{{category.difficulty}}</span>
                </td>
                <td ng-if="category.editMode" class="td__difficulty">
                    <select class="form-control" ng-model="category.difficulty">
                        <option value="">--</option>
                        <option ng-value="1">1</option>
                        <option ng-value="2">2</option>
                    </select>
                </td>
                <td ng-repeat="module in $ctrl.modules" class="td__checkbox">
                    <input
                        type="checkbox"
                        name="{{module.name}}"
                        checklist-model="category.modules"
                        checklist-value="module.id"
                        ng-change="$ctrl.onChange(category, module)"
                        checked
                    >
                </td>
            </tr>
            <tr class="exercise-category--new" ng-if="$ctrl.newCategory" ng-form="categoryForm">
                <td ng-class="{ 'has-error': categoryForm.name.$invalid, 'td__name': true }">
                    <input type="text" class="form-control" name="name" ng-required="true" minlength="1" maxlength="55" ng-model="$ctrl.newCategory.name" focus-if ng-keydown="($event.which === 27) ? $ctrl.toggleAdd(false) : ($event.which === 13) ? categoryForm.$valid && $ctrl.saveCategory($ctrl.newCategory) : 0"/>

                    <div role="group">
                        <button type="submit" class="btn btn-primary btn-xs qs-category-save" title="Save" ng-click="categoryForm.$valid && $ctrl.saveCategory($ctrl.newCategory)">
                            <span class="glyphicon glyphicon-ok" aria-hidden="true"></span>
                        </button>
                        &nbsp;
                        <button type="button" class="btn btn-default btn-xs qs-category-cancel" title="Cancel" ng-click="$ctrl.toggleAdd(false)">
                            <span class="glyphicon glyphicon-remove"></span>
                        </button>
                    </div>
                </td>
                <td class="td__difficulty">
                    <select class="form-control" ng-model="$ctrl.newCategory.difficulty">
                        <option value="">-- Select difficulty --</option>
                        <option ng-value="1">1</option>
                        <option ng-value="2">2</option>
                    </select>
                </td>
                <td ng-repeat="module in $ctrl.modules" />
            </tr>
            <tr ng-if="filteredCategories.length === 0 && !$ctrl.newCategory">
                <td colspan="2" class="no-result text-muted">∞</td>
                <td ng-repeat="module in $ctrl.modules" />
            </tr>
        </tbody>
    </table>
    `,
    controller: ThemeComponentController,
    bindings: {
        theme: '=',
        categories: '=',
        modules: '=',
        save: '&?',
        cancel: '&?',
        delete: '&?',
    },
};
