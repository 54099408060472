import './user-statistics.component.scss';

class UserStatisticsController {

    // @ngInject
    constructor($http) {
        this.$http = $http;
    }

    $onInit() {
        this.teacherColor = '#2A7D55';
        this.studentColor = '#5DA074';
        this.publisherColor = '#D4846C';
        this.editorColor = '#BA5536';
        this.salesColor = '#A43820';
        this.productionColor = '#693D3D';
        this.sysadminColor = '#46211A';
        this.externalColor = '#D6AEA2';
        this.othersColor = '#DCDCDC';

        this.chartColors = [this.teacherColor, this.studentColor, this.sysadminColor, this.productionColor, this.salesColor, this.editorColor, this.publisherColor, this.externalColor, this.othersColor];

        return this.$http
            .get('/api/shell/statistics/users')
            .then(res => {
                this.statistics = res.data;
            });
    }
}

export default {
    template: `
    <div class="panel panel-default">
        <div class="panel-heading">
            <h3 class="panel-title">User stats</h3>
        </div>
        <div class="panel-body">
           <div class="row panel-row">
                <table class="table table-condensed qs-provider-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th class="text-right">Number</th>
                            <th class="text-right">%</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Total</td>
                            <td class="text-right">{{ $ctrl.statistics.total }}</td>
                            <td class="text-right">100</td>
                        </tr>
                        <tr>
                            <td>Knooppunt</td>
                            <td class="text-right">{{ $ctrl.statistics.amountOfCameViaKnooppunt }}</td>
                            <td class="text-right"><span ng-if="$ctrl.statistics.total > 0">{{ (($ctrl.statistics.amountOfCameViaKnooppunt / $ctrl.statistics.total) * 100).toFixed(2) }}</span></td>
                        </tr>
                         <tr>
                            <td>Digiportail</td>
                            <td class="text-right">{{ $ctrl.statistics.amountOfCameViaDigiportail }}</td>
                            <td class="text-right"><span ng-if="$ctrl.statistics.total > 0">{{ (($ctrl.statistics.amountOfCameViaDigiportail / $ctrl.statistics.total) * 100).toFixed(2) }}</span></td>
                        </tr>
                         <tr>
                            <td>Pelckmans</td>
                            <td class="text-right">{{ $ctrl.statistics.amountOfCameViaPelckmans }}</td>
                            <td class="text-right"><span ng-if="$ctrl.statistics.total > 0">{{ (($ctrl.statistics.amountOfCameViaPelckmans / $ctrl.statistics.total) * 100).toFixed(2) }}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row panel-row text-center">
                <canvas class="chart chart-pie qs-chart"
                    chart-data="[$ctrl.statistics.amountOfTeachers, $ctrl.statistics.amountOfStudents, $ctrl.statistics.amountOfSysadmins, $ctrl.statistics.amountOfProductions, $ctrl.statistics.amountOfSales, $ctrl.statistics.amountOfEditors, $ctrl.statistics.amountOfPublishers, $ctrl.statistics.amountOfExternals, $ctrl.statistics.amountOfOthers]"
                    chart-labels="['Teachers','Students','Sysadmin','Production','Sales','Editor','Publisher','External','Others']"
                    chart-colors="$ctrl.chartColors"
                >
                </canvas>
            </div>
             <div class="row panel-row">
                <table class="table table-condensed qs-table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th class="text-right" title="#(#Avatar/#Background/#Nickname)">#(#Av./#Bk./#Nic.)</th>
                            <th class="text-right">%</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span class="label label-legend" ng-style="{ 'backgroundColor': $ctrl.teacherColor }"> </span>Teachers</td>
                            <td class="text-right">{{ $ctrl.statistics.amountOfTeachers }} ({{ $ctrl.statistics.amountOfTeachersWithCustomAvatar }}/{{ $ctrl.statistics.amountOfTeachersWithCustomBackground }})</td>
                            <td class="text-right"><span ng-if="$ctrl.statistics.total > 0">{{ (($ctrl.statistics.amountOfTeachers / $ctrl.statistics.total) * 100).toFixed(2) }}</span></td>
                        </tr>
                        <tr>
                            <td><span class="label label-legend" ng-style="{ 'backgroundColor': $ctrl.studentColor }"> </span>Students</td>
                            <td class="text-right">{{ $ctrl.statistics.amountOfStudents }} ({{ $ctrl.statistics.amountOfStudentsWithCustomAvatar }}/{{ $ctrl.statistics.amountOfStudentsWithCustomBackground }}/{{ $ctrl.statistics.amountOfStudentsWithNickname }})</td>
                            <td class="text-right"><span ng-if="$ctrl.statistics.total > 0">{{ (($ctrl.statistics.amountOfStudents / $ctrl.statistics.total) * 100).toFixed(2) }}</span></td>
                        </tr>
                        <tr>
                            <td><span class="label label-legend" ng-style="{ 'backgroundColor': $ctrl.sysadminColor }"> </span>Sysadmin</td>
                            <td class="text-right">{{ $ctrl.statistics.amountOfSysadmins }}</td>
                            <td class="text-right"></td>
                        </tr>
                        <tr>
                            <td><span class="label label-legend" ng-style="{ 'backgroundColor': $ctrl.productionColor }"> </span>Production</td>
                            <td class="text-right">{{ $ctrl.statistics.amountOfProductions }}</td>
                            <td class="text-right"></td>
                        </tr>
                        <tr>
                            <td><span class="label label-legend" ng-style="{ 'backgroundColor': $ctrl.salesColor }"> </span>Sales</td>
                            <td class="text-right">{{ $ctrl.statistics.amountOfSales }}</td>
                            <td class="text-right"></td>
                        </tr>
                        <tr>
                            <td><span class="label label-legend" ng-style="{ 'backgroundColor': $ctrl.editorColor }"> </span>Editor</td>
                            <td class="text-right">{{ $ctrl.statistics.amountOfEditors }}</td>
                            <td class="text-right"></td>
                        </tr>
                            <tr>
                            <td><span class="label label-legend" ng-style="{ 'backgroundColor': $ctrl.publisherColor }"> </span>Publisher</td>
                            <td class="text-right">{{ $ctrl.statistics.amountOfPublishers }}</td>
                            <td class="text-right"></td>
                        </tr>
                            <tr>
                            <td><span class="label label-legend" ng-style="{ 'backgroundColor': $ctrl.externalColor }"> </span>External</td>
                            <td class="text-right">{{ $ctrl.statistics.amountOfExternals }}</td>
                            <td class="text-right"></td>
                        </tr>
                         <tr>
                            <td><span class="label label-legend" ng-style="{ 'backgroundColor': $ctrl.othersColor }"> </span>Others</td>
                            <td class="text-right">{{ $ctrl.statistics.amountOfOthers }}</td>
                            <td class="text-right"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </div>
    </div>
    `,
    controller: UserStatisticsController,
};
