import React from 'react';
import {
    arrayOf,
    bool,
    number,
    object,
    oneOf,
    shape,
    string,
} from 'prop-types';

import MediaLinkKind from '../../../utitilies/medialink-kind';
import ExternalMediaType from '../../../utitilies/external-media-type';

function getKindText(row) {
    const kind = row.Kind;
    switch (kind) {
        case MediaLinkKind.EXTERNAL_MEDIA: {
            const externalMediaType = row['External Media Type'];
            switch (externalMediaType) {
                case ExternalMediaType.WEBLINK:
                    return 'Weblink';
                case ExternalMediaType.YOUTUBE:
                    return 'Youtube';
                default:
                    return externalMediaType;
            }
        }
        case MediaLinkKind.FILE:
            return 'File';
        case MediaLinkKind.EXERCISE_EDUHINT:
            return 'Eduhint';
        default:
            return kind;
    }
}

function ConfirmationTable({ rows, omitNode }) {
    return (
        <table className="table confirmation-table">
            <thead>
                <tr>
                    <th className="col-sm-1">Row</th>
                    {!omitNode && <th className="col-sm-2">Node</th>}
                    <th className={`col-sm-${omitNode ? 4 : 2}`}>Name</th>
                    <th className="col-sm-1">Kind</th>
                    <th className="col-sm-2">Update</th>
                    <th>Details</th>
                </tr>
            </thead>
            <tbody>
                {rows.map(row => {
                    let update;

                    const { operation, changes = {} } = row.action || {};

                    switch (operation) {
                        case 'insert': {
                            update = row.Kind === MediaLinkKind.FILE ? 'Moved from shelf' : 'New item created';
                            break;
                        }
                        case 'update': {
                            const amountOfChanges = Object.keys(changes).length;
                            update = `Updated ${amountOfChanges} fields`;
                            break;
                        }
                        default:
                            break;
                    }

                    return (
                        <tr key={row.id} data-id={row.id} className={row.outdated && 'warning'}>
                            <td>{row.id}</td>
                            {!omitNode && <td>{row['Node Title']}</td>}
                            <td>{row.Name}</td>
                            <td>{getKindText(row)}</td>
                            <td>{update}</td>
                            <td>
                                {Object
                                    .entries(changes)
                                    .map(([field, change]) => (
                                        <span className="confirmation-table__detail-field" key={field}>
                                            {`${field}:`}
                                            <span className="confirmation-table__detail-field__change">
                                                {change === null ? '<cleared>' : `${change}`}
                                            </span>
                                        </span>
                                    ))}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

ConfirmationTable.propTypes = {
    rows: arrayOf(shape({
        id: number.isRequired,
        'Node Title': string.isRequired,
        Kind: string.isRequired,
        Name: string.isRequired,
        outdated: bool,
        action: shape({
            operation: oneOf(['insert', 'update']).isRequired,
            changes: object,
        }),
    })),
    omitNode: bool,
};

ConfirmationTable.defaultProps = {
    rows: [],
    omitNode: false,
};

export default ConfirmationTable;
