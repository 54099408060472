import { useState, useEffect } from 'react';
import {
    Subject, Method, Module, getSubjects, getMethods, getModules, getTableOfContentForModule,
} from '../../../api/HierarchyApi';

type UseTableOfContentHierarchyReturnType = {
    subjects: Subject[];
    methods: Method[];
    modules?: Module[];
    formValues: {
        targetSubjectId: string;
        targetMethodId: string;
        targetModuleId: string;
    };
    handleChange: (name: string, value: string) => void;
};

export default function useTableOfContentHierarchy(
    publishingHouseId: string,
    selectedSubjectId: string,
    selectedMethodId: string,
    selectedModuleId: string
): UseTableOfContentHierarchyReturnType {
    const [subjects, setSubjects] = useState<Subject[]>([]);
    const [methods, setMethods] = useState<Method[]>([]);
    const [modules, setModules] = useState<Module[]>();
    const [modulesWithEmptyToc, setModulesWithEmptyToc] = useState<Module[]>();

    const [formValues, setFormValues] = useState({
        targetSubjectId: selectedSubjectId,
        targetMethodId: selectedMethodId,
        targetModuleId: '',
    });

    useEffect(() => {
        getSubjects(publishingHouseId).then(data => {
            setSubjects(data);
        });
    }, [publishingHouseId]);

    useEffect(() => {
        if (formValues.targetSubjectId) {
            getMethods(formValues.targetSubjectId).then(data => {
                setMethods(data);
                setFormValues(values => ({
                    ...values,
                    targetMethodId: data
                        .map(x => x.id)
                        .includes(selectedMethodId)
                        ? selectedMethodId
                        : data[0].id,
                    targetModuleId: '',
                }));
            });
        }
    }, [formValues.targetSubjectId, selectedMethodId]);

    useEffect(() => {
        if (formValues.targetMethodId) {
            getModules(formValues.targetMethodId).then(data => {
                setModules(data);
                setFormValues(values => ({ ...values, targetModuleId: '' }));
            });
        }
    }, [formValues.targetMethodId, selectedModuleId]);

    useEffect(() => {
        const fetchTablesOfContent = async () => {
            if (!modules) return;

            const tocs = await Promise.all(modules.filter(m => m.id !== selectedModuleId).map(module => getTableOfContentForModule(module.id)));

            setModulesWithEmptyToc(modules.filter(module => tocs.find(toc => toc.module === module.id)?.nodes.length === 0));
        };

        fetchTablesOfContent();
    }, [modules, selectedModuleId]);

    const handleChange = (name: string, value: string) => {
        if (name === 'targetSubjectId') {
            setFormValues(values => ({
                ...values,
                targetSubjectId: value,
                targetMethodId: '',
                targetModuleId: '',
            }));
        }

        if (name === 'targetMethodId') {
            setFormValues(values => ({
                ...values,
                targetMethodId: value,
                targetModuleId: '',
            }));
        }

        if (name === 'targetModuleId') {
            setFormValues(values => ({
                ...values,
                targetModuleId: value,
            }));
        }
    };

    return {
        subjects,
        methods,
        modules: modulesWithEmptyToc,
        formValues,
        handleChange,
    };
}
