import './editable-text.scss';

class EditableTextController {

    $onInit() {
        this.editable = angular.isUndefined(this.editable) ? false : this.editable;
        this.ngRequired = angular.isUndefined(this.ngRequired) ? false : this.ngRequired;
    }
}

export default {
    template: `
    <div class="editable-text-component">
        <span class="form-control editable-text" ng-if="!$ctrl.editable"> {{ $ctrl.ngModel }} </span>
        <input type="text" class="form-control" ng-if="$ctrl.editable" ng-required="$ctrl.ngRequired" name="{{$ctrl.name}}" ng-model="$ctrl.ngModel">
    </div>
    `,
    controller: EditableTextController,
    bindings: {
        editable: '<?',
        ngModel: '=',
        name: '@',
        ngRequired: '<?',
    },
};
