import './group-statistics.component.scss';

class BookmarkStatistics {

    // @ngInject
    constructor($http) {
        this.$http = $http;
    }

    $onInit() {
        return this.$http
            .get('/api/shell/statistics/groups')
            .then(groups => {
                this.statistics = groups.data;
            });
    }
}

export default {
    template: `
    <div class="panel panel-default">
        <div class="panel-heading">
            <h3 class="panel-title">Group stats - Total: {{$ctrl.statistics.total}}</h3>
        </div>
        <div class="panel-body">
             <div class="row panel-row">
                <table class="table table-condensed qs-table__top bookmarks-top" ng-if="$ctrl.statistics.data.length">
                    <thead>
                        <tr>
                            <th>Top 5 Schools</th>
                            <th class="text-right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr ng-repeat="module in $ctrl.statistics.data">
                            <td>{{ module.school }}</td>
                            <td class="text-right">{{ module.total }}</td>
                        </tr>
                    </tbody>
                </table>
        </div>
    </div>
    `,
    controller: BookmarkStatistics,
};
