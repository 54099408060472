/* eslint-disable react/button-has-type */
import React from 'react';
import classNames from 'classnames';
import {
    func,
    node,
    oneOf,
    string,
    bool,
} from 'prop-types';

const BUTTON_ROOT = 'btn';

function Button({
    children,
    className,
    'data-testid': dataTestId,
    disabled,
    onClick,
    type,
    variant,
    size,
}) {
    return (
        <button
            className={classNames(BUTTON_ROOT, {
                [`${BUTTON_ROOT}-${variant}`]: true,
                [`${BUTTON_ROOT}-${size}`]: Boolean(size),
            }, className)}
            data-testid={dataTestId}
            disabled={disabled}
            onClick={onClick}
            type={type}
        >
            {children}
        </button>
    );
}

Button.propTypes = {
    className: string,
    children: node.isRequired,
    'data-testid': string,
    disabled: bool,
    onClick: func,
    type: oneOf(['button', 'submit', 'reset']),
    variant: oneOf(['default', 'primary', 'success', 'info', 'warning', 'danger', 'link', 'light']),
    size: oneOf(['xs', 'sm', 'lg']),
};

Button.defaultProps = {
    className: undefined,
    'data-testid': undefined,
    disabled: undefined,
    onClick: undefined,
    type: 'button',
    variant: 'default',
    size: undefined,
};

export default Button;
