import React, { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { getPublications, Publication } from '../../../../../../api/MediaLinksApi';

type Props = {
    disabled: boolean;
    selectedScheduleId?: string;
    selectedPublication: SingleValue<{ value: string; label: string; }> | null;
    onChange: (value: SingleValue<{ value: string; label: string; }>) => void;

};

const Publications = ({
    selectedPublication, selectedScheduleId, disabled, onChange,
}: Props): JSX.Element => {
    const [publications, setPublications] = useState<Publication[]>([]);

    useEffect(() => {
        if (selectedScheduleId) {
            const fetchPublications = async () => {
                const fetchedPublications = await getPublications(selectedScheduleId);
                setPublications(fetchedPublications);
            };
            fetchPublications();
        }
    }, [selectedScheduleId]);

    return (
        <div className="form-group" data-testid="publications">
            <label htmlFor="publications" className="control-label col-md-4">Publications</label>
            <div className="col-md-7">
                <Select
                    inputId="publications"
                    options={publications.map(publication => ({
                        value: publication.id,
                        label: publication.name,
                    }))}
                    value={selectedPublication}
                    onChange={onChange}
                    isDisabled={disabled}
                    placeholder="Select a publication"
                    styles={{
                        container: baseStyles => ({
                            ...baseStyles,
                            display: 'flex',
                        }),
                        control: baseStyles => ({
                            ...baseStyles,
                            width: '100%',
                        }),
                    }}
                />
            </div>

        </div>
    );
};

export default Publications;
