import findIndex from 'lodash/findIndex';
import some from 'lodash/some';
import Filesize from 'filesize/lib/filesize';

import ExternalMediaType from '../utitilies/external-media-type';
import MedialinkKind from '../utitilies/medialink-kind';

import Role from '../../../../../core/security/role';

import tabTemplate from './medialinks-tab.component.html';
import externalMediaSettingsTemplate from './external-media-settings.html';
import fileSettingsTemplate from './file-settings.html';
import assessmentQSettingsTemplate from './assessment-q-settings.html';
import eduhintSettingsTemplate from './eduhint-settings.html';
import miniDialogSettingsTemplate from './mini-dialog-settings.html';
import wordListSettingsTemplate from './word-list-settings.html';
import miniSiteSettingsTemplate from './mini-site-settings.html';
import actionNames from '../media-links-action-names';

class MedialinksTabController {

    // @ngInject
    constructor($scope, $rootScope, ngDialog, MediaLinkModel, store, ModuleModel, $q, toaster, $filter, EdumaticScheduleModel, EdumaticPublicationModel, $sce, treeConfig, reviewStates, MiniDialogModel, WordListModel, $http, $window, config) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$sce = $sce;
        this.$http = $http;
        this.$q = $q;
        this.$window = $window;

        this.ngDialog = ngDialog;
        this.MediaLinkModel = MediaLinkModel;
        this.EdumaticScheduleModel = EdumaticScheduleModel;
        this.EdumaticPublicationModel = EdumaticPublicationModel;
        this.treeFilter = $filter('uiTreeFilter');
        this.Filesize = Filesize;
        this.store = store;
        this.ModuleModel = ModuleModel;
        this.toaster = toaster;
        this.treeConfig = treeConfig;
        this.reviewStates = reviewStates;
        this.WordListModel = WordListModel;
        this.MiniDialogModel = MiniDialogModel;
        this.config = config;
        this.onAssessmentQDialogClosed = this.onAssessmentQDialogClosed.bind(this);

        this.MedialinkKind = MedialinkKind;
        this.ExternalMediaType = ExternalMediaType;

        this.highestRoleIsEditor = this.$rootScope.identity.isInRole(Role.EDITOR)
            && !this.$rootScope.identity.hasAnyRole([Role.SYSADMIN]);

        this.tableOptions = {
            beforeDrop: node => {

                const medialinks = angular.copy(this.mediaLinks);
                const destIsHigherThanSource = node.dest.index > node.source.index;
                const droppedOnIndex = medialinks[node.dest.index];

                const rank = {
                    before: destIsHigherThanSource ? medialinks[node.dest.index + 1] : droppedOnIndex,
                    after: destIsHigherThanSource ? droppedOnIndex : medialinks[node.dest.index - 1],
                };

                if (node.dest.index === 0) {
                    delete rank.after;
                }

                if (node.dest.index === medialinks.length - 1) {
                    delete rank.before;
                }

                const mediaLinkToRank = { ...this.mediaLinks[node.source.index], nodeId: this.currentNodeId, moduleId: this.moduleId };

                return mediaLinkToRank.$rank(rank.before ? rank.before.id : undefined, rank.after ? rank.after.id : undefined)
                    .catch(err => {
                        if (err.status === 404) {
                            toaster.pop('warning', 'Oops, something went wrong', 'Someone removed the medialink you are trying to drag');
                            return this.$q.resolve(false);
                        }

                        if (err.status === 409) {
                            toaster.pop('warning', 'Oops, something went wrong', 'Invalid state detected, try refreshing your page');
                            return this.$q.resolve(false);
                        }
                        return this.$q.reject(err);
                    });
            },
        };
    }

    $onInit() {
        this.nodeRenderer = this.$sce.trustAsResourceUrl('/shell/modules/media-links/components/publication-node-renderer.html');
        this.treeConfig.defaultCollapsed = true;

        this.medialinks = [];

        this.wordLists = [];
        this.allWordLists = [];
        this.miniDialogs = [];
        this.allMiniDialogs = [];

        this.suggestions = [];
        this.filteredSuggestions = [];
        this.suggestionTypeSelected = '';

        this.$scope.$on(actionNames.MEDIALINKS_IMPORTED, () => this.getMedialinksForCurrentModule());

        this.onModuleSelected = this.onTargetModuleSelected.bind(this);
    }

    $onChanges(changes) {
        if (changes.moduleId) {
            this.destinationModuleId = this.moduleId;
            delete this.nodeId;
            this.moduleOrNodeChanged();
        }
    }

    onTargetModuleSelected(moduleId) {
        this.destinationModuleId = moduleId;
        this.destinationNodeId = 'general';
        this.$scope.$digest();
    }

    setContentNode(node) {
        this.nodeId = node.id;

        this.moduleOrNodeChanged();
    }

    enrichMedialink(mediaLink) {
        mediaLink.contentTypeModel(this.mediaContentTypes.find(x => x.id === mediaLink.contentType));
        if (mediaLink.tags) {
            mediaLink.tagsAsString = mediaLink.tags.join(' ');
        }
    }

    get currentNodeId() {
        return this.nodeId || 'general';
    }

    get canChangeExternalMediaType() {
        return !this.currentMediaLink.id;
    }

    get selectedMediaLinks() {
        return this.mediaLinks ? this.mediaLinks.filter(m => m.checked) : [];
    }

    moduleOrNodeChanged() {
        this.mediaLinks = [];
        this.suggestions = [];
        this.filteredSuggestions = [];
        this.allSelected = false;
        this.medialinkFilter = undefined;

        if (this.moduleId) {
            const baseUrl = this.publishingHouse ? this.publishingHouse.baseUrl : this.$window.location.origin;
            this.baseLinkUrl = `${baseUrl}/link/${this.superModuleId}/${this.moduleId}/${this.currentNodeId}`;

            this.$q.all([
                this.getMedialinksForCurrentModule(),
                this.getSuggestions(),
            ]);
        }
    }

    getMedialinksForCurrentModule() {
        return this.MediaLinkModel
            .query({
                nodeId: this.currentNodeId,
                moduleId: this.moduleId,
            })
            .then(mediaLinks => {
                mediaLinks.forEach(x => this.enrichMedialink(x));
                this.mediaLinks = mediaLinks;
            });
    }

    setCurrentReplaceMediaLink(clickedMediaLink) {
        clickedMediaLink.moduleId = this.moduleId;
        clickedMediaLink.nodeId = this.currentNodeId;
        this.currentReplaceMediaLink = clickedMediaLink;
    }

    selectMiniDialog(md) {
        this.currentMediaLink.name = md.name;
        this.currentMediaLink.miniDialog = md.id;
    }

    selectWordList(wl) {
        this.currentMediaLink.name = wl.name;
        this.currentMediaLink.wordList = wl.id;
    }

    openDetails(kind, mediaLink) {
        this.selectedMediaLink = mediaLink;

        let template;
        let className;

        const promise = mediaLink
            ? this.MediaLinkModel.get({
                id: mediaLink.id,
                moduleId: this.moduleId,
                nodeId: this.currentNodeId,
            })
            : this.$q.resolve(new this.MediaLinkModel({
                kind,
            }));

        switch (kind) {
            case MedialinkKind.EXTERNAL_MEDIA:
                template = externalMediaSettingsTemplate;
                className = 'ngdialog-theme-default ng-dialog__xs';
                break;
            case MedialinkKind.FILE:
                template = fileSettingsTemplate;
                className = 'ngdialog-theme-default ng-dialog__custom-width media-links-disabled-text-dialog';
                break;
            case MedialinkKind.EXERCISE_EDUMATIC:
                template = assessmentQSettingsTemplate;
                className = 'ngdialog-theme-default ng-dialog__custom-width';
                break;
            case MedialinkKind.EXERCISE_EDUHINT:
                template = eduhintSettingsTemplate;
                className = 'ngdialog-theme-default ng-dialog__xs';
                break;
            case MedialinkKind.MINI_DIALOG:
                template = miniDialogSettingsTemplate;
                className = 'ngdialog-theme-default ng-dialog__xs';
                break;
            case MedialinkKind.WORD_LIST:
                template = wordListSettingsTemplate;
                className = 'ngdialog-theme-default ng-dialog__xs';
                break;
            case MedialinkKind.MINI_SITE:
                template = miniSiteSettingsTemplate;
                className = 'ngdialog-theme-default ng-dialog__xs media-links-disabled-text-dialog';
                break;
            default:
                throw new Error(`Unsupported kind ${kind}`);
        }

        return promise
            .then(resolvedMediaLink => {
                const initEdumaticExercise = (resolvedMediaLink.kind === MedialinkKind.EXERCISE_EDUMATIC)
                    ? this.initEdumaticExerciseLink(resolvedMediaLink)
                    : this.$q.resolve(resolvedMediaLink);

                return initEdumaticExercise
                    .then(medialink => {
                        this.currentMediaLink = medialink;
                        this.currentMediaLink.moduleId = this.moduleId;
                        this.currentMediaLink.nodeId = this.currentNodeId;
                        this.medialink = { ...this.currentMediaLink }.toResource();

                        if (kind === MedialinkKind.EXTERNAL_MEDIA && !this.currentMediaLink.id) {
                            if (!this.currentMediaLink.externalMedia) this.currentMediaLink.externalMedia = {};
                            this.currentMediaLink.externalMedia.type = ExternalMediaType.WEBLINK;
                        }

                        if (this.selectedMediaLink) {
                            this.selectedMediaLink.$update(medialink);
                            this.selectedMediaLink.$commit();

                            if (kind !== MedialinkKind.EXERCISE_EDUMATIC) {
                                this.enrichMedialink(this.selectedMediaLink);
                            }
                        }

                        if (kind === MedialinkKind.MINI_DIALOG) {
                            return this
                                .getMiniDialogs()
                                .then(miniDialogs => {
                                    this.miniDialogSearch = undefined;
                                    this.miniDialogs = miniDialogs;
                                });
                        }
                        if (kind === MedialinkKind.WORD_LIST) {
                            return this
                                .getWordLists()
                                .then(wordLists => {
                                    this.wordListSearch = undefined;
                                    this.wordLists = wordLists;
                                });
                        }

                        if (kind === MedialinkKind.FILE) {

                            this.imagePreviewLinks = (this.mediaLinks || [])
                                .filter(mlink => {
                                    const mediaContentType = this.mediaContentTypes.find(x => x.id === mlink.contentType);
                                    const isImagePreview = mediaContentType && mediaContentType.imagePreview === true;

                                    return (mlink.kind === MedialinkKind.FILE)
                                        && !(mlink.file || {}).linkedMedia // prevent recursive linking
                                        && (mlink.id !== this.currentMediaLink.id) // prevent link with itself
                                        && isImagePreview;
                                });

                            // add option if linked medialink is not found anymore
                            // (not found on node or content type is no more image preview)
                            if (this.currentMediaLink.file.linkedMedia
                                && !this.imagePreviewLinks.find(link => link.id === this.currentMediaLink.file.linkedMedia)) {

                                this.imagePreviewLinks.push({
                                    id: this.currentMediaLink.file.linkedMedia,
                                    name: 'Orphaned media link',
                                });
                            }
                        }

                        return this.$q.resolve();
                    })
                    .then(() => this.ngDialog.open({
                        template,
                        plain: true,
                        scope: this.$scope, // we need this so we can access the function of our Home controller
                        className,
                    }));
            });
    }

    externalMediaTypeChanged() {
        // set geogebra defaults
        if (!this.currentMediaLink.id) {

            if (this.currentMediaLink.externalMedia.type === ExternalMediaType.GEOGEBRA) {
                Object.assign(
                    this.currentMediaLink.externalMedia,
                    {
                        geogebra: {
                            language: 0, //NL
                            resetIcon: true,
                        },
                    }
                );
            } else {
                delete this.currentMediaLink.externalMedia.geogebra;
            }
        }
    }

    clearInputWhenUnchecked(id) {
        switch (id) {
            case 'start':
                this.currentMediaLink.externalMedia.start = null;
                break;
            case 'end':
                this.currentMediaLink.externalMedia.end = null;
                break;
            default:
        }
    }

    initEdumaticExerciseLink(exerciseLink, reInitLink) {
        this.reInitLink = reInitLink;
        this.isNewExercise = !exerciseLink.id;
        this.treeConfig.defaultCollapsed = this.isNewExercise;

        if (this.isNewExercise || reInitLink) {
            exerciseLink.edumaticExercise = {};

            return this.getEdumaticSchedules()
                .then(() => this.$q.resolve(exerciseLink));
        }

        const publicationPartTree = this.getPublicationPartTree(exerciseLink);
        this.edumatic = {
            schedules: [exerciseLink.edumaticExercise.schedule],
            publications: [exerciseLink.edumaticExercise.publication],
            publicationParts: [publicationPartTree],
        };

        return this.$q.resolve(exerciseLink);
    }

    getPublicationPartTree(exerciseLink) {
        const pathTreeNodes = exerciseLink.edumaticExercise.publicationPart.fullPath.split('@#@');
        let publicationPartTree = {};
        let currentNode;

        pathTreeNodes.forEach((nodeName, index) => {

            const newNode = {
                name: nodeName,
            };

            if (index === 0) {
                publicationPartTree = newNode;
            } else {
                currentNode.nodes = [newNode];
            }
            currentNode = newNode;
        });
        return publicationPartTree;
    }

    getEdumaticSchedules() {
        this.edumatic = {
            schedules: [],
            publications: [],
            publicationParts: [],
        };

        return this.EdumaticScheduleModel.query()
            .then(schedules => {
                this.edumatic.schedules = schedules;
            });
    }

    getPublicationsByScheduleId() {
        this.edumatic.publications = [];
        this.currentMediaLink.edumaticExercise.publication = '';

        this.edumatic.publicationParts = [];
        this.currentMediaLink.edumaticExercise.publicationPart = '';

        return this.EdumaticScheduleModel
            .getPublicationsByScheduleId({
                scheduleId: this.currentMediaLink.edumaticExercise.schedule.id,
            })
            .then(publications => {
                this.edumatic.publications = publications;
            });
    }

    getPartsByPublicationId() {
        this.edumatic.publicationParts = [];
        this.currentMediaLink.edumaticExercise.publicationPart = '';

        return this.EdumaticPublicationModel
            .getPartsByPublicationId({
                publicationId: this.currentMediaLink.edumaticExercise.publication.id,
            })
            .then(publicationParts => {
                this.edumatic.publicationParts = publicationParts;
            });
    }

    selectPublicationPart(publicationPart) {
        this.currentMediaLink.edumaticExercise.publicationPart = publicationPart;
    }

    saveMediaLink() {
        return (!this.currentMediaLink.id
            ? this.currentMediaLink.$save()
            : this.currentMediaLink.$patch())
            .then(mediaLink => {
                if (angular.isUndefined(this.selectedMediaLink)) {
                    this.mediaLinks.push(mediaLink);
                    this.allSelected = false;
                    return mediaLink.kind === MedialinkKind.MINI_DIALOG
                        ? this.getMiniDialogSuggestions()
                        : this.$q.resolve();
                }

                this.selectedMediaLink.$update(mediaLink);
                this.selectedMediaLink.$commit();
                this.enrichMedialink(this.selectedMediaLink);
                return this.$q.resolve();
            })
            .then(() => this.ngDialog.close())
            .catch(err => {
                if (err.status === 400) {
                    this.toaster.pop('warning', 'Bad Request', err.data.details);
                    return;
                }
                this.toaster.pop('error', 'Oops, something went wrong', `[${err.status}] ${err.data.message}`);
            });
    }

    updateMediaLink(mediaLink, rowIndex) {
        cleanHasErrorClasses(rowIndex);
        mediaLink.moduleId = this.moduleId;
        mediaLink.nodeId = this.currentNodeId;

        return mediaLink.$patch()
            .then(res => {
                if (mediaLink.checked) res.checked = true;
                mediaLink.$update(res);
                mediaLink.$commit();

                this.enrichMedialink(mediaLink);
            });
    }

    onAssessmentQDialogClosed() {
        this.ngDialog.close();
        this.getMedialinksForCurrentModule();
        this.currentMedialink = undefined;
    }

    changePublishedState(mediaLink, rowIndex) {
        if (mediaLink.published) {
            mediaLink.moduleId = this.moduleId;
            mediaLink.nodeId = this.currentNodeId;

            return mediaLink
                .$unpublish()
                .then(res => {
                    this.calculateIfAllMediaLinksAreSelected();
                    mediaLink.$update(res);
                    mediaLink.$commit();
                    this.enrichMedialink(mediaLink);
                    this.calculateIfAllMediaLinksAreSelected();
                });
        }

        const valid = placeHasErrorClasses(mediaLink, rowIndex, this.mediaContentTypes, this.exerciseCategoryTable);
        if (valid) {
            mediaLink.moduleId = this.moduleId;
            mediaLink.nodeId = this.currentNodeId;

            return mediaLink.$publish()
                .then(res => {
                    this.calculateIfAllMediaLinksAreSelected();
                    mediaLink.$update(res);
                    mediaLink.$commit();

                    this.enrichMedialink(mediaLink);
                    this.calculateIfAllMediaLinksAreSelected();
                });
        }
    }

    publishSelectedForReview() {
        this.selectedMediaLinks
            .forEach(m => {
                const index = findIndex(this.mediaLinks, mediaLink => mediaLink.id === m.id);
                if (m.reviewState === this.reviewStates.NOT_IN_REVIEW && !m.published) {
                    const stateChanged = this.setReviewState(m, index);

                    if (stateChanged) {
                        m.checked = false;
                    }
                }
            });

        this.calculateIfAllMediaLinksAreSelected();
    }

    unpublishSelectedForReview() {
        this.selectedMediaLinks
            .forEach(m => {
                const index = findIndex(this.mediaLinks, mediaLink => mediaLink.id === m.id);
                if (m.reviewState !== this.reviewStates.NOT_IN_REVIEW && !m.published) {
                    const stateChanged = this.setReviewState(m, index);

                    if (stateChanged) {
                        m.checked = false;
                    }
                }
            });

        this.calculateIfAllMediaLinksAreSelected();
    }

    publishSelected() {
        const mediaLinksToBePublished = [];

        this.selectedMediaLinks
            .filter(x => !x.published)
            .forEach(m => {
                const index = findIndex(this.mediaLinks, mediaLink => mediaLink.id === m.id);
                const valid = placeHasErrorClasses(m, index, this.mediaContentTypes, this.exerciseCategoryTable);
                m.moduleId = this.moduleId;
                m.nodeId = this.currentNodeId;
                if (valid && (m.reviewState === this.reviewStates.NOT_IN_REVIEW || m.reviewState === this.reviewStates.ACCEPTED)) mediaLinksToBePublished.push(m);
            });

        mediaLinksToBePublished
            .forEach(x => {
                x.$publish()
                    .then(value => {
                        x.$update(value);
                        x.$commit();
                        this.calculateIfAllMediaLinksAreSelected();
                        this.enrichMedialink(x);
                    });
            });

    }

    unpublishSelected() {
        const mediaLinksToBeUnpublished = this.selectedMediaLinks.map(x => {
            x.moduleId = this.moduleId;
            x.nodeId = this.currentNodeId;
            return x;
        });

        return mediaLinksToBeUnpublished
            .reduce((p, medialink) => p
                .then(() => medialink
                    .$unpublish()
                    .then(value => {
                        medialink.$update(value);
                        medialink.$commit();
                        this.calculateIfAllMediaLinksAreSelected();
                        this.enrichMedialink(medialink);
                    })), this.$q.resolve());
    }

    deleteMediaLinkReference(referencedMediaLinkId) {
        this.mediaLinks.forEach(mLink => {
            if (mLink.linkedMedia === referencedMediaLinkId) {
                delete mLink.linkedMedia;
                mLink.$commit();
            }
        });
    }

    deleteMediaLink(mediaLink) {
        mediaLink.moduleId = this.moduleId;
        mediaLink.nodeId = this.currentNodeId;
        return mediaLink
            .$destroy()
            .then(() => this.getSuggestions())
            .then(() => this.deleteMediaLinkReference(mediaLink.id));
    }

    deleteSelected() {
        const mediaLinksToBeDeleted = [];

        this.selectedMediaLinks
            .forEach(m => {
                if (!m.published) {
                    m.moduleId = this.moduleId;
                    m.nodeId = this.currentNodeId;
                    mediaLinksToBeDeleted.push(m);
                }
            });

        this.allSelected = false;
        const mediaLinkIdsToBeDeleted = mediaLinksToBeDeleted.map(ml => ml.id);

        return deleteMediaLinkChain(mediaLinksToBeDeleted)
            .then(mediaLink => {
                if (mediaLink) {
                    this.mediaLinks.forEach((val, key) => {
                        if (val.id === mediaLink.id) {
                            this.mediaLinks.splice(key, 1);
                        }
                    });
                }

                mediaLinkIdsToBeDeleted
                    .forEach(referencedMediaLinkId => this.deleteMediaLinkReference(referencedMediaLinkId));
                this.calculateIfAllMediaLinksAreSelected();
                return this.getSuggestions();
            });
    }

    setSelectedMedialinksAsPromo(isPromo) {
        this.selectedMediaLinks.forEach(x => {
            if (!x.published) {
                x.promoChapter = isPromo;
                x.moduleId = this.moduleId;
                x.nodeId = this.currentNodeId;

                x
                    .$patch()
                    .then(value => {
                        this.calculateIfAllMediaLinksAreSelected();

                        this.enrichMedialink(value);
                        x.$update(value);
                        x.$commit();
                    });
            }
        });
    }

    moveSelected() {
        const mediaLinksToBeMoved = this.selectedMediaLinks.map(x => {
            x.moduleId = this.moduleId;
            x.nodeId = this.currentNodeId;
            return x;
        });

        const destinationNodeId = (this.destinationNode || {}).id || 'general';

        return mediaLinksToBeMoved
            .reduce((p, medialink) => p
                .then(() => medialink
                    .$move(destinationNodeId, this.destinationModuleId)
                    .then(() => {
                        const idx = this.mediaLinks.findIndex(x => x.id === medialink.id);
                        if (idx >= 0) this.mediaLinks.splice(idx, 1);
                        this.calculateIfAllMediaLinksAreSelected();
                    })
                    .catch(err => {
                        if (err.status === 409) return this.toaster.pop('warning', 'Conflict', err.data.message);

                        throw err;
                    })), this.$q.resolve())
            .then(() => this.getMedialinksForCurrentModule());
    }

    copySelected() {
        const mediaLinksToBeCopied = this.selectedMediaLinks.map(x => {
            x.moduleId = this.moduleId;
            x.nodeId = this.currentNodeId;
            return x;
        });

        const destinationNodeId = (this.destinationNode || {}).id || 'general';

        return mediaLinksToBeCopied
            .reduce((p, medialink) => p
                .then(() => medialink
                    .$copyMedialink(destinationNodeId, this.destinationModuleId)
                    .catch(err => {
                        if (err.status === 409) return this.toaster.pop('warning', 'Conflict', err.data.message);

                        throw err;
                    })), this.$q.resolve())
            .then(() => this.getMedialinksForCurrentModule());
    }

    toggleManageContent(open) {
        if (open) delete this.destinationNode;
        this.isToggleManageContent = open;
    }

    setContentTypeBatch() {
        const filteredMediaLinks = this.selectedMediaLinks
            .map(x => {
                x.moduleId = this.moduleId;
                x.nodeId = this.currentNodeId;

                return x;
            })
            .filter(x => !x.published);

        return filteredMediaLinks
            .reduce((p, medialink) => p
                .then(() => {
                    medialink.contentType = this.batchContentType.id;

                    return medialink
                        .$patch();
                })
                .then(value => {
                    medialink.$update(value);
                    medialink.$commit();
                    this.enrichMedialink(medialink);
                    this.calculateIfAllMediaLinksAreSelected();
                }), this.$q.resolve())
            .then(() => {
                this.batchContentType = undefined;
            });
    }

    setGroupBatch() {
        const filteredMediaLinks = this.selectedMediaLinks
            .map(x => {
                x.moduleId = this.moduleId;
                x.nodeId = this.currentNodeId;

                return x;
            })
            .filter(x => !x.published);

        return filteredMediaLinks
            .reduce((p, medialink) => p
                .then(() => {
                    medialink.group = this.batchGroup;

                    return medialink
                        .$patch();
                })
                .then(value => {
                    medialink.$update(value);
                    medialink.$commit();
                    this.enrichMedialink(medialink);
                    this.calculateIfAllMediaLinksAreSelected();
                }), this.$q.resolve())
            .then(() => {
                this.batchGroup = undefined;
            });
    }

    toggleSelectedOfAllMediaLinks() {
        if (this.mediaLinks) {
            this.mediaLinks.forEach(m => {
                m.checked = this.allSelected;
            });
        }
    }

    calculateIfAllMediaLinksAreSelected() {
        this.allSelected = this.mediaLinks
            ? (this.mediaLinks.length > 0 && this.mediaLinks.length === this.selectedMediaLinks.length)
            : false;
    }

    onUploadInitialized(uploader) {
        this.uploader = uploader;
    }

    onItemAdded(item) {
        if (this.currentReplaceMediaLink) {
            this.currentReplaceMediaLink.moduleId = this.moduleId;
            this.currentReplaceMediaLink.nodeId = this.currentNodeId;

            item.context = { mediaLink: this.currentReplaceMediaLink, moduleId: this.moduleId, nodeId: this.currentNodeId };
            delete this.currentReplaceMediaLink;
        } else {
            item.context = { moduleId: this.moduleId, nodeId: this.currentNodeId };
        }
    }

    onCompleteItem(fileId, item) {
        // file upload is complete,
        // if we have currentMediaLink, replace the fileId of the existing mediaLink
        // otherwise, create new mediaLink
        const replaceMediaLink = item.context.mediaLink;

        if (replaceMediaLink) {
            if (replaceMediaLink.kind === 'mini-site') replaceMediaLink.miniSite.temporaryFileId = fileId;
            else replaceMediaLink.file.temporaryFileId = fileId;

            return replaceMediaLink.$patch();
        }

        const name = item.file.name.replace(/\.[^/.]+$/, '');

        let mediaLink;

        if (item.kind === 'mini-site') {
            mediaLink = new this.MediaLinkModel({
                kind: MedialinkKind.MINI_SITE,
                name,
                miniSite: {
                    temporaryFileId: fileId,
                },
            });
        } else {
            mediaLink = new this.MediaLinkModel({
                kind: MedialinkKind.FILE,
                name,
                file: {
                    temporaryFileId: fileId,
                    forceDownload: true,
                },
            });
        }

        // we use $http for bulk upload. model-factory can not handle multiple posts at the same time and will only execute one.
        return this.$http.post(`/api/shell/modules/${item.context.moduleId}/table-of-content/${item.context.nodeId}/medialinks`, mediaLink.toResource())
            .then(storedMediaLink => {
                this.mediaLinks.push(new this.MediaLinkModel(storedMediaLink.data));
                this.allSelected = false;
            });
    }

    setReviewState(mediaLink, rowIndex) {
        const valid = placeHasErrorClasses(mediaLink, rowIndex, this.mediaContentTypes, this.exerciseCategoryTable);
        if (valid) {
            if (mediaLink.reviewState === this.reviewStates.NOT_IN_REVIEW) {
                mediaLink.reviewState = this.reviewStates.IN_REVIEW;
            } else {
                mediaLink.reviewState = this.reviewStates.NOT_IN_REVIEW;
            }
            return this.updateMediaLink(mediaLink, rowIndex);
        }
    }

    getMiniDialogs() {
        const miniDialogsForModule = this.allMiniDialogs[this.moduleId];
        if (miniDialogsForModule) return this.$q.resolve(miniDialogsForModule);

        return this.MiniDialogModel
            .$getList(this.moduleId, 'general', true)
            .then(results => {
                this.allMiniDialogs[this.moduleId] = results.sort(this.compareByNameAscending);
                return this.allMiniDialogs[this.moduleId];
            });
    }

    getWordLists() {
        const wordListsForModule = this.allWordLists[this.moduleId];
        if (wordListsForModule) return this.$q.resolve(wordListsForModule);

        return this.WordListModel
            .$getList(this.moduleId, 'general', true)
            .then(results => {
                this.allWordLists[this.moduleId] = results.sort(this.compareByNameAscending);
                return this.allWordLists[this.moduleId];
            });
    }

    searchMiniDialogs(term) {
        if (term.length >= 3) {
            const miniDialogsForModule = this.allMiniDialogs[this.moduleId];
            this.miniDialogs = miniDialogsForModule.filter(md => md.name.toLowerCase().contains(term.toLowerCase()));
        } else {
            this.miniDialogs = this.allMiniDialogs[this.moduleId];
        }
    }

    searchWordLists(term) {
        if (term.length >= 3) {
            const wordListsForModule = this.allWordLists[this.moduleId];
            this.wordLists = wordListsForModule.filter(wl => wl.name.toLowerCase().contains(term.toLowerCase()));
        } else {
            this.wordLists = this.allWordLists[this.moduleId];
        }
    }

    compareByNameAscending(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    }

    createMedialinkFromType(suggestion) {
        let mediaLink;
        switch (suggestion.type) {
            case MedialinkKind.WORD_LIST:
                mediaLink = this.createMedialinkFromWordList(suggestion);
                break;
            case MedialinkKind.MINI_DIALOG:
                mediaLink = this.createMedialinkFromMiniDialog(suggestion);
                break;
            default:
                throw new Error(`Unsupported type ${suggestion.type}.`);
        }

        return mediaLink.$save()
            .then(storedMediaLink => {
                this.mediaLinks.push(storedMediaLink);
                this.allSelected = false;

                // refresh suggestions
                return this.getSuggestions();
            });
    }

    getSuggestions() {
        if (!this.moduleId || this.nodeId === 'shelf') return this.$q.resolve();

        return this.$q
            .all([
                this.getMiniDialogSuggestions(),
                this.getWordListSuggestions(),
            ])
            .then(([miniDialogSuggestions, wordListSuggestions]) => {
                this.suggestions = miniDialogSuggestions
                    .concat(wordListSuggestions)
                    .sort(this.compareByNameAscending);
                this.filterSuggestions();
                return this.suggestions;
            });
    }

    getMiniDialogSuggestions() {
        if (this.nodeId === 'shelf') return this.$q.resolve();

        return this.MiniDialogModel
            .$getSuggestions(this.moduleId, this.currentNodeId)
            .then(miniDialogSuggestions => miniDialogSuggestions.map(suggestion => ({
                ...suggestion,
                type: MedialinkKind.MINI_DIALOG,
            })));

    }

    getWordListSuggestions() {
        if (this.nodeId === 'shelf') return this.$q.resolve();

        return this.WordListModel
            .$getSuggestions(this.moduleId, this.currentNodeId)
            .then(wordListSuggestions => wordListSuggestions.map(suggestion => ({
                ...suggestion,
                type: MedialinkKind.WORD_LIST,
            })));
    }

    createMedialinkFromWordList(wordList) {
        return new this.MediaLinkModel({
            moduleId: this.moduleId,
            nodeId: this.currentNodeId,
            kind: MedialinkKind.WORD_LIST,
            name: wordList.name,
            wordList: wordList.id,
        });
    }

    createMedialinkFromMiniDialog(miniDialog) {
        return new this.MediaLinkModel({
            moduleId: this.moduleId,
            nodeId: this.currentNodeId,
            kind: MedialinkKind.MINI_DIALOG,
            name: miniDialog.name,
            miniDialog: miniDialog.id,
        });
    }

    filterSuggestions() {
        switch (this.suggestionTypeSelected) {
            case MedialinkKind.WORD_LIST:
                this.filteredSuggestions = this.suggestions
                    .filter(suggestion => suggestion.type === MedialinkKind.WORD_LIST);
                break;
            case MedialinkKind.MINI_DIALOG:
                this.filteredSuggestions = this.suggestions
                    .filter(suggestion => suggestion.type === MedialinkKind.MINI_DIALOG);
                break;
            default:
                this.filteredSuggestions = this.suggestions;
        }
    }
}

export default {
    template: tabTemplate,
    controller: MedialinksTabController,
    bindings: {
        superModuleId: '<',
        moduleId: '<',
        methodId: '<',
        subjectId: '<',
        tags: '<',
        exerciseCategories: '<',
        mediaContentTypes: '<',
        publishingHouse: '<',
        modules: '<', // needed for moving medialinks to other module
    },
};

function cleanHasErrorClasses(index) {
    if (document.getElementById(`mediaLinkGroup${index}`)) document.getElementById(`mediaLinkGroup${index}`).parentElement.className = document.getElementById(`mediaLinkGroup${index}`).parentElement.className.replace(/ has-error/g, '');
    if (document.getElementById(`mediaLinkContentType${index}`)) document.getElementById(`mediaLinkContentType${index}`).parentElement.className = document.getElementById(`mediaLinkContentType${index}`).parentElement.className.replace(/ has-error/g, '');
    if (document.getElementById(`exerciseCategory${index}`)) document.getElementById(`exerciseCategory${index}`).parentElement.className = document.getElementById(`exerciseCategory${index}`).parentElement.className.replace(/ has-error/g, '');
}

function placeHasErrorClasses(mediaLink, index, contentTypes, exerciseCategories) {
    let valid = true;
    if (!mediaLink.group) {
        if (document.getElementById(`mediaLinkGroup${index}`)) document.getElementById(`mediaLinkGroup${index}`).parentElement.className += ' has-error';
        valid = false;
    }

    if (!mediaLink.contentType
        || findIndex(contentTypes, ct => ct.id === mediaLink.contentType) === -1
    ) {

        if (document.getElementById(`mediaLinkContentType${index}`)) document.getElementById(`mediaLinkContentType${index}`).parentElement.className += ' has-error';
        valid = false;
    }

    let exerciseCategoryFound = true;
    if (mediaLink.exerciseCategories) {
        for (let i = 0; i < mediaLink.exerciseCategories.length; i++) {
            const mec = mediaLink.exerciseCategories[i];
            exerciseCategoryFound = some(exerciseCategories, ['id', mec]);
            if (!exerciseCategoryFound) break;
        }
    }

    return valid;
}

function deleteMediaLinkChain(mediaLinksToBeDeleted) {
    if (mediaLinksToBeDeleted.length > 0) {
        return mediaLinksToBeDeleted[0].$destroy()
            .then(() => {
                mediaLinksToBeDeleted.shift();
                deleteMediaLinkChain(mediaLinksToBeDeleted);
            });
    }
    return Promise.resolve();
}
