/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import {
    arrayOf,
    bool,
    object,
    oneOf,
    shape,
    string,
    number,
} from 'prop-types';
import sortBy from 'lodash/sortBy';

import CollapsePanel from '../../../../../components/CollapsePanel';
import Label from '../../../../../components/Label';
import ConfirmationTable from './ConfirmationTable';
import MediaLinkKind from '../../../utitilies/medialink-kind';

const GroupBy = {
    CHANGE: 'change',
    TOC: 'toc',
};

function ImportConfirmation({
    rows,
    groupBy,
}) {
    const groups = useMemo(() => {
        if (groupBy === GroupBy.CHANGE) {
            const initialMap = {
                moved: {
                    title: 'Moved from shelf',
                    data: [],
                },
                created: {
                    title: 'Created',
                    data: [],
                },
                updated: {
                    title: 'Metadata updated',
                    data: [],
                },
            };

            return rows.reduce((map, current, idx) => {
                const { action, Kind } = current;
                const { operation } = action || {};

                // We want to indicate the row number of the original excel, so +1 because it's zero indexed and + 1 for the header row
                const rowId = idx + 2;

                switch (operation) {
                    case 'insert': {
                        if (Kind === MediaLinkKind.FILE) {
                            map.moved.data.push({ ...current, id: rowId });
                        } else {
                            map.created.data.push({ ...current, id: rowId });
                        }
                        break;
                    }
                    case 'update': {
                        map.updated.data.push({ ...current, id: rowId });
                        break;
                    }
                    default:
                        break;
                }

                return map;
            }, initialMap);
        }
        const orderedByHierarchy = sortBy(rows, ['Node Hierarchy']);

        return orderedByHierarchy.reduce((map, current) => {
            const {
                'Node Title': nodeTitle,
                'Node Hierarchy': nodeHierarchy,
                action,
            } = current;

            if (action && action.operation) {
                // We want to indicate the row number of the original excel, so +1 because it's zero indexed and + 1 for the header row
                const rowId = rows.indexOf(current) + 2;

                if (!map[nodeHierarchy]) map[nodeHierarchy] = { title: nodeTitle, data: [] };
                map[nodeHierarchy].data.push({ ...current, id: rowId });
            }

            return map;
        }, {});
    }, [rows, groupBy]);

    return (
        <div data-testid="import-confirmation">
            <h4>Import Overview</h4>
            {
                Object
                    .entries(groups)
                    .map(([key, { title, data }]) => (
                        <CollapsePanel
                            data-testid="group"
                            key={key}
                            defaultCollapsed={data.length === 0}
                            endAdornment={(
                                <Label
                                    data-testid="group-item-count"
                                    className="import-confirmation__count"
                                    variant={data.length > 0 ? 'warning' : 'default'}
                                >
                                    {data.length}
                                </Label>
                            )}
                            title={title}
                        >
                            <ConfirmationTable rows={data} omitNode={groupBy === GroupBy.TOC} />
                        </CollapsePanel>
                    ))
            }
        </div>
    );
}

ImportConfirmation.propTypes = {
    rows: arrayOf(shape({
        'Node Title': string.isRequired,
        'Node Hierarchy': arrayOf(number).isRequired,
        Kind: string.isRequired,
        Name: string.isRequired,
        outdated: bool,
        action: shape({
            operation: oneOf(['insert', 'update']).isRequired,
            changes: object,
        }),
    })).isRequired,
    groupBy: oneOf(Object.values(GroupBy)).isRequired,
};

export default ImportConfirmation;
