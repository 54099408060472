import React from 'react';

export const CHECKBOX_STATES = {
    Checked: 'Checked',
    Indeterminate: 'Indeterminate',
    Empty: 'Empty',
};

export type CheckboxState = typeof CHECKBOX_STATES[keyof typeof CHECKBOX_STATES];

const Checkbox = ({
    label, value, disabled, onClick,
}: { label: string; value: CheckboxState; disabled: boolean; onClick: (e: React.MouseEvent<HTMLInputElement>) => void; }): JSX.Element => {
    const checkboxRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (!checkboxRef.current) return;
        if (value === CHECKBOX_STATES.Checked) {
            checkboxRef.current.checked = true;
            checkboxRef.current.indeterminate = false;
        } else if (value === CHECKBOX_STATES.Empty) {
            checkboxRef.current.checked = false;
            checkboxRef.current.indeterminate = false;
        } else if (value === CHECKBOX_STATES.Indeterminate) {
            checkboxRef.current.checked = false;
            checkboxRef.current.indeterminate = true;
        }
    }, [value]);

    return (
        <label className="tree-checkbox-label">
            <input ref={checkboxRef} type="checkbox" onClick={onClick} className="tree-checkbox" defaultChecked={value === CHECKBOX_STATES.Checked} disabled={disabled} />
            {label}
        </label>
    );
};

export default Checkbox;
