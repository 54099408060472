import './faq.scss';

class ShellFaq {
    /* @ngInject */
    constructor(FAQCategoryModel, $q, studioGroup) {
        this.FAQCategoryModel = FAQCategoryModel;

        this.tab = {
            FAQ: 'faq',
            CATEGORIES: 'categories',
            ABOUT: 'about',
        };

        this.$q = $q;
        this.studioGroup = { ...studioGroup, VO: 'VOLWASSENEN' };
    }

    $onInit() {
        return this.setTab(this.tab.ABOUT);
    }

    setTab(tab) {
        this.activeTab = tab;

        if (this.selectedStudioGroup && (this.isFAQTab || this.isCategoryTab)) {
            return this.getCategoriesForStudioGroup();
        }
        return this.$q.resolve();
    }

    getCategoriesForStudioGroup() {
        return this.FAQCategoryModel
            .query({
                group: this.selectedStudioGroup,
            })
            .then(categories => {
                this.categories = categories;
            });
    }

    get isAboutTab() {
        return this.activeTab === this.tab.ABOUT;
    }

    get isFAQTab() {
        return this.activeTab === this.tab.FAQ;
    }

    get isCategoryTab() {
        return this.activeTab === this.tab.CATEGORIES;
    }

    onStudioGroupChanged() {
        if (this.isFAQTab || this.isCategoryTab) return this.getCategoriesForStudioGroup();
    }
}

export default {
    template: `
    <div pt-faq>
        <div class="row">
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <ul class="nav nav-pills pull-left">
                            <li id="{{$ctrl.tab.ABOUT}}" role="presentation" ng-class="{ active: $ctrl.isAboutTab }">
                                <a ng-click="$ctrl.setTab($ctrl.tab.ABOUT)">About</a>
                            </li>
                            <li id="{{$ctrl.tab.FAQ}}" role="presentation" ng-class="{ active: $ctrl.isFAQTab }">
                                <a ng-click="$ctrl.setTab($ctrl.tab.FAQ)">FAQ's & Tutorials</a>
                            </li>
                            <li id="{{$ctrl.tab.CATEGORIES}}" role="presentation" ng-class="{ active: $ctrl.isCategoryTab }">
                                <a ng-click="$ctrl.setTab($ctrl.tab.CATEGORIES)">Categories</a>
                            </li>
                        </ul>
                        <select id="studioGroup" class="input-sm pull-right" ng-change="$ctrl.onStudioGroupChanged()" ng-model="$ctrl.selectedStudioGroup" ng-options="key as value for (key , value) in $ctrl.studioGroup">
                            <option disabled value="">-- Select a studio --</option>
                        </select>

                    </div>
                    <div class="panel-body">
                        <div ng-if="!$ctrl.selectedStudioGroup" class="p-faq--no-studio-selected">Please select a studio</div>
                        <div ng-if="$ctrl.selectedStudioGroup">
                            <about-manager ng-if="$ctrl.isAboutTab" studio-group="$ctrl.selectedStudioGroup"></about-manager>
                            <faq-manager ng-if="$ctrl.isFAQTab" categories="$ctrl.categories" studio-group="$ctrl.selectedStudioGroup"></faq-manager>
                            <faq-category-manager ng-if="$ctrl.isCategoryTab" categories="$ctrl.categories" studio-group="$ctrl.selectedStudioGroup"></faq-category-manager>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `,
    controller: ShellFaq,
};
