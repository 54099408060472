import MediumEditor from 'medium-editor';
import toMarkdown from 'to-markdown';
import marked from 'marked';

import '../../../node_modules/medium-editor/src/sass/medium-editor.scss';
import '../../../node_modules/medium-editor/src/sass/themes/bootstrap.scss';

import './markdown-wysiwyg.scss';

marked.setOptions({
    breaks: true,
    sanitize: false,
});

// @ngInject
export default (debounce, $sce) => ({
    restrict: 'A',
    require: '?ngModel',
    scope: {
        editable: '=',
        buttons: '=',
        toHtml: '=',
        placeholder: '@',
        model: '=ngModel',
    },
    link: ($scope, element, attrs, ngModel) => {
        let oldValue;
        let isEditing = false;

        init();

        function init() {

            // don't do anything unless this is actually bound to a model
            if (!ngModel) {
                return;
            }

            element.addClass('markdown-wysiwyg');

            // when no custom placeholder we set a default placeholder
            element.attr('data-placeholder', $scope.placeholder || 'Type your text here...');

            $scope.$watch('editable', e => onEditableChange(e), true);

            $scope.$watch('model', () => onEditableChange($scope.editable, true), true);

            element.on('click', onClick);
            element.on('focus', () => {
                onClick();

                // We have to use this because editor.selectElement is not easily mockable
                // Discussed with Tom Marien.
                if (!window.isJSDom) {//eslint-disable-line
                    if ($scope.editor.elements && $scope.editor.elements[0].childElementCount > 0) {
                        const indexOfLastChildNode = $scope.editor.elements[0].childElementCount - 1;
                        $scope.editor.selectElement($scope.editor.elements[0].childNodes[indexOfLastChildNode]);
                    }
                }
            });

            element.on('blur', debounce(e => {
                // check if we click on a medium editor specific toolbar action
                // if so, don't remove editor (aka ignore blur)
                // debounce is needed to trigger editableChange first before blur
                // e.g. clicking on save button
                if (!e.relatedTarget || (e.relatedTarget && e.relatedTarget.classList.contains('medium-editor-action') < 0)) {
                    removeEditor();
                    if (!hasContent()) element.addClass('placeholder');
                }
            }, 200));

            if (attrs.maxMarkdownLength) {
                ngModel.$validators.maxMarkdownLength = function (modelValue, viewValue) {
                    const value = modelValue || viewValue;
                    if (!value) return true;
                    const valid = value.length <= attrs.maxMarkdownLength;
                    return valid;
                };
            }

            // model to view
            ngModel.$formatters.push(value => {
                if (!value) {
                    return null;
                }

                // md to html
                return marked(value);
            });

            // view to model
            ngModel.$parsers.unshift(value => {
                if (value === '' || !value) {
                    return '';
                }

                value = value.replace(/<dd><\/dd>/g, ''); // strip appended dd tag for focus

                // html to md
                if (!$scope.toHtml) {
                    return toMarkdown(value, {
                        converters: [{
                            filter: 'br',
                            replacement: () => '<br />',
                        }],
                    });
                }

                return value;
            });

            $scope.$on('$destroy', cleanUp);
            ngModel.$render = render;
        }

        function hasContent() {
            return ngModel.$modelValue;
        }

        function onEditableChange(editable, modelChange) {
            if (editable) {
                if (hasContent()) {
                    createEditor();
                } else if (!modelChange) {
                    element.addClass('placeholder');
                }
            } else {
                if (hasContent()) {
                    element.removeClass('placeholder');
                } else {
                    element.addClass('placeholder');
                }

                removeEditor();
            }
        }

        function createEditor() {
            if ($scope.editor) return;
            const buttons = $scope.buttons ? $scope.buttons : ['bold', 'underline', 'italic', 'unorderedlist', 'orderedlist', 'h1', 'h2', 'h3'];

            $scope.editor = new MediumEditor(element, {
                placeholder: false,
                toolbar: {
                    allowMultiParagraphSelection: true,
                    buttons,
                },
            });

            oldValue = ngModel.$viewValue;

            isEditing = true;
            element.addClass('editable');
            element.removeClass('placeholder');

            // For focus, will be removed when saving
            if (!oldValue) element.append('<dd />');

            element.on('keydown', onKeyDown);
            $scope.editor.subscribe('editableInput', onEditableInput());
        }

        function removeEditor() {
            if ($scope.editor) {
                $scope.editor.unsubscribe('editableInput', onEditableInput());
                $scope.editor.destroy();
                $scope.editor = null;
            }

            element.removeClass('editable');
            element.off('keydown');

            isEditing = false;
        }

        function cleanUp() {
            element.off('click');
            element.off('blur');
            element.off('focus');
            removeEditor();
        }

        function onClick() {
            if ($scope.editable && !isEditing) createEditor();
        }

        function onEditableInput() {
            return debounce(onInput, 200);
        }

        function onInput() {
            $scope.$evalAsync(read);
        }

        function onKeyDown(e) {
            if (e.keyCode === 27) {
                ngModel.$setViewValue(oldValue);
                ngModel.$render();
                element[0].blur();
            }
        }

        function render() {
            element.html($sce.getTrustedHtml(ngModel.$viewValue || ''));
        }

        function stripHTML(data) {
            const html = data;
            const div = document.createElement('div');
            div.innerHTML = html;
            let text = div.textContent || div.innerText || '';
            text = text.replace(/<([^>]+)>/g, '');
            text = text.replace(/\r?\n|\r/g, ''); // line breaks
            text = text.replace(/(?:\/\*(?:[\s\S]*?)\*\/)|(?:([\s;])+\/\/(?:.*)$)/gm, ''); // comments
            text = text.replace(/^\s+|\s+$/g, ''); // leading and trailing spaces

            return text;
        }

        function read() {
            const html = element.html();
            const stripped = stripHTML(html);

            if (stripped === '') {
                element.html('');
                ngModel.$setViewValue('');
            } else {
                ngModel.$setViewValue(html);
            }
        }

    },
});
