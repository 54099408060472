import angular from 'angular';
import component from './app-types.view';
import screenshotComponent from './components/screenshot-manager.component';

const module = angular.module('app.shell.modules.app-types', []);

module
    .config(($stateProvider) => {
        // @ngInject
        $stateProvider.state('app-types', {
            url: '/app-types',
            template: `
                <shell-app-types></shell-app-types>
            `,
            security: (permissionService) => permissionService.canManageAppTypes,
        });
    })
    .component('shellAppTypes', component)
    .component('shellAppTypesScreenshotManager', screenshotComponent);

export default module.name;
