import './upload.component.scss';

class UploadComponent {

    // @ngInject
    constructor($http, FileUploader, store, fileUploadValidationService) {
        this.$http = $http;
        this.FileUploader = FileUploader;
        this.store = store;
        this.fileUploadValidationService = fileUploadValidationService;
    }

    $onInit() {
        this.itemsRejected = [];

        this.uploader = new this.FileUploader({
            autoUpload: false,
            removeAfterUpload: true,
            url: this.uploadUrl,
            onAfterAddingFile: item => {
                if (this.itemAddedToQueue) {
                    this.itemAddedToQueue({ item });
                }

                if (!this.uploadUrl) {
                    const resource = { originalFileName: item.file.name, mimeType: item.file.type };

                    return this.$http.post('/api/file-upload', resource)
                        .then(({ data }) => {

                            item.url = data.postData.url;
                            item.fileId = data.fileId;

                            item.formData.push({ 'Content-Type': resource.mimeType });

                            Object.entries(data.postData.fields).forEach(([key, val]) => {
                                item.formData.push({ [key]: val });
                            });

                            return item.upload();
                        });
                }

                return item.upload();
            },
            onBeforeUploadItem: item => {
                if (this.uploadUrl) {
                    const token = this.store.get('p-access_token');

                    if (token) {
                        item.headers = {
                            Authorization: `Bearer ${token}`,
                        };
                    }

                    if (item.context) item.formData = [{ moduleId: item.context.moduleId }];
                }
            },
            onCompleteItem: (item, response, status, headers) => {
                if (item.isSuccess && status === 201 && this.completeItem) {
                    const fileId = headers.location.split('/')[headers.location.split('/').length - 1];
                    this.completeItem({
                        fileId,
                        item,
                    });

                } else if (item.isSuccess && status === 204 && this.completeItem) {
                    const { fileId } = item;

                    this.completeItem({
                        fileId,
                        item,
                    });
                } else if (status === 404 && this.itemFileNotFound) {
                    this.itemFileNotFound({ item, errors: this.itemsRejected });
                }
            },
            onProgressAll: progress => {
                this.allProgress = progress;
            },
            onCompleteAll: () => {
                this.allProgress = 0;

                if (this.uploadCompleted) {
                    this.uploadCompleted({ errors: this.itemsRejected });
                }
            },
            onWhenAddingFileFailed: (item, filter) => {
                if (filter.name === 'extensionAndSize') {
                    const errorMessage = this.fileUploadValidationService.validate(item.name, item.size);

                    this.itemsRejected.push({
                        name: item.name,
                        message: errorMessage,
                    });
                }

                if (this.addingFileFailed) {
                    this.addingFileFailed({ filter, item, errors: this.itemsRejected });
                }
            },
        });

        // file extension and size filter
        this.uploader.filters.push({
            name: 'extensionAndSize',
            fn: item => this.fileUploadValidationService.isValid(item.name, item.size),
        });

        if (this.onInitialized) {
            this.onInitialized({
                uploader: this.uploader,
            });
        }

        return this.fileUploadValidationService.initialize();
    }

    cancelItem(item, index) {
        if (index > 0) {
            this.uploader.removeFromQueue(index);
        }
        item.cancel();
    }

    removeErrorMessage(index) {
        this.itemsRejected.splice(index, 1);
    }
}

export default {
    template: `
    <div class="file-upload">
        <div ng-if="$ctrl.uploader.queue.length > 0 || $ctrl.itemsRejected.length > 0" class="panel panel-default" pt-upload-progress>
            <div class="panel-heading">
                <p class="panel-title pull-left">Upload status</p>
                <div class="pull-right" ng-if="$ctrl.itemsRejected.length > 0">
                    <button class="btn btn-danger" ng-click="$ctrl.itemsRejected = []" pt-clear-rejected>Clear</button>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="panel-body">
                <div class="uploading-block">
                    <table class="table table-responsive">
                        <thead>
                            <tr>
                                <th class="col-sm-8">Name</th>
                                <th colspan="2" class="col-sm-4">Upload status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="loading-row" ng-repeat="item in $ctrl.uploader.queue track by $index" pt-uploader-queue>
                                <td>{{item.file.name}}</td>
                                <td>
                                    <uib-progressbar ng-if="item.progress" value="item.progress">
                                        {{item.progress}}%
                                    </uib-progressbar>
                                </td>
                                <td>
                                    <a ng-click="$ctrl.cancelItem(item, index)"
                                       class="glyphicon glyphicon-remove remove-medialink"></a>
                                </td>
                            </tr>

                            <tr class="loading-row" ng-repeat="item in $ctrl.itemsRejected track by $index" pt-items-rejected>
                                <td>{{item.name}}</td>
                                <td>
                                    <span class="glyphicon glyphicon-warning-sign"></span> {{item.message}}
                                </td>
                                 <td>
                                    <a ng-click="$ctrl.removeErrorMessage($index)"
                                       class="glyphicon glyphicon-remove remove-medialink"></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="panel panel-default">
            <div ng-if="!$ctrl.enabled" class="panel-body qs-drop-disabled">
                <div ng-transclude></div>
            </div>
            <div id="upload-container" ng-if="$ctrl.enabled" nv-file-over nv-file-drop uploader="$ctrl.uploader" class="panel-body qs-drop-enabled">
                <div ng-transclude></div>
            </div>
        </div>
    </div>`,
    controller: UploadComponent,
    transclude: true,
    bindings: {
        itemAddedToQueue: '&?',
        itemFileNotFound: '&?',
        completeItem: '&?',
        uploadFilters: '<?',
        enabled: '<',
        onInitialized: '&?',
        uploadUrl: '<?',
        uploadCompleted: '&?',
        addingFileFailed: '&?',
    },
};
