import React, { useMemo, useEffect, useState } from 'react';
import {
    arrayOf,
    shape,
    string,
    func,
    bool,
} from 'prop-types';

import orderBy from 'lodash/orderBy';
import classNames from 'classnames';

import Button from './Button';
import GlyphIcon from './GlyphIcon';

import './MultiSelectWithSearch.scss';

export default function MultiSelectWithSearch({
    items, selectedIds, onChange, disabled, placeholder,
}) {
    const [searchTerm, setSearchTerm] = useState('');

    const selectedItems = useMemo(() => (
        selectedIds
            .map(id => items.find(item => item.id === id) || { id, name: 'This item is no longer valid.', invalid: true })
    ), [items, selectedIds]);

    const searchResults = useMemo(() => {
        const searchTermMatcher = searchTerm.toLowerCase();
        const filteredItems = searchTerm
            ? items
                .filter(item => !selectedIds.includes(item.id))
                .filter(item => item.name.toLowerCase().includes(searchTermMatcher))
            : [];

        return orderBy(filteredItems, ['name']);
    }, [searchTerm, items, selectedIds]);

    const handleItemSelection = item => {
        onChange([...selectedIds, item.id]);
        setSearchTerm('');
    };

    const handleItemDelete = item => {
        onChange(selectedIds.filter(id => id !== item.id));
    };

    useEffect(() => {
        setSearchTerm('');
    }, [disabled]);

    useEffect(() => {
        function closeDropDown(e) {
            if (e.key === 'Escape') {
                e.stopPropagation();
                setSearchTerm('');
            }
        }

        if (searchTerm && searchResults.length > 0) {
            window.addEventListener('keydown', closeDropDown, { capture: true });
        }

        return () => {
            if (searchTerm && searchResults.length > 0) {
                window.removeEventListener('keydown', closeDropDown, { capture: true });
            }
        };
    }, [searchTerm, searchResults]);

    return (
        <div className="multi-select-wrapper" data-testid="multi-select-wrapper">
            <ul className="list-group list-group-flush">
                {
                    selectedItems.map(item => (
                        <li className="col-md-12 list-group-item" key={item.id}>
                            <div
                                className={classNames('col-md-11', { 'text-danger': item.invalid })}
                            >
                                {item.name}
                            </div>
                            <Button
                                data-testid={`delete-${item.id}`}
                                className="col-md-1 btn-xs"
                                type="button"
                                variant="danger"
                                onClick={() => handleItemDelete(item)}
                            >
                                <GlyphIcon iconName="remove" />
                            </Button>
                        </li>
                    ))
                }
            </ul>
            <input
                id="search"
                autoComplete="off"
                className="form-control"
                type="text"
                placeholder={placeholder}
                role="searchbox"
                onChange={e => setSearchTerm(e.target.value)}
                value={searchTerm}
                disabled={disabled}
            />
            {
                searchTerm && searchResults.length > 0 && (
                    <div data-testid="search-results" className="dropdown-menu show" aria-labelledby="search">
                        {
                            searchResults
                                .map(item => (
                                    <div key={item.id} className="dropdown-item">
                                        <Button className="btn-block" variant="light" type="button" onClick={() => handleItemSelection(item)}>
                                            <span className="pull-left">{item.name}</span>
                                        </Button>
                                    </div>
                                ))
                        }
                    </div>
                )
            }
        </div>
    );
}

MultiSelectWithSearch.propTypes = {
    items: arrayOf(shape({
        id: string.isRequired,
        name: string.isRequired,
    })).isRequired,
    selectedIds: arrayOf(string),
    onChange: func.isRequired,
    disabled: bool,
    placeholder: string,
};

MultiSelectWithSearch.defaultProps = {
    selectedIds: [],
    placeholder: '',
    disabled: false,
};
