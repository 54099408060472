class ImageUploadController {
    // @ngInject
    constructor(FileUploader, store, config) {
        this.FileUploader = FileUploader;
        this.store = store;
        this.config = config;
    }

    $onInit() {
        const token = this.store.get('p-access_token');
        this.fileProgress = 0;

        this.uploader = new this.FileUploader({
            autoUpload: true,
            removeAfterUpload: true,
            url: this.config.baseUrl + this.fileApiUrl,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            onCompleteItem: (item, response, status, headers) => {
                this.fileProgress = 0;

                if (status === 201) {
                    const location = headers.location;

                    const id = location.substr(location.lastIndexOf('/') + 1);

                    return this.imageUploaded ? this.imageUploaded({ id }) : this.ngModel = id;
                }
            },
            onProgressItem: (item, progress) => {
                this.fileProgress = progress;
            },
        });

        // DEFAULT BINDINGS
        this.required = angular.isUndefined(this.required) ? false : this.required;
        this.ngHeight = angular.isUndefined(this.ngHeight) ? '100px' : this.ngHeight;
        this.ngWidth = angular.isUndefined(this.ngWidth) ? '100px' : this.ngWidth;
        this.ngDraggable = angular.isUndefined(this.ngDraggable) ? false : this.ngDraggable;
    }

    get imageUrl() {
        return (this.fileApiUrl && this.ngModel) ? `${this.config.baseUrl}${this.fileApiUrl}/${this.ngModel}` : '';
    }

    clearImage() {
        return this.imageCleared ? this.imageCleared({ id: this.ngModel }) : this.ngModel = undefined;
    }
}

export default {
    template: `<div class="image-uploader-component" ng-style="{ 'height': $ctrl.ngHeight, 'width': $ctrl.ngWidth }">
    <div class="image-uploader__wrapper">
        <uib-progressbar value="$ctrl.fileProgress" ng-if="$ctrl.fileProgress > 0" class="image-uploader__progress">
            {{$ctrl.fileProgress}}%
        </uib-progressbar>

        <div ng-if="$ctrl.ngModel" class="image-uploader__actions">
            <div ng-if="$ctrl.ngDraggable" class="image-uploader__action image-uploader__action--move">
                <span class="glyphicon glyphicon-move"></span>
            </div>
            <div class="image-uploader__action image-uploader__action--remove" ng-click="$ctrl.clearImage()">
                <span class="glyphicon glyphicon-remove"></span>
            </div>
        </div>

        <div class="image-uploader__preview">
            <div class="image-uploader__popover" ng-class="{ 'image-uploader__popover--noimage': !$ctrl.ngModel }">
                <span class="glyphicon glyphicon-plus-sign"></span>
            </div>

            <img ng-if="$ctrl.ngModel" ng-src="{{ $ctrl.imageUrl }}"/>

            <input type="file"
                   accept="{{$ctrl.accept}}"
                   id="{{$ctrl.ngId}}"
                   class="image-uploader__upload"
                   name="{{$ctrl.name}}"
                   title="Upload an image"
                   nv-file-select
                   uploader="$ctrl.uploader"
                   ng-required="$ctrl.required"
            />

        </div>
    </div>
</div>`,
    controller: ImageUploadController,
    bindings: {
        ngModel: '=',
        name: '@',
        required: '<?',
        accept: '@?',
        ngId: '<?',
        fileApiUrl: '@',
        ngWidth: '@?',
        ngHeight: '@?',
        imageUploaded: '&?',
        imageCleared: '&?',
        ngDraggable: '<?',
    },
};
