import React from 'react';
import {
    arrayOf,
    number,
    shape,
    string,
} from 'prop-types';

function ImportErrorView({ errorDetails }) {
    return (
        <table role="table" className="table" data-testid="medialink-error-table">
            <thead>
                <tr>
                    <th>Line</th>
                    <th>Column</th>
                    <th>Medialink Name</th>
                    <th>Error</th>
                </tr>
            </thead>
            <tbody>
                {
                    errorDetails.map((detail, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <tr data-testid="table-line" key={index}>
                            <td>{detail.index !== undefined && detail.index + 2}</td>
                            <td>{detail.key}</td>
                            <td>{detail.name}</td>
                            <td>{detail.message}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    );
}

ImportErrorView.propTypes = {
    errorDetails: arrayOf(shape({
        index: number,
        key: string.isRequired,
        message: string.isRequired,
        name: string,
    })).isRequired,
};

export default ImportErrorView;
