import angular from 'angular';

import component from './faq.view';
import aboutManager from './components/about-manager';
import faqManager from './components/faq-manager';
import faqCategoryManager from './components/faq-category-manager';

const module = angular.module('app.shell.modules.faq', []);

module
    .config(($stateProvider) => {
        // @ngInject
        $stateProvider
            .state('faq', {
                url: '/faq',
                title: 'FAQ - shell - p',
                template: `
                    <shell-faq></shell-faq>
                `,
                security: permissionService => permissionService.canManageFaq,
            });
    })
    .component('shellFaq', component)
    .component('aboutManager', aboutManager)
    .component('faqManager', faqManager)
    .component('faqCategoryManager', faqCategoryManager);

export default module.name;
