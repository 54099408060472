import './subjects-methods.scss';

class SubjectsMethods {
    // @ngInject
    constructor(PublishingHouseModel, studioGroup) {
        this.PublishingHouseModel = PublishingHouseModel;
        this.studioGroup = studioGroup;
        this.publishingHouses = [];
    }

    $onInit() {
        return this.getPublishingHouses();
    }

    onSubjectsLoaded(subjects) {
        this.subjects = subjects;

        if (this.selectedSubject) {
            if (!this.subjects.find(s => {
                return s.id === this.selectedSubject.id;
            })) {
                this.selectedMethod = undefined;
                this.selectedSubject = undefined;
            }
        }
    }

    onSubjectChanged(subject) {
        this.selectedSubject = subject;
    }

    onMethodChanged(method) {
        this.selectedMethod = method;
    }

    onMethodsLoaded(methods) {
        this.methods = methods;
    }

    getPublishingHouses() {
        return this.PublishingHouseModel
            .query({ orderBy: 'rank' })
            .then(publishingHouses => {
                this.publishingHouses = publishingHouses.map(x => {
                    x.groupName = this.studioGroup[x.group];
                    return x;
                });
            });
    }
}

export default {
    template: `<div class="subjects-methods-view">
    <div class="row">
        <div class='page-header'>
            <h2>Subjects and methods</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <shell-subjects-component publishing-houses="$ctrl.publishingHouses"
                                      selected-subject="$ctrl.selectedSubject"
                                      subjects-loaded="$ctrl.onSubjectsLoaded(subjects)"
                                      subject-changed="$ctrl.onSubjectChanged(subject)"></shell-subjects-component>
        </div>

        <div class="col-md-4">
            <shell-methods-component subjects="$ctrl.subjects"
                                     selected-subject="$ctrl.selectedSubject"
                                     selected-method="$ctrl.selectedMethod"
                                     subject-changed="$ctrl.onSubjectChanged(subject)"
                                     methods-loaded="$ctrl.onMethodsLoaded(methods)"
                                     method-changed="$ctrl.onMethodChanged(method)"></shell-methods-component>
        </div>

        <div class="col-md-4">
            <shell-modules-component methods="$ctrl.methods"
                                     selected-method="$ctrl.selectedMethod"
                                     selected-method-changed="$ctrl.onMethodChanged(method)"></shell-modules-component>
        </div>
    </div>
</div>
    `,
    controller: SubjectsMethods,
};
