import React, { useMemo } from 'react';
import { copyTableOfContent } from '../api/TableOfContentApi';
import useTableOfContentHierarchy from '../hooks/useTableOfContentHierarchy';

type TocHierachyFilterProps = {
    publishingHouseId: string;
    selectedSubjectId: string;
    selectedMethodId: string;
    selectedModuleId: string;
    onCopy: () => void;
};

export default function TocHierarchyFilter({
    publishingHouseId,
    selectedSubjectId,
    selectedMethodId,
    selectedModuleId,
    onCopy,
}: TocHierachyFilterProps): JSX.Element {
    const {
        subjects, methods, modules, formValues, handleChange,
    } = useTableOfContentHierarchy(
        publishingHouseId,
        selectedSubjectId,
        selectedMethodId,
        selectedModuleId
    );

    const isCopyDisabled = useMemo(() => {
        if (
            !formValues.targetSubjectId
            || !formValues.targetMethodId
            || !formValues.targetModuleId
        ) {
            return true;
        }
        return false;
    }, [formValues]);

    const onButtonClick = async (navigate?: boolean) => {
        await copyTableOfContent(selectedModuleId, formValues.targetModuleId);

        if (navigate) {
            document.dispatchEvent(
                new CustomEvent('set-hierarchy-filter', {
                    detail: {
                        subjectId: formValues.targetSubjectId,
                        methodId: formValues.targetMethodId,
                        moduleId: formValues.targetModuleId,
                    },
                })
            );
        }

        onCopy();
    };

    return (
        <div className="dropdown-menu" role="menu">
            <strong>Copy to:</strong>
            <select
                value={formValues.targetSubjectId}
                className="form-control"
                name="targetSubjectId"
                onChange={e => handleChange('targetSubjectId', e.target.value)}
            >
                {subjects.map(subject => (
                    <option key={subject.id} value={subject.id}>
                        {subject.name}
                    </option>
                ))}
            </select>

            <select
                value={formValues.targetMethodId}
                className="form-control"
                name="targetMethodId"
                onChange={e => handleChange('targetMethodId', e.target.value)}
            >
                {methods.map(method => (
                    <option key={method.id} value={method.id}>
                        {method.name}
                    </option>
                ))}
            </select>

            <select
                value={formValues.targetModuleId}
                className="form-control"
                name="targetModuleId"
                onChange={e => handleChange('targetModuleId', e.target.value)}
                disabled={modules?.length === 0}
            >
                <option value="" disabled>
                    -- Select a module --
                </option>
                {modules?.map(module => (
                    <option key={module.id} value={module.id}>
                        {module.name}
                    </option>
                ))}
            </select>
            {modules && modules.length === 0 && (
                <span className="no-modules-available">
                    No modules with empty TOC found.
                </span>
            )}

            <div className="buttons">
                <button
                    type="button"
                    className="btn btn-success"
                    disabled={isCopyDisabled}
                    onClick={() => onButtonClick(false)}
                >
                    Copy
                </button>
                <button
                    type="button"
                    className="btn btn-success"
                    disabled={isCopyDisabled}
                    onClick={() => onButtonClick(true)}
                >
                    Copy and open
                </button>
            </div>
        </div>
    );
}
