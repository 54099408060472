/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { string, func } from 'prop-types';
import classNames from 'classnames';
import Button from '../../../components/Button';
import GlyphIcon from '../../../components/GlyphIcon';

function InlineTextFieldEditor({ initialValue, save }) {
    const [value, setValue] = useState(initialValue);
    const [editing, setEditMode] = useState(false);

    function enableEditMode(evt) {
        evt.preventDefault();

        setEditMode(true);
    }

    const exitEditMode = () => {
        setEditMode(false);
    };

    function handleChange(evt) {
        const { target: { value: newValue } } = evt;

        setValue(newValue);
    }

    function handleSubmit(evt) {
        evt.preventDefault();

        if (value !== initialValue) {
            save(value, exitEditMode);
        } else {
            exitEditMode();
        }
    }

    function cancelFormRequest() {
        exitEditMode();
        setValue(initialValue);
    }

    return (
        <form onSubmit={handleSubmit} noValidate name="text-field-editor-form">
            <div data-testid="text-field-wrapper" className={classNames('col-sm-2')}>
                <input type="text" value={value} className="form-control" disabled={!editing} onChange={handleChange} />
            </div>

            <div className="col-sm-4">
                {!editing ? (
                    <Button size="sm" onClick={enableEditMode}>
                        <GlyphIcon iconName="pencil" />
                        {' '}
                        Edit
                    </Button>
                ) : (
                    <>
                        <Button type="submit" variant="success" size="sm">
                            <GlyphIcon iconName="floppy-disk" />
                            {' '}
                            Save
                        </Button>
                        <span>&nbsp;</span>
                        <Button variant="danger" size="sm" onClick={cancelFormRequest}>
                            <GlyphIcon iconName="pencil" />
                            {' '}
                            Cancel
                        </Button>
                    </>
                )}
            </div>
        </form>
    );
}

InlineTextFieldEditor.propTypes = {
    initialValue: string,
    save: func.isRequired,
};

InlineTextFieldEditor.defaultProps = {
    initialValue: '',
};

export default InlineTextFieldEditor;
