import angular from 'angular';
import component from './exercise-categories.view';
import themeComponent from './components/theme.component';

const module = angular.module('app.shell.modules.exercise-categories', []);

module
    .config($stateProvider => {
        // @ngInject
        $stateProvider
            .state('exercise-categories', {
                url: '/exercise-categories',
                template: '<shell-exercise-categories></shell-exercise-categories>',
                security: permissionService => permissionService.canManageExerciseCategories,
            });
    })
    .component('shellExerciseCategories', component)
    .component('shellTheme', themeComponent);

export default module.name;
