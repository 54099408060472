import shellApi from '../services/api';

export type Subject = {
    id: string;
    name: string;
    slug: string;
    shortName: string;
    ignoreOnTeacher: boolean;
    klopotekCode: string;
    publishingHouseId: string;
    publishingState: 'draft' | 'preview' | 'published';
    responsiblePersons: string[];
};

export type Method = {
    id: string;
    educationalTypes: string[];
    klopotekCode: string;
    language?: 'nl' | 'fr';
    name: string;
    niceName: string;
    publishingState: 'draft' | 'preview' | 'published';
    catalogName: string;
    slug: string;
    lessonDisplay: 'neutral' | 'big_number' | 'big_title';
    styling?: {
        primaryColor?: string;
        secundaryColor?: string;
        logoId?: string;
        logoUrl?: string;
    };
    subject: string;
    webShopUrl?: string;
    hostedExternally?: boolean;
    externalContentUrl?: string;
    hasAlternativeStudentSite?: boolean;
    alternativeStudentSiteUrl?: string;
    weight: number;
};

export type Module = {
    id: string;
    name: string;
    niceName: string;
    catalogName: string;
    slug: string;
    bookType?: string;
    years: string[];
    methodId: string;
    publishingState: 'draft' | 'preview' | 'published';
    releaseDate?: Date;
    styling?: {
        primaryColor?: string;
        secundaryColor?: string;
        logoId?: string;
        logoUrl?: string;
        visualId?: string;
        visualUrl?: string;
        coverId?: string;
        coverUrl?: string;
    };
    codesRequestable: boolean;
    weight: number;
    incomplete: boolean;
    hasExerciseEntrance: boolean;
    hideExerciseEntrance: boolean;
    enableSlideSetsAccess: boolean;
    disableLessonSorting: boolean;
    defaultSorting: 'category' | 'lesson_order';
    hostedExternally: boolean;
    externalContentUrl?: string;
    hasAlternativeStudentSite?: boolean;
    alternativeStudentSiteUrl?: string;
    allowedExternals?: string[];
    type: 'SUB' | 'SUPER';
    subModules?: string[];
};

type TableOfContentNode = {
    id?: string;
    title: string;
    description?: string;
    medialinkCount: number;
    prefix?: string;
    displayPrefix: boolean;
    imageId?: string;
    dontShowImageInStudentStudio?: boolean;
    nodes?: TableOfContentNode[];
    styling?: {
        primaryColor?: string;
        secundaryColor?: string;
    };
};

type TableOfContent = {
    maxDrawerLevel: number;
    module: string;
    nodes: TableOfContentNode[];
};

export const getSubjects = async (
    publishingHouseId: string
): Promise<Subject[]> => {
    const {
        data: { data },
    } = await shellApi.get('/shell/subjects', {
        params: {
            orderBy: 'name',
            publishingHouseId,
        },
    });

    return data;
};

export const getMethods = async (subjectId: string): Promise<Method[]> => {
    const {
        data: { data },
    } = await shellApi.get('/shell/methods', {
        params: {
            orderBy: 'name',
            subjectId,
        },
    });

    return data;
};

export const getModules = async (methodId: string): Promise<Module[]> => {
    const {
        data: { data },
    } = await shellApi.get('/shell/modules', {
        params: {
            orderBy: 'name',
            methodId,
        },
    });

    return data;
};

export const getTableOfContentForModule = async (
    moduleId: string
): Promise<TableOfContent> => {
    const { data } = await shellApi.get(`/shell/modules/${moduleId}/table-of-content`);

    return data;
};
