/* @ngInject */
export default function SubjectModelFactory($modelFactory) {
    return $modelFactory('shell/subjects', {
        actions: {
            query: {
                afterRequest: response =>
                    // map inner subjects array
                    response.data,
            },

            get: {
                afterRequest: response =>
                    // map inner subjects array
                    response.data[0],
            },

            update: {
                beforeRequest: req => {
                    if (req.data.id) {
                        delete req.data.id;
                    }
                },
            },
        },
    });
}
