import './tags.scss';

class Tags {
    // @ngInject
    constructor($scope, TagModel) {
        this.$scope = $scope;
        this.TagModel = TagModel;
        this.showArchived = false;
        this.addDisabled = true;
    }

    onFilterChange(filter) {
        this.filter = filter;
        if (this.filter.method) {
            this.addDisabled = false;
            this.getMethodSpecificTags(this.filter.method);
        } else {
            this.addDisabled = true;
        }
    }

    getMethodSpecificTags(methodId) {
        if (methodId) {
            this.TagModel
                .query({
                    methodId,
                    archived: this.showArchived,
                    orderBy: 'name',
                })
                .then(tags => {
                    this.methodSpecificTags = tags;
                });
        }
    }

    addTag() {
        this.newTag = new this.TagModel({
            methodId: this.filter.method,
            name: '',
            archived: false,
            enabledModules: [],
        });

        this.methodSpecificTags.unshift(this.newTag);
    }

    onShowArchivedClick() {
        this.getMethodSpecificTags(this.filter.method);
    }

}

export default {
    template: `
    <div class="tags-view">
        <div class="row">
            <div class='page-header'>
                <h2>Method specific tags</h2>
            </div>
        </div>
        <div class="row">
            <div class="panel panel-default">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-sm-4">
                            <hierarchy-filter
                                levels="3"
                                filter-change="$ctrl.onFilterChange(filter)">
                            </hierarchy-filter>
                            <div class="row col-xs-12 tag-actions">
                                <div class="form-group pull-left">
                                    <input  id="archivedTags"
                                            ng-click="$ctrl.onShowArchivedClick()"
                                            ng-disabled="!$ctrl.filter.method"
                                            name="archivedTags"
                                            ng-model="$ctrl.showArchived"
                                            type="checkbox">
                                    <label for="archivedTags">Show archived tags</label>
                                </div>
                                <button class="btn btn-success pull-right"
                                        ng-disabled="$ctrl.addDisabled"
                                        ng-click="$ctrl.addTag()">
                                    <span></span> Add new tag
                                </button>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-sm-12">
                            <shell-tag-list
                                filter="$ctrl.filter"
                                modules="$ctrl.modules"
                                tag-list="$ctrl.methodSpecificTags">
                            </shell-tag-list>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `,
    controller: Tags,
};
