import DeepDiff from 'deep-diff';
import get from 'lodash/get';
import set from 'lodash/set';

/* @ngInject */
export default function ExerciseCategoryModelFactory($modelFactory) {
    const model = $modelFactory('shell/methods/{method}/exercise-categories', {
        actions: {
            query: {
                afterRequest: res => res.data,
            },
            post: {
                beforeRequest: req => {
                    if (req.data.difficulty && req.data.difficulty === '') req.data.difficulty = 0;
                    delete req.data.editMode;
                },
            },
        },
        instance: {
            $patch: function () { // eslint-disable-line object-shorthand
                const changedPaths = (this.$diff() || []).map(d => d.path.join('.')).filter(path => ['name', 'difficulty'].indexOf(path) > -1);

                return model.$call({
                    method: 'PATCH',
                    url: `/api/shell/methods/${this.method}/exercise-categories/${this.id}`,
                    data: this,
                    invalidateCache: true,
                    wrap: true,
                    beforeRequest: function (req) { // eslint-disable-line object-shorthand
                        const data = {};

                        changedPaths.forEach(p => {
                            set(data, p, get(req.data, p));
                        });

                        if (data.difficulty === '') data.difficulty = 0;

                        req.data = data;
                    },
                });
            },
            $revert: function () { // eslint-disable-line object-shorthand
                const changes = this.$diff();
                if (changes && changes.length > 0) {
                    changes.forEach(change => DeepDiff.revertChange(this, {}, change));
                }
            },
            $link: function (module) { // eslint-disable-line object-shorthand
                return model.$call({
                    method: 'POST',
                    url: `/api/shell/modules/${module.id}/exercise-categories/${this.id}`,
                    invalidateCache: true,
                    wrap: true,
                });
            },
            $unlink: function (module) { // eslint-disable-line object-shorthand
                return model.$call({
                    method: 'DELETE',
                    url: `/api/shell/modules/${module.id}/exercise-categories/${this.id}`,
                    invalidateCache: true,
                    wrap: true,
                });
            },
        },
    });

    return model;
}
