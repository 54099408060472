/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unknown-property */
import React, {
    useState, useEffect, useMemo,
} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import {
    shape, func, number, string, bool,
} from 'prop-types';
import api from '../../services/api';

import './extensions.scss';

const Dialog = ({
    title, message, onConfirm, onCancel,
}) => (
    <div className="modal fade in" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 className="modal-title">{title}</h4>
                </div>
                <div className="modal-body">
                    <p>{message}</p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-testid="dialog-cancel" onClick={onCancel}>No</button>
                    <button type="button" className="btn btn-primary" data-testid="dialog-confirm" onClick={onConfirm}>Yes</button>
                </div>
            </div>
        </div>
    </div>
);

Dialog.propTypes = {
    title: string.isRequired,
    message: string.isRequired,
    onCancel: func.isRequired,
    onConfirm: func.isRequired,
};

const validateConstraint = constraint => {
    const errors = [];

    if (!constraint.extension || constraint.extension.length < 1) errors.push('extension');
    if (!constraint.maxSizeInKb || constraint.maxSizeInKb < 1) errors.push('maxSizeInKb');

    return errors;
};

const ViewMode = { EXTERNAL: 'EXTERNAL', INTERNAL: 'INTERNAL' };

const EditConstraint = ({ constraint: data, onCancel, onSave }) => {
    const [constraint, setConstraint] = useState(data);
    const [errors, setErrors] = useState([]);

    const handleChange = (key, value) => {
        const nextConstraint = { ...constraint, [key]: value };
        const validationErrors = validateConstraint(nextConstraint);

        if (validationErrors.length > 0) setErrors(validationErrors);

        setConstraint(nextConstraint);
    };

    const handleSave = () => {
        const validationErrors = validateConstraint(constraint);
        if (validationErrors.length > 0) return setErrors((validationErrors));
        return onSave(constraint);
    };

    useEffect(() => {
        const handleKeyDown = e => {
            if (e.keyCode === 13) handleSave();
            if (e.keyCode === 27) onCancel();
        };
        window.addEventListener('keydown', handleKeyDown);

        return () => window.removeEventListener('keydown', handleKeyDown);
    });

    return (
        <tr className="extension__add" data-testid="edit-extension-row">
            <td className={
                classNames({ 'has-error': errors.includes('extension') })
            }
            >
                <input
                    type="text"
                    className="form-control"
                    name="extension"
                    required
                    minLength="1"
                    autoFocus
                    value={constraint.extension}
                    onChange={e => handleChange('extension', e.target.value)}
                />
            </td>
            <td className={
                classNames({ 'has-error': errors.includes('maxSizeInKb') })
            }
            >
                <input
                    type="number"
                    className={classNames('form-control', {
                        'has-error': errors.includes('maxSizeInKb'),
                    })}
                    name="maxSizeInKb"
                    required
                    min="1"
                    value={constraint.maxSizeInKb}
                    onChange={e => {
                        if (e.target.value) {
                            handleChange('maxSizeInKb', parseInt(e.target.value, 10));
                        }
                    }}
                />
            </td>
            <td className="text-right">
                <button type="submit" className="btn btn-primary btn-xs btn-extension-save" title="Save" onClick={handleSave}>
                    <span className="glyphicon glyphicon-ok" aria-hidden="true" />
                </button>
                &nbsp;
                <button type="button" className="btn btn-default btn-xs btn-extension-cancel" title="Cancel" onClick={onCancel}>
                    <span className="glyphicon glyphicon-remove" />
                </button>
            </td>
        </tr>
    );
};

EditConstraint.propTypes = {
    constraint: shape({
        extension: string.isRequired,
        maxSizeInKb: number.isRequired,
        external: bool,
    }).isRequired,
    onSave: func.isRequired,
    onCancel: func.isRequired,
};

const ViewConstraint = ({ constraint, onDelete, onEdit }) => {
    const [isConfirmationOpen, setConfirmationOpen] = useState(false);

    return (
        <tr data-testid="extension-row">
            <td>
                <p className="form-control-static">{constraint.extension}</p>
            </td>
            <td className="text-right" ng-class="{ 'has-error': constraint.isEditing && editForm.maxSizeInKb.$invalid }">
                <p className="form-control-static">{constraint.maxSizeInKb}</p>
            </td>
            <td className="text-right">
                <button type="button" className="btn btn-default btn-xs btn-edit-extension" title="Edit" onClick={onEdit}>
                    <span className="glyphicon glyphicon-pencil" aria-hidden="true" />
                </button>
                &nbsp;
                <button type="button" className="btn btn-danger btn-xs btn-delete-extension" title="Delete" onClick={() => setConfirmationOpen(true)}>
                    <span className="glyphicon glyphicon-trash" aria-hidden="true" />
                </button>
            </td>
            {isConfirmationOpen && ReactDOM.createPortal(
                <Dialog title="Delete extension" message={`Are you sure you want to delete: '${constraint.extension}'?`} onConfirm={onDelete} onCancel={() => setConfirmationOpen(false)} />,
                document.body
            )}
        </tr>
    );
};

ViewConstraint.propTypes = {
    constraint: shape({
        extension: string.isRequired,
        maxSizeInKb: number.isRequired,
        external: bool,
    }).isRequired,
    onDelete: func.isRequired,
    onEdit: func.isRequired,
};

const ConstraintRow = ({
    constraint, onDelete, onCancel, onSave,
}) => {
    const [isEditing, setIsEditing] = useState(false);

    const handleSave = c => {
        setIsEditing(false);
        onSave(c);
    };

    const handleCancel = () => {
        setIsEditing(false);
        onCancel();
    };

    return isEditing ? (
        <EditConstraint constraint={constraint} onCancel={handleCancel} onSave={handleSave} />
    ) : <ViewConstraint constraint={constraint} onDelete={onDelete} onEdit={() => setIsEditing(!isEditing)} />;
};

ConstraintRow.propTypes = {
    constraint: shape({
        extension: string.isRequired,
        maxSizeInKb: number.isRequired,
        external: bool,
    }).isRequired,
    onDelete: func.isRequired,
    onCancel: func.isRequired,
    onSave: func.isRequired,
};

const getVisibleConstraints = (constraints, currentViewMode) => {
    if (currentViewMode === ViewMode.EXTERNAL) {
        return constraints.filter(c => c.external);
    }
    return constraints.filter(c => !c.external);
};

const ExtensionManager = ({ ngDispatch }) => {
    const [constraints, setConstraints] = useState([]);
    const [newConstraint, setNewConstraint] = useState();
    const [viewMode, setViewMode] = useState(ViewMode.INTERNAL);
    const [activeTab, setActiveTab] = useState('extensions');

    useEffect(() => {
        async function getConstraints() {
            const { data: { data } } = await api.get('shell/file-constraints');

            setConstraints(data);
        }

        getConstraints();
    }, []);

    const deleteConstraint = async constraintId => {
        await api.delete(`shell/file-constraints/${constraintId}`);
        setConstraints(constraints.filter(c => c.id !== constraintId));
    };

    const addConstraint = async () => {
        setNewConstraint({
            extension: '',
            maxSizeInKb: 1,
            external: viewMode === ViewMode.EXTERNAL,
        });
    };

    const saveConstraint = async constraint => {
        try {
            if (constraint.id) {
                await api.put(`shell/file-constraints/${constraint.id}`, constraint);

                setConstraints(constraints.map(x => (x.id === constraint.id ? constraint : x)));
            } else {
                const { data } = await api.post('shell/file-constraints', constraint);

                setConstraints([...constraints, data]);
                setNewConstraint();
            }
        } catch (e) {
            ngDispatch({
                type: 'HANDLE_EXT_API_ERROR',
                payload: { constraint, status: e.response.status },
            });
        }
    };

    const visibleConstraints = useMemo(() => getVisibleConstraints(constraints, viewMode), [constraints, viewMode]);

    return (
        <div className="row">
            <div className="col-lg-12 extensions-view">
                <ul className="nav nav-tabs" data-testid="extensions-tabs">
                    <li id="tab-extensions" role="presentation" className={activeTab === 'extensions' ? 'active' : ''} onClick={() => setActiveTab('extensions')}>
                        <a>Extensions</a>
                    </li>
                </ul>
            </div>

            {activeTab === 'extensions' && (
                <div className="col-lg-12" data-testid="extensions-view">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <button type="button" className="btn btn-success btn-add-extension" onClick={addConstraint} data-testid="btn-add-extension">Add new extension</button>
                            <select name="" id="viewMode" className="form-control input-sm pull-right" style={{ width: 200 }} value={viewMode} onChange={e => setViewMode(e.target.value)} data-testid="extension-select">
                                <option value={ViewMode.INTERNAL}>Internal</option>
                                <option value={ViewMode.EXTERNAL}>External</option>
                            </select>
                            <div className="clearfix" />
                        </div>

                        <div className="panel-body extensions__list">
                            <div className=" col-xs-12 table-responsive">
                                <table className="table extensions__table table-bordered table-condensed">
                                    <thead>
                                        <tr>
                                            <th className="col-xs-5">Extension</th>
                                            <th className="col-xs-5 text-right">Max Size (KB)</th>
                                            <th className="col-xs-2 text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            visibleConstraints.length === 0 && !newConstraint ? (
                                                <tr>
                                                    <td colSpan="3" className="extension__no-result text-muted">No results found</td>
                                                </tr>
                                            )
                                                : (
                                                    <>
                                                        {visibleConstraints.map(constraint => (
                                                            <ConstraintRow
                                                                key={constraint.id}
                                                                constraint={constraint}
                                                                onDelete={() => deleteConstraint(constraint.id)}
                                                                onCancel={() => setNewConstraint()}
                                                                onSave={saveConstraint}
                                                            />
                                                        ))}
                                                        {newConstraint && (
                                                            <EditConstraint constraint={newConstraint} onCancel={() => setNewConstraint()} onSave={saveConstraint} />
                                                        )}
                                                    </>
                                                )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

ExtensionManager.propTypes = {
    ngDispatch: func.isRequired,
};

export default ExtensionManager;
