import MediaLinkCriteria from './utitilies/media-link-criteria';
import mediaLinksViewTemplate from './media-links.view.html';

class MediaLinkController {

    // @ngInject
    constructor(store, MediaContentTypeModel, TagModel, ModuleModel, $state, $q, ExerciseCategoryModel) {
        this.MediaContentTypeModel = MediaContentTypeModel;
        this.TagModel = TagModel;
        this.store = store;
        this.ModuleModel = ModuleModel;
        this.$state = $state;
        this.$q = $q;
        this.ExerciseCategoryModel = ExerciseCategoryModel;
        this.tabs = {
            MEDIALINKS: 'medialinks',
            IMPORT: 'import',
            EXPORT: 'export',
        };
    }

    $onInit() {
        this.criteria = new MediaLinkCriteria(() => this.onCriteriaChanged());
        this.activeTab = this.tabs.MEDIALINKS;

        this.preferredModule = this.store.get('p-preferred-module');

        if (this.preferredModule) {
            this.getModulePath();
        }
    }

    getModulePath() {
        return this.ModuleModel.$getPath(this.preferredModule)
            .then(path => {
                this.modulePath = path;
                this.criteria.setHierarchy({
                    module: this.modulePath.module.id,
                    method: this.modulePath.method.id,
                });

                return this.ModuleModel.query({ methodId: this.criteria.methodId, orderBy: 'name' });
            })
            .then(modules => {
                this.modulesForCurrentMethod = modules;
            });

    }

    onModulesRetrieved(modules) {
        this.modulesForCurrentMethod = modules;
    }

    onPublishingHouseRetrieved(publishingHouse) {
        this.publishingHouse = publishingHouse;
    }

    getSuperModuleIdFor(moduleId) {
        if (!this.modulesForCurrentMethod) return undefined;

        const module = this.modulesForCurrentMethod.find(mod => mod.id === moduleId);
        if ((module || {}).type === 'SUPER') return moduleId;

        const superModule = this.modulesForCurrentMethod.find(mod => mod.type === 'SUPER' && mod.subModules.includes(moduleId));
        return (superModule || {}).id;
    }

    onCriteriaChanged() {
        this.suggestions = [];
        // prevent medialink loading in medialinks-tab.component untill dependencies loaded
        this.dataLoaded = false;

        if (this.criteria.moduleId) {
            const moduleHasChanged = this.previousModuleId !== this.criteria.moduleId;
            if (moduleHasChanged) this.criteria.superModuleId = this.getSuperModuleIdFor(this.criteria.moduleId);

            return this.$q
                .all([
                    moduleHasChanged ? this.MediaContentTypeModel.query({
                        moduleId: this.criteria.moduleId,
                        orderBy: 'name',
                    }) : this.$q.resolve(),
                    moduleHasChanged ? this.TagModel.query({
                        archived: false,
                        moduleId: this.criteria.moduleId,
                        orderBy: 'name',
                        methodId: this.criteria.methodId,
                    }) : this.$q.resolve(),
                    this.previousMethodId !== this.criteria.methodId ? this.ExerciseCategoryModel.query({
                        method: this.criteria.methodId,
                    }) : this.$q.resolve(this.exerciseCategoryTable),
                ])
                .then(([mediaContentTypes, tags, exerciseCategoryTable]) => {
                    if (mediaContentTypes) this.mediaContentTypes = mediaContentTypes;
                    if (tags) this.tags = tags;
                    if (exerciseCategoryTable) this.exerciseCategoryTable = exerciseCategoryTable;

                    this.previousModuleId = this.criteria.moduleId;
                    this.previousMethodId = this.criteria.methodId;

                    this.setExerciseCategories();

                    this.dataLoaded = true;
                });

        }
    }

    savePreferredFilter(moduleId) {
        this.store.set('p-preferred-module', moduleId);
        this.preferredModule = this.store.get('p-preferred-module');
        return this.getModulePath();
    }

    resetPreferredFilter() {
        this.store.remove('p-preferred-module');
        this.$state.reload();
    }

    setExerciseCategories() {
        this.exerciseCategories = [];

        if (this.exerciseCategoryTable.length > 0) {
            const orderedCategories = this.exerciseCategoryTable
                .filter(category => category.modules.includes(this.previousModuleId))
                .map(category => {
                    if (!category.theme) {
                        category.theme = {
                            id: 'general',
                            name: 'Algemeen',
                            rank: 1000,
                        };
                    }
                    return category;
                })
                .sort((a, b) => a.theme.rank - b.theme.rank);

            this.insertCategoryThemes(orderedCategories);
        }
    }

    insertCategoryThemes(orderedCategories) {
        const existingThemes = [];

        orderedCategories.forEach(category => {
            if (!existingThemes.includes(category.theme.id)) {
                this.exerciseCategories.push({
                    name: category.theme.name,
                    isTheme: true,
                });

                existingThemes.push(category.theme.id);
            }

            this.exerciseCategories.push({
                id: category.id,
                name: category.name,
            });
        });
    }
}

export default {
    template: mediaLinksViewTemplate,
    controller: MediaLinkController,
};
