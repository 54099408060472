import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import './media-content-types.scss';
import mediaContentTypeDetailsTemplate from './media-content-type-details.html';

class MediaContentTypesController {
    // @ngInject
    constructor(
        MediaContentTypeModel,
        ngDialog,
        $scope,
        ModuleModel,
        toaster,
        $q,
        FAQCategoryModel,
        FAQModel,
        targetLanguage,
        FAQTutorialModel
    ) {
        this.MediaContentTypeModel = MediaContentTypeModel;
        this.ngDialog = ngDialog;
        this.$scope = $scope;
        this.ModuleModel = ModuleModel;
        this.toaster = toaster;
        this.$q = $q;
        this.FAQCategoryModel = FAQCategoryModel;
        this.FAQModel = FAQModel;
        this.targetLanguage = targetLanguage;
        this.FAQTutorialModel = FAQTutorialModel;
    }

    $onInit() {
        return this.$q.all([
            this.getMediaContentTypes(),
            this.getFAQCategories(),
        ]);
    }

    getFAQCategories() {
        return this.FAQCategoryModel.query().then(resolvedFAQCategories => {
            this.FAQCategories = resolvedFAQCategories;
        });
    }

    getMediaContentTypes() {
        return this.MediaContentTypeModel.query().then(mediaContentTypes => {
            this.mediaContentTypes = mediaContentTypes;
            return this.buildCategorized(mediaContentTypes);
        });
    }

    buildCategorized() {
        const sorted = sortBy(this.mediaContentTypes, r => [
            (r.category || '~').toLowerCase(),
            (r.name || '').toLowerCase(),
        ]);

        this.categorizedMediaContentTypes = groupBy(sorted, t => (t.category || '').trim().toLowerCase());
    }

    showDetails(mediaContentType) {
        this.selectedMediaContentType = mediaContentType;
        const mediaContentTypePromise = mediaContentType
            ? this.MediaContentTypeModel.get(mediaContentType.id)
            : this.$q.resolve(new this.MediaContentTypeModel());

        this.selectedLanguage = undefined;
        this.selectedFAQCategory = undefined;

        return mediaContentTypePromise
            .then(resolvedMediaContentType => {
                this.currentMediaContentType = resolvedMediaContentType;

                if (this.currentMediaContentType.faqTutorial) {
                    this.selectedLanguage = this.currentMediaContentType.faqTutorial.targetLanguage.toString();
                    this.selectedFAQCategory = this.currentMediaContentType.faqTutorial.category.id;
                    this.currentMediaContentType.faqTutorial = this.currentMediaContentType.faqTutorial.id;
                }

                if (this.selectedMediaContentType) {
                    this.selectedMediaContentType.$update(
                        this.currentMediaContentType
                    );
                    this.selectedMediaContentType.$commit();
                }

                if (this.currentMediaContentType.faqTutorial) {
                    return this.getTutorials();
                }

                return this.$q.resolve();
            })
            .then(() => {
                this.ngDialog.open({
                    template: mediaContentTypeDetailsTemplate,
                    plain: true,
                    scope: this.$scope,
                    className: 'ngdialog-theme-default',
                });
            });
    }

    onLanguageChanged() {
        this.selectedFAQCategory = undefined;
        this.currentMediaContentType.faqTutorial = undefined;

        if (this.selectedFAQCategory) return this.getTutorials();
    }

    onCategoryChanged() {
        this.currentMediaContentType.faqTutorial = undefined;

        return this.getTutorials();
    }

    getTutorials() {
        if (this.selectedFAQCategory === null) return this.$q.resolve();

        return this.FAQTutorialModel.get({
            languageId: this.selectedLanguage,
            categoryId: this.selectedFAQCategory,
        }).then(faqs => {
            this.FAQTutorials = faqs;
        });
    }

    save() {
        return this.currentMediaContentType.$save().then(mediaContentType => {
            if (angular.isUndefined(this.selectedMediaContentType)) {
                this.mediaContentTypes.push(mediaContentType);
            } else {
                this.selectedMediaContentType.$update(mediaContentType);
            }

            this.buildCategorized();

            return this.ngDialog.close();
        });
    }

    delete(mediaContentType) {
        return mediaContentType
            .$destroy()
            .then(() => this.buildCategorized())
            .catch(err => {
                if (err.status === 409) {
                    this.toaster.pop(
                        'warning',
                        'Oops, something went wrong',
                        'The media content type you are trying to delete has related medialinks.'
                    );
                } else {
                    return this.$q.reject(err);
                }
            });
    }

    onFilterChange(filter) {
        this.selectedMethod = filter.method;

        this.availableModules = [];

        if (this.selectedMethod) {
            return this.ModuleModel.query({
                methodId: this.selectedMethod,
                orderBy: 'name',
            }).then(modules => {
                this.availableModules = modules;
            });
        }
    }

    link(mediaContentType, module) {
        return mediaContentType.$link(module).then(value => {
            if (value) {
                mediaContentType.$update(value);
                mediaContentType.$commit();
            }
        });
    }

    unlink(mediaContentType, module) {
        return mediaContentType.$unlink(module).then(value => {
            if (value) {
                mediaContentType.$update(value);
                mediaContentType.$commit();
            }
        });
    }
}

export default {
    template: `<div class="media-content-types-view">
    <div class="panel panel-default">
        <div class="panel-heading">
            <h3 class="panel-title media-content-types__title pull-left">Media Link Content Types</h3>
            <button type="button" class="btn btn-sm btn-success pull-right" ng-click="$ctrl.showDetails()"><span
                class="glyphicon glyphicon-plus"></span> Add Media Link Content Type
            </button>
            <div class="clearfix"></div>
        </div>
        <div class="panel-body">

            <div class="row media-content-types__filter">
                <div class="col-xs-6">
                </div>
                <div class="col-xs-6">
                    <hierarchy-filter
                        levels="3"
                        filter-change="$ctrl.onFilterChange(filter)"
                    </hierarchy-filter>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 table-responsive media-content-types__table-wrapper">
                    <table class="table table-condensed media-content-types__table table-bordered">
                        <thead class="shell-media-content-types__header">
                        <tr>
                            <th colspan="2"></th>
                            <th ng-if="!$ctrl.selectedMethod || ($ctrl.availableModules && $ctrl.availableModules.length === 0)">
                                <span ng-if="!$ctrl.selectedMethod" class="text-muted">Select a method using the above hierarchy filter</span>
                                <span
                                    ng-if="$ctrl.selectedMethod && ($ctrl.availableModules && $ctrl.availableModules.length === 0)"
                                    class="text-muted">Selected method has no modules</span>
                            </th>
                            <th ng-repeat="module in $ctrl.availableModules" class="media-content-types__column">
                                {{ module.name }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr ng-if="!$ctrl.mediaContentTypes || $ctrl.mediaContentTypes.length === 0"
                            class="media-content-types__table-row media-content-types__table-header-row"
                        >
                            <td colspan="{{ ($ctrl.availableModules && $ctrl.availableModules.length > 0) ? $ctrl.availableModules.length + 2 : 3 }}">
                                <span class="glyphicon glyphicon-th-list"></span></td>
                        </tr>
                        <tr ng-if="!$ctrl.mediaContentTypes || $ctrl.mediaContentTypes.length === 0"
                            class="media-content-types__table-row">
                            <td class="col-xs-4 col-sm-3 media-content-types__name-col">
                                <span class="text-muted">No Media Link Content Type found</span>
                            </td>
                            <td class="col-xs-4 col-sm-3 col-md-2 col-lg-1 media-content-types__action-col">&nbsp;</td>
                            <td ng-if="!$ctrl.selectedMethod || ($ctrl.availableModules && $ctrl.availableModules.length === 0)">
                                &nbsp;
                            </td>
                            <td ng-repeat="module in $ctrl.availableModules" class="media-content-types__column">
                                &nbsp;
                            </td>
                        </tr>

                        <tr ng-repeat-start="(key, items) in $ctrl.categorizedMediaContentTypes" ng-if="false"></tr>
                        <tr class="media-content-types__table-row media-content-types__table-header-row">
                            <td colspan="{{ ($ctrl.availableModules && $ctrl.availableModules.length > 0) ? $ctrl.availableModules.length + 2 : 3 }}">
                                <span class="glyphicon glyphicon-th-list"></span> {{ items && items.length > 0 ?
                                items[0].category : '' }}
                            </td>
                        </tr>
                        <tr ng-repeat="mediaContentType in items" class="media-content-types__table-row">
                            <td class="col-xs-4 col-sm-3 media-content-types__name-col">{{ mediaContentType.name }}</td>
                            <td class="col-xs-4 col-sm-3 col-md-2 col-lg-1 media-content-types__action-col">
                                <div class="pull-right">
                                    <button type="button" class="btn btn-default btn-xs" title="Edit"
                                            ng-click="$ctrl.showDetails(mediaContentType)">
                                        <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                                    </button>
                                    <button type="button" class="btn btn-danger btn-xs" title="Delete"
                                            ng-click-confirmed
                                            confirmed="$ctrl.delete(mediaContentType)"
                                            confirmation-title='Delete Media Link Content Type'
                                            confirmation-message="Are you sure you want to delete : '{{ mediaContentType.name }}'?">
                                        <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                                    </button>
                                </div>
                            </td>
                            <td ng-if="!$ctrl.selectedMethod || ($ctrl.availableModules && $ctrl.availableModules.length === 0)"></td>
                            <td ng-repeat="module in $ctrl.availableModules" class="media-content-types__column">
                                <label>
                                <input
                                    checklist-model="mediaContentType.modules"
                                    checklist-value="module.id"
                                    ng-change="mediaContentType.modules && mediaContentType.modules.indexOf(module.id) >= 0 ? $ctrl.unlink(mediaContentType, module) : $ctrl.link(mediaContentType, module)"
                                    type="checkbox">
                            </label>
                            </td>

                        </tr>
                        <tr ng-repeat-end ng-if="false"></tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>`,
    controller: MediaContentTypesController,
};
