import angular from 'angular';
import './word-trainer-manager.scss';
import component from './word-trainer-manager.view';
import ImportWordLists from './components/import-word-lists.view';
import ImportWordListsMedia from './components/import-word-lists-media.view';
import WordManager from './components/word-manager/word-manager.view';

const module = angular.module('app.shell.modules.word-trainer-manager', []);

module
    .config($stateProvider => {
        // @ngInject
        $stateProvider
            .state('word-trainer-manager', {
                url: '/word-trainer-manager',
                title: 'Pakket-p SHELL word trainer manager',
                template: `
                    <shell-word-trainer-manager></shell-word-trainer-manager>
                `,
                security: permissionService => permissionService.canManageWordLists,
            });
    })
    .component('shellWordTrainerManager', component)
    .component('importWordLists', ImportWordLists)
    .component('importWordListsMedia', ImportWordListsMedia)
    .component('wordManager', WordManager);

export default module.name;
