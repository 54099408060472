import findIndex from 'lodash/findIndex';
import without from 'lodash/without';

class TagList {

    // @ngInject
    constructor($scope, ModuleModel) {
        this.$scope = $scope;
        this.ModuleModel = ModuleModel;
        this.loading = true;
    }

    $onChanges(context) {

        if (context.filter) {
            if (context.filter.currentValue && context.filter.currentValue.method) {
                this.getModules(context.filter.currentValue.method);
            }
        }

    }

    getModules(methodId) {
        if (methodId) {
            this.ModuleModel
                .query({ methodId, orderBy: 'name' })
                .then(modules => {
                    this.modules = modules;
                    this.loading = false;
                });
        }
    }

    checkEnabledModules(tag, module) {
        const index = findIndex(tag.enabledModules, moduleId => moduleId === module.id);
        if (index > -1) return true;
        return false;
    }

    onCheckboxClick(tag, module) {
        const index = findIndex(tag.enabledModules, moduleId => moduleId === module.id);
        if (index > -1) {
            tag.enabledModules = without(tag.enabledModules, tag.enabledModules[index]);
        } else {
            tag.enabledModules.push(module.id);
        }

        this.cleanUpEnabledModules(tag);
        tag.$save();
    }

    onArchiveTag(tag) {
        tag.archived = !tag.archived;
        this.cleanUpEnabledModules(tag);
        tag.$save().then(savedTag => {
            const index = findIndex(this.tagList, t => t.id === savedTag.id);
            this.tagList.splice(index, 1);
        });
    }

    cleanUpEnabledModules(tag) {
        tag.enabledModules.forEach((enabledModule, key) => {
            const index = findIndex(this.modules, m => m.id === enabledModule);
            if (index === -1) tag.enabledModules.splice(key, 1);
        });
    }
}

export default {
    template: `
        <div ng-if="!$ctrl.filter.method">
            Please select a method...
        </div>
        <div ng-if="$ctrl.tagList.length === 0 && $ctrl.filter.method">
            No tags were found for this method...
        </div>
        <div ng-if="$ctrl.filter.method" class="row tag-list">
            <div ng-if="!$ctrl.loading && $ctrl.tagList.length > 0" class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="firs-col"></th>
                            <th class="center-text" ng-repeat="module in $ctrl.modules">
                                {{module.name}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr ng-repeat="tag in $ctrl.tagList">
                            <td>
                                <shell-tag-item
                                    archive-tag="$ctrl.onArchiveTag(tag)"
                                    method-id="$ctrl.filter.method"
                                    tag="tag">
                                </shell-tag-item>
                            </td>
                            <td class="center-text" ng-repeat="module in $ctrl.modules">
                                <input
                                    ng-disabled="!tag.id"
                                    ng-click="$ctrl.onCheckboxClick(tag, module)"
                                    ng-checked="$ctrl.checkEnabledModules(tag, module)"
                                    type="checkbox" >
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    `,
    controller: TagList,
    bindings: {
        tagList: '<',
        filter: '<',
    },
};
