class TagItem {

    // @ngInject
    constructor($scope) {
        this.$scope = $scope;
    }

    $onInit() {
        this.editMode = !this.tag.name;
        this.error = !this.tag.name;
    }

    editTag() {
        this.editMode = true;
    }

    saveTag() {
        if (!this.error) {
            this.tag.$save()
                .then(() => {
                    this.editMode = false;
                });
        }
    }

    checkValid() {
        if (this.tag.name) {
            this.error = false;
        } else {
            this.error = true;
        }
    }
}

export default {
    template: `
        <div class="tag-item"
            ng-class="{ 'has-error': $ctrl.error }">
            <div class="pull-left tag-block">
                <p ng-if="!$ctrl.editMode">{{ $ctrl.tag.name }}</p>
                <input  class="tag-input form-control"
                        ng-change="$ctrl.checkValid()"
                        ng-if="$ctrl.editMode"
                        placeholder="Please fill in a name..."
                        type="text"
                        ng-model="$ctrl.tag.name">
            </div>

            <div class="pull-right icon-block">
                <a ng-if="!$ctrl.editMode" title="Edit" ng-click="$ctrl.editTag()">
                    <span class="pull-left glyphicon glyphicon-pencil"></span>
                </a>
                <a ng-if="!$ctrl.editMode" title="(Un)archive" ng-click="$ctrl.archiveTag({ tag: $ctrl.tag })">
                    <span class="pull-right glyphicon glyphicon-inbox"></span>
                </a>
                <a type="submit" ng-if="$ctrl.editMode" ng-click="$ctrl.saveTag()">
                    <span class="pull-right glyphicon glyphicon-floppy-disk"></span>
                </a>
            </div>
        </div>
    `,
    controller: TagItem,
    bindings: {
        tag: '=',
        archiveTag: '&',
        methodId: '<',
        formName: '<',
    },
};
