import each from 'lodash/each';
import moment from 'moment';

class DatePickerController {

    // @ngInject
    constructor($scope) {
        this.$scope = $scope;
        this.opened = false;
        if (this.ngModel !== undefined) {
            this.ngModel = moment(this.ngModel);
        }
    }

    $onInit() {
        this.minView = angular.isUndefined(this.minView) ? 'day' : this.minView;
    }

    openDatePicker() {
        this.opened = true;
    }

    closeDatePicker() {
        this.opened = false;
    }

    beforeRender($view, $dates) {
        let local;
        let future;

        each($dates, (date) => {
            local = date.localDateValue();

            switch ($view) {
                case 'day':
                    future = moment(local).isSameOrAfter(moment(), 'days');
                    break;
                case 'hour':
                    future = moment(local).isSameOrAfter(moment(), 'hours');
                    break;
                case 'minute':
                    future = moment(local).isAfter(moment(), 'minutes');
                    date.display = moment(local).format('hh:mm A');
                    break;
                default:
                    break;
            }

            if (!future) {
                date.selectable = false;
            }
        });
    }

    toggleDatePicker() {
        this.opened = !this.opened;
    }

    onTimeSet(newDate) {
        if (this.onDateSelected && this.onDateSelected.name !== 'noop') {
            this.onDateSelected({ newDate });
        }
        this.closeDatePicker();
    }
}

export default {
    template: `<div class="date-picker-component">
    <div class="dropdown" ng-class="{'open': $ctrl.opened }">
        <div class="input-group date-picker__choose-date" ng-click="$ctrl.toggleDatePicker()">

            <input type="text"
                   class="form-control"
                   name="{{ $ctrl.name }}"
                   id="{{$ctrl.ngId}}"
                   data-date-time-input="DD-MM-YYYY"
                   ng-model="$ctrl.ngModel"
                   ng-required="$ctrl.required"
                   ng-change="$ctrl.closeDatePicker()"
            />

            <span class="input-group-addon">
                <i class="glyphicon glyphicon-calendar"></i>
            </span>
        </div>

        <ul class="dropdown-menu" role="menu" aria-labelledby="dLabel">
            <datetimepicker
                data-datetimepicker-config="{ minView: $ctrl.minView }"
                class="date-time-picker"
                data-ng-model="$ctrl.ngModel"
                data-before-render="$ctrl.beforeRender($view, $dates)"
                data-on-set-time="$ctrl.onTimeSet(newDate)"
            />
        </ul>
    </div>
</div>`,
    controller: DatePickerController,
    bindings: {
        ngModel: '=',
        onDateSelected: '&?',
        name: '@',
        required: '<?',
        ngId: '@?',
        minView: '<?',
    },
};
