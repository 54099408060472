function MaintenanceError() {
    this.name = 'MaintenanceError';
    this.status = 503;
    this.message = 'Service Unavailable';
}

MaintenanceError.prototype = Object.create(Error.prototype);
MaintenanceError.prototype.constructor = MaintenanceError;

export default MaintenanceError;
