/* @ngInject */
export default function LicenseProductModelFactory($modelFactory) {
    return $modelFactory('shell/licenseproducts', {
        actions: {
            query: {
                afterRequest: response => response.data,
            },
        },
    });
}
