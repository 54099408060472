import miniDialogSetProductionalStateTemplate from './modals/mini-dialog-set-productional-state.html';
import miniDialogSettingsTemplate from './modals/mini-dialog-settings.html';
import miniDialogManagerTemplate from './mini-dialog-manager.view.html';

class MiniDialogManagerCtrl {
    // @ngInject
    constructor(MiniDialogModel, $q, ngDialog, $scope, FileUploader, fileUploadValidationService, toaster, store, $window, $http, config) {
        this.$scope = $scope;
        this.$q = $q;
        this.MiniDialogModel = MiniDialogModel;
        this.ngDialog = ngDialog;
        this.FileUploader = FileUploader;
        this.fileProgress = 0;
        this.fileUploadValidationService = fileUploadValidationService;
        this.toaster = toaster;
        this.store = store;
        this.$window = $window;
        this.$http = $http;
        this.config = config;
        this.onModuleSelected = this.onModuleSelected.bind(this);
    }

    $onInit() {
        this.filteredMiniDialogs = [];
        this.createMode = false;
        return this.fileUploadValidationService.initialize();
    }

    setSignedUrl(fileId) {
        return this.$http
            .get(`/api/s3-files/${fileId}/signedUrl?extendedTimeValidity=true`) // 12 hours
            .then(res => {
                const signedUrl = res.headers('location');
                return signedUrl;
            });
    }

    get selectedMiniDialogs() {
        return this.filteredMiniDialogs.filter(m => m.checked);
    }

    initializePlayer(line) {
        return this
            .setSignedUrl(line.audioFile)
            .then(signedUrl => {
                const options = {
                    playlist: [{ file: signedUrl }],
                    autostart: false,
                    width: '200px',
                    height: '30px',
                };

                this.$window.jwplayer(`jwplayer-${line.audioFile}`).setup(options);
            });
    }

    initializeUploader(line) {
        this.audioUploader = new this.FileUploader({
            autoUpload: true,
            removeAfterUpload: false,
            url: `${this.config.baseUrl}/s3-files`,
            onBeforeUploadItem: item => {
                const token = this.store.get('p-access_token');

                if (token) {
                    item.headers = {
                        Authorization: `Bearer ${token}`,
                    };
                }
                item.formData = [{
                    moduleId: this.filter.module,
                }];
                item.context = line;
            },
            onCompleteItem: (item, response, status, headers) => {
                if (item.isSuccess && status === 201) {
                    const fileId = headers.location.split('/')[headers.location.split('/').length - 1];
                    item.context.audioFile = fileId;
                    this.fileProgress = 0;
                }
            },
            onProgressItem: (item, progress) => {
                this.fileProgress = progress;
            },
            onWhenAddingFileFailed: (item, filter) => {
                if (filter.name === 'extensionAndSize') {
                    const errorMessage = this.fileUploadValidationService.validate(item.name, item.size);

                    this.toaster.pop('warning', errorMessage);
                }
            },
        });

        // file extension and size filter
        this.audioUploader.filters.push({
            name: 'extensionAndSize',
            fn: item => this.fileUploadValidationService.isValid(item.name, item.size),
        });
    }

    onModuleSelected(moduleId) {
        this.destinationModule = moduleId;
        this.destinationNode = 'general';
        this.$scope.$apply();
    }

    getSuperModuleIdFor(moduleId) {
        if (!this.modules) return undefined;

        const module = this.modules.find(mod => mod.id === moduleId);
        if ((module || {}).type === 'SUPER') return moduleId;

        const superModule = this.modules.find(mod => mod.type === 'SUPER' && mod.subModules.includes(moduleId));
        return (superModule || {}).id;
    }

    onModulesRetrieved(modules) {
        this.modules = modules;
    }

    copySelected() {
        const newModule = this.destinationModule;
        const newNode = (this.destinationNode && this.destinationNode.id) || 'general';
        const sourceModule = this.filter.module;

        if (!newModule) return Promise.resolve();

        return this.$http.post(`/api/shell/modules/${sourceModule}/mini-dialogs/copy`, {
            targetModuleId: newModule,
            targetNodeId: newNode,
            dialogIds: this.selectedMiniDialogs.map(md => md.id),
        }).then(() => {
            this.filteredMiniDialogs.forEach(md => {
                md.checked = false;
            });
            this.allSelected = false;

            if (sourceModule === newModule && this.node.id === newNode) {
                return this.getMiniDialogsForNode(newNode);
            }
        });

    }

    // module filter
    onFilterChange(filter) {
        this.destinationModule = filter.module;
        // initial loading
        this.filter = { ...filter, superModule: this.getSuperModuleIdFor(filter.module) };

        this.nodeTree = [];
    }

    // level filter
    onLevelFilterLoaded(rootNodes, selectedNode) {

        // initial loading
        if (!selectedNode) {
            this.rootNode = {
                id: 'general',
                level: 'general',
                path: 'Algemeen',
                nodes: rootNodes,
            };
            selectedNode = this.rootNode;
        }
        this.setNode(selectedNode);

        // build a full tree
        this.nodeTree = [this.rootNode.id];
        this.buildNodeTree(this.nodeTree, this.rootNode);
    }

    setNode(node) {
        this.allSelected = false;
        this.node = node;
        return this.getMiniDialogsForNode(node.id);
    }

    enrichMiniDialog(md) {
        if (md.nodeId === 'general') md.nodePath = 'Algemeen';
        md.nodeOrder = this.nodeTree.indexOf(md.nodeId);
        md.$commit();
    }

    getMiniDialog(miniDialog) {
        return this.MiniDialogModel
            .get({ id: miniDialog.id, moduleId: this.filter.module, nodeId: miniDialog.nodeId });
    }

    getMiniDialogsForNode(nodeId) {
        return this.MiniDialogModel
            .$getList(this.filter.module, nodeId)
            .then(miniDialogs => {
                this.filteredMiniDialogs = miniDialogs;
                this.filteredMiniDialogs.forEach(md => this.enrichMiniDialog(md));
                this.filteredMiniDialogs = this.filteredMiniDialogs.sort(this.byNodeOrderByName);
            });
    }

    buildNodeTree(nodeTree, node) {
        if (node.nodes && node.nodes.length > 0) {
            node.nodes.forEach(x => {
                nodeTree.push(x.id);
                this.buildNodeTree(nodeTree, x);
            });
        }
    }

    byNodeOrderByName(md1, md2) {
        if (md1.nodeOrder > md2.nodeOrder) {
            return 1;
        } else if (md1.nodeOrder < md2.nodeOrder) {
            return -1;
        }

        const name1 = md1.name.toLowerCase();
        const name2 = md2.name.toLowerCase();
        if (name1 < name2) {
            return -1;
        } else if (name1 > name2) {
            return 1;
        }

        // nothing to split them
        return 0;
    }

    openUpdateProductionalStateModal() {
        this.selectedProductionalState = 0;

        return this.ngDialog.open({
            template: miniDialogSetProductionalStateTemplate,
            plain: true,
            scope: this.$scope,
            className: 'ngdialog-theme-default ng-dialog__xs ngdialog-patch-productional-state',
        });
    }

    saveProductionalState() {
        const promises = [];

        this.selectedMiniDialogs.forEach(miniDialog => {
            promises.push(this.doUpdateProductionalState(miniDialog, this.selectedProductionalState));
        });

        return Promise.all(promises)
            .then(() => {
                this.ngDialog.close();
            })
            .catch(err => this.toaster.pop('error', err.data.message));
    }

    doUpdateProductionalState(miniDialog, productionalState) {
        this.allSelected = false;

        return miniDialog
            .$patchProductionalState(productionalState)
            .then(md => {
                this.enrichMiniDialog(md);

                const foundMiniDialog = this.filteredMiniDialogs.find(res => res.id === md.id);

                foundMiniDialog.$update(md);
                foundMiniDialog.$commit();
            })
            .catch(err => this.toaster.pop('error', err.data.message));
    }

    showAddMiniDialogModal() {
        this.activeTab = 'settings';
        this.createMode = true;

        // set defaults
        this.currentMiniDialog = new this.MiniDialogModel({
            moduleId: this.filter.module,
            nodeId: this.node.id,
            productionalState: 0,
            nodePath: this.node.path,
        });
        this.selectedActorIndex = 0;

        return this.ngDialog.open({
            template: miniDialogSettingsTemplate,
            plain: true,
            scope: this.$scope,
            className: 'ngdialog-theme-default ngdialog-mini-dialog',
        });
    }

    showEditMiniDialogModal(miniDialogId) {
        this.activeTab = 'settings';
        this.createMode = false;

        // get the info
        return this
            .getMiniDialog(miniDialogId)
            .then(md => {
                this.currentMiniDialog = md;
                this.selectedActorIndex = 0;

                return this.ngDialog.open({
                    template: miniDialogSettingsTemplate,
                    plain: true,
                    scope: this.$scope,
                    className: 'ngdialog-theme-default ngdialog-mini-dialog',
                });
            });
    }

    closeDialog() {
        return this.ngDialog.close();
    }

    setMiniDialogDefaults() {
        if (!this.currentMiniDialog.actors) {

            this.currentMiniDialog.actors = [
                {
                    actorId: 'ACTOR1',
                    role: 'A',
                }, {
                    actorId: 'ACTOR2',
                    role: 'B',
                }, {
                    actorId: 'ACTOR3',
                    role: 'C',
                }, {
                    actorId: 'ACTOR4',
                    role: 'D',
                }, {
                    actorId: 'ACTOR5',
                    role: 'E',
                }, {
                    actorId: 'ACTOR6',
                    role: 'F',
                },
            ];
        }

        if (!this.currentMiniDialog.lines) {

            this.currentMiniDialog.lines = [{
                actorId: 'ACTOR1',
            }];
        }
    }

    showLinesTab() {
        if (this.createMode) this.setMiniDialogDefaults();
        this.activeTab = 'lines';
    }

    saveMiniDialog() {
        return this.currentMiniDialog
            .$save()
            .then(miniDialog => {

                this.enrichMiniDialog(miniDialog);

                if (this.createMode) {

                    this.currentMiniDialog.$update(miniDialog);
                    this.currentMiniDialog.$commit();

                    if (this.activeTab === 'settings') {
                        this.showLinesTab();
                        return; // do not close modal
                    }

                    // on lines tab: insert
                    this.filteredMiniDialogs.push(this.currentMiniDialog);
                } else {
                    // update
                    const foundMiniDialog = this.filteredMiniDialogs.find(md => md.id === miniDialog.id);
                    foundMiniDialog.$update(miniDialog);
                    foundMiniDialog.$commit();
                }

                // name could be changed => re-sort node (TODO: only sort when name is actually changed)
                this.filteredMiniDialogs = this.filteredMiniDialogs.sort(this.byNodeOrderByName);

                return this.ngDialog.close();
            })
            .catch(err => {
                this.$q.reject(err);
            });
    }

    cloneDialog(dialog) {
        return dialog
            .$clone()
            .then(clonedDialog => {
                this.enrichMiniDialog(clonedDialog);
                this.filteredMiniDialogs.push(clonedDialog);
                this.filteredMiniDialogs = this.filteredMiniDialogs.sort(this.byNodeOrderByName);
            });
    }

    deleteDialog(miniDialog) {
        return miniDialog
            .$destroy()
            .catch(err => {
                if (err.status === 409) this.toaster.pop('error', err.data.message);
                else return this.$q.reject(err);
            });
    }

    toggleSelectAllMiniDialogs() {
        if (this.filteredMiniDialogs) {
            this.filteredMiniDialogs.forEach(md => {
                md.checked = this.allSelected;
            });
        }
    }

    moveSelected() {
        const miniDialogsToBeMoved = this.selectedMiniDialogs;
        const destinationNodeId = (this.destinationNode || {}).id || 'general';

        const promises = [];

        miniDialogsToBeMoved.forEach(md => {
            promises.push(md.$move(destinationNodeId));
        });

        return this.$q
            .all(promises)
            .then(() => {
                this.allSelected = false;

                // refetch dialogs for module, init on selected level node
                this.onLevelFilterLoaded(this.rootNode.nodes, this.node);
            })
            .catch(err => {
                this.$q.reject(err);
            });
    }

    addDialogLine() {
        this.currentMiniDialog.lines.push({ actorId: this.currentMiniDialog.actors[0].actorId });
    }

    deleteDialogLine(line) {
        line.remove();
    }

}

export default {
    template: miniDialogManagerTemplate,
    controller: MiniDialogManagerCtrl,
};
