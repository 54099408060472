class ExtensionsContainerController {
    // @ngInject
    constructor($rootScope, $scope, toaster) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.toaster = toaster;
    }

    $onInit() {
        this.removeToastListener = this.$scope.$on('HANDLE_EXT_API_ERROR', (_, err) => {
            switch (err.status) {
                case 404:
                    this.toaster.pop('warning', `The extension '${err.constraint.extension}' was removed`);
                    break;
                case 409:
                    this.toaster.pop('error', `The extension '${err.constraint.extension}' already exists`);
                    break;
                default:
                    this.$rootScope.$broadcast('unhandledHttpError', err);
            }
        });
    }

    $onDestroy() {
        this.removeToastListener();
    }
}

export default {
    controller: ExtensionsContainerController,
    template: '<shell-extensions></shell-extensions>',
};
