/* @ngInject */
export default function TableOfContentModelFactory($modelFactory) {
    const model = $modelFactory('shell/modules/{module}/table-of-content', {
        actions: {
            $store: {
                method: 'PUT',
                beforeRequest: (req) => {
                    delete req.data.module;
                },
            },
        },

        $queryNodes: function (moduleId) { // eslint-disable-line object-shorthand
            return model.$call({
                method: 'GET',
                url: `/api/shell/modules/${moduleId}/table-of-content/nodes`,
                afterRequest: (response) => {
                    return response.data;
                },
            });
        },
    });
    return model;
}
