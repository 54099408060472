import React from 'react';
import ReactDOM from 'react-dom';

function buildDispatcher($rootScope) {
    return function angularDispatcher({ type, payload }) {
        /**
         * Because we broadcast this event from outside angular.js
         * we need to call digest!
         *
         * https://docs.angularjs.org/api/ng/type/$rootScope.Scope#$evalAsync
         * to defer $apply() invocation because it may or may not conflict with an already-running $digest phase.
         */
        $rootScope.$evalAsync(() => $rootScope.$broadcast(type, payload));
    };
}

function buildI18n($translate) {
    /**
     *  @param {String} translationId   The translation id
     *  @param {any} interpolationParams Values to use while interpolating
     *  @returns {String}
     */
    function t(translationId, interpolationParams = {}) {
        return $translate.instant(translationId, interpolationParams);
    }

    const lang = $translate.proposedLanguage() || $translate.use();

    return { t, lang };
}

/**
 * Register a react component
 * @param {React.ComponentType} component The react component
 * @param {[string]} bindingNames The names of bindings to pass as props to the component
 * @param {[string]} dependencyNames The dependencies to inject and pass as props to the component
 */
export default function reactComponent(component, bindingNames = [], dependencyNames = []) {
    const bindings = bindingNames.reduce((map, currentValue) => {
        map[currentValue] = '<';
        return map;
    }, {});

    return {
        bindings,
        controller: ['$element', '$rootScope', '$translate', ...dependencyNames, class {
            constructor($element, $rootScope, $translate, ...rest) {
                this.$element = $element;

                this.injectedDependencies = dependencyNames.reduce((map, injectName, idx) => {
                    map[injectName] = rest[idx];
                    return map;
                }, {});

                this.$translate = $translate;
                this.$rootScope = $rootScope;

                this.props = {
                    i18n: buildI18n($translate),
                    ngDispatch: buildDispatcher($rootScope),
                };
            }

            $onInit() {
                this.unsubscribeTranslateChangeSuccess = this.$rootScope.$on('$translateChangeSuccess', () => {
                    this.$onChanges({ i18n: { currentValue: buildI18n(this.$translate) } });
                });
            }

            $onChanges(changes) {
                if (this.isDestroyed) return;

                const newProps = Object
                    .keys(changes)
                    .filter(k => k === 'i18n' || bindingNames.includes(k))
                    .reduce((map, propName) => {
                        map[propName] = changes[propName].currentValue;
                        return map;
                    }, {});

                this.props = {
                    ...this.props,
                    ...newProps,
                };

                const componentToRender = React.createElement(component, { ...this.props, ...this.injectedDependencies });
                ReactDOM.render(componentToRender, this.$element[0]);
            }

            $onDestroy() {
                this.isDestroyed = true;
                this.unsubscribeTranslateChangeSuccess();
                ReactDOM.unmountComponentAtNode(this.$element[0]);
            }
        }],
    };
}
