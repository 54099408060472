export default class FileUploadValidationService {

    // @ngInject
    constructor(FileConstraintModel, $q) {
        this.FileConstraintModel = FileConstraintModel;
        this.$q = $q;
    }

    initialize() {
        const getConstraints = this.availableConstraints
            ? this.$q.resolve(this.availableConstraints)
            : this.FileConstraintModel.query();

        return getConstraints
            .then(constraints => {
                this.availableConstraints = constraints;
            });
    }

    isValid(fileName, fileSize) {
        return !this.validate(fileName, fileSize);
    }

    hasValidExtension(fileName, extension) {
        const fileNameParts = fileName.split('.');
        if (fileNameParts.length < 2) return false;
        return extension === [...fileNameParts].pop().toLowerCase();
    }

    validate(fileName, fileSize) {
        const matchingConstraint = this.availableConstraints.getConstraintByFileName(fileName);

        if (!matchingConstraint) return 'The file is not allowed. It has an extension we don\'t accept.';
        if (!matchingConstraint.isSatisfiedBy(fileSize)) return `The maximum file size for a file with extension ".${matchingConstraint.extension}" is "${matchingConstraint.maxSizeInKb}KB".`;

        return undefined;
    }
}
