import angular from 'angular';
import component from './media-content-types.view';
import svgImageSelector from './components/svg-image-selector-component';

const module = angular.module('app.shell.modules.media-content-types', []);

module
    .config(($stateProvider) => {
        // @ngInject
        $stateProvider.state('media-content-types', {
            url: '/media-content-types',
            template: `
                <shell-media-content-types></shell-media-content-types>
            `,
            security: (permissionService) => permissionService.canManageMediaContentTypes,
        });
    })
    .component('shellMediaContentTypes', component)
    .component('svgImageSelector', svgImageSelector);

export default module.name;
