import { useState, useEffect, useMemo } from 'react';
import {
    getMethods, getModules, getSubjects, Method, Module, Subject,
} from '../../../../api/HierarchyApi';

type UseMedialinksHierarchyReturnType = {
    subjects: Subject[];
    methods: Method[];
    superModules: Module[];
    modules: Module[];
    formValues: {
        targetSubjectId: string;
        targetMethodId: string;
        targetSuperModuleId: string;
        targetModuleId: string;
    };
    handleChange: (name: string, value: string) => void;
};

export default function useMedialinksHierarchy(publishingHouseId: string, subjectId: string, methodId: string, superModuleId: string, moduleId: string, onModuleSelected: (moduleId: string) => void): UseMedialinksHierarchyReturnType {
    const [subjects, setSubjects] = useState<Subject[]>([]);
    const [methods, setMethods] = useState<Method[]>([]);
    const [modules, setModules] = useState<Module[]>([]);

    const [formValues, setFormValues] = useState({
        targetSubjectId: subjectId,
        targetMethodId: methodId,
        targetSuperModuleId: superModuleId,
        targetModuleId: moduleId,
    });

    useEffect(() => {
        getSubjects(publishingHouseId).then(data => {
            setSubjects(data);
        });
    }, [publishingHouseId]);

    useEffect(() => {
        if (formValues.targetSubjectId) {
            getMethods(formValues.targetSubjectId).then(data => {
                setMethods(data);
                setFormValues(values => ({
                    ...values,
                    targetMethodId: data
                        .map(x => x.id)
                        .includes(methodId)
                        ? methodId
                        : data[0].id,
                }));
            });
        }
    }, [formValues.targetSubjectId, methodId]);

    useEffect(() => {
        if (formValues.targetMethodId) {
            getModules(formValues.targetMethodId).then(data => {
                setModules(data);
                setFormValues(values => ({ ...values }));
            });
        }
    }, [formValues.targetMethodId]);

    const superModules = useMemo(() => modules.filter(x => x.type !== 'SUB'), [modules]);
    const subModules = useMemo(() => modules.find(m => m.id === formValues.targetSuperModuleId)?.subModules?.map(sId => modules.find(m => m.id === sId)), [formValues.targetSuperModuleId, modules]);

    const modulesToShow = useMemo(() => [
        superModules.find(m => m.id === formValues.targetSuperModuleId),
        ...(subModules || []),
    ].filter(Boolean) as Module[], [formValues.targetSuperModuleId, subModules, superModules]);

    const handleChange = (name: string, value: string) => {
        if (name === 'targetSubjectId') {
            setFormValues(values => ({
                ...values,
                targetSubjectId: value,
                targetMethodId: '',
                targetSuperModuleId: '',
                targetModuleId: '',
            }));

            onModuleSelected('');
        }

        if (name === 'targetMethodId') {
            setFormValues(values => ({
                ...values,
                targetMethodId: value,
                targetSuperModuleId: '',
                targetModuleId: '',
            }));

            onModuleSelected('');
        }

        if (name === 'targetSuperModuleId') {
            setFormValues(values => ({
                ...values,
                targetSuperModuleId: value,
                targetModuleId: '',
            }));

            onModuleSelected('');
        }

        if (name === 'targetModuleId') {
            setFormValues(values => ({
                ...values,
                targetModuleId: value,
            }));

            onModuleSelected(value);
        }
    };

    return {
        subjects,
        methods,
        superModules,
        modules: modulesToShow,
        formValues,
        handleChange,
    };
}
