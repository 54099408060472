import './word-manager.scss';
import wordDetailTemplate from './modals/word-detail.html';
import wordManagerViewTemplate from './word-manager.view.html';

class WordManager {
    // @ngInject
    constructor($scope, WordModel, ngDialog, $q, toaster) {
        this.$scope = $scope;
        this.WordModel = WordModel;
        this.ngDialog = ngDialog;
        this.$q = $q;
        this.toaster = toaster;

        this.buttons = {
            BOLD: 'bold',
            ITALIC: 'italic',
            UNDERLINE: 'underline',
        };

        this.buttonLayout = [this.buttons.BOLD, this.buttons.ITALIC, this.buttons.UNDERLINE];

        this.tableOptions = {
            beforeDrop: node => {
                const words = angular.copy(this.wordList.words);
                const destIsHigherThanSource = node.dest.index > node.source.index;
                const droppedOnIndex = words[node.dest.index];

                const rank = {
                    before: destIsHigherThanSource ? words[node.dest.index + 1] : droppedOnIndex,
                    after: destIsHigherThanSource ? droppedOnIndex : words[node.dest.index - 1],
                };

                if (node.dest.index === 0) {
                    delete rank.after;
                }

                if (node.dest.index === words.length - 1) {
                    delete rank.before;
                }

                const foundWord = this.wordList.words[node.source.index];
                const word = new this.WordModel({
                    id: foundWord.id,
                    moduleId: foundWord.moduleId,
                    nodeId: foundWord.nodeId,
                    wordListId: this.wordList.id,
                });

                word.wordListId = this.wordList.id;

                return word.$rank(rank.before ? rank.before.id : undefined, rank.after ? rank.after.id : undefined)
                    .catch(err => {
                        if (err.status === 404) {
                            this.toaster.pop('warning', 'Oops, something went wrong', 'Someone removed the word you are trying to drag');
                            return this.$q.resolve(false);
                        }

                        if (err.status === 409) {
                            this.toaster.pop('warning', 'Oops, something went wrong', 'Invalid state detected, try refreshing your page');
                            return this.$q.resolve(false);
                        }
                        return this.$q.reject(err);
                    });
            },
        };
    }

    getNumberOfCheckedWords() {
        return this.wordList.words.filter(word => word.checked === true).length;
    }

    stripHTML(string) {
        if (string) return string.replace(/<(?:.|\n)*?>/gm, '');
    }

    // Word specific content
    addWord() {
        this.currentWord = new this.WordModel({
            moduleId: this.wordList.moduleId,
            nodeId: this.wordList.nodeId,
            wordListId: this.wordList.id,
        });

        return this.openDialog();
    }

    editWord(word) {
        return this
            .getWord(word)
            .then(w => {
                w.wordListId = this.wordList.id;
                this.currentWord = w;

                return this.openDialog();
            });
    }

    deleteWord(word) {
        return this.WordModel
            .get({
                id: word.id,
                moduleId: this.wordList.moduleId,
                nodeId: word.nodeId,
                wordListId: this.wordList.id,
            })
            .then(w => {
                w.wordListId = this.wordList.id;
                return w.$destroy();
            })
            .then(() => {
                this.wordList.words = this.wordList.words.filter(w => word.id !== w.id);
                this.wordList.$update(this.wordList);
                this.wordList.$commit();
            });
    }

    deleteSelectedWords() {
        const selectedWords = this.wordList.words.filter(word => word.checked);

        return selectedWords.reduce((chain, word) => {
            return chain
                .then(() => {
                    return this.deleteWord(word);
                });
        }, this.$q.resolve())
            .then(() => {
                this.wordList.words = this.wordList.words.filter(word => !selectedWords.find(w => w.id === word.id));
                this.allSelectedWords = false;
                this.wordList.$update(this.wordList);
                this.wordList.$commit();
            });
    }

    getWord(word) {
        return this.WordModel
            .get({
                id: word.id,
                moduleId: this.wordList.moduleId,
                nodeId: word.nodeId,
                wordListId: this.wordList.id,
            });
    }

    saveWord() {
        return this.currentWord
            .$save()
            .then(word => {
                if (!this.wordList.words.find(w => w.id === word.id)) {
                    this.wordList.words.push(word);

                    this.currentWord.$update(word);
                    this.currentWord.$commit();
                } else {
                    this.wordList.words = this.wordList.words.map(w => (w.id === word.id ? word : w));

                    this.wordList.$update(this.wordList);
                    this.wordList.$commit();
                }

                return this.ngDialog.close();
            });
    }

    openDialog() {
        return this.ngDialog.open({
            template: wordDetailTemplate,
            plain: true,
            scope: this.$scope,
            className: 'ngdialog-theme-default ng-dialog__xs ngdialog-word-detail',
        });
    }

    closeDialog() {
        return this.ngDialog.close();
    }

    toggleSelectAllWords() {
        if (this.wordList.words) {
            this.wordList.words.forEach(word => {
                word.checked = this.allSelectedWords;
            });
        }
    }

}

export default {
    template: wordManagerViewTemplate,
    controller: WordManager,
    bindings: {
        wordList: '=',
    },
};
