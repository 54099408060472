class SubModulesSelectorController {

    get isTypeSuper() {
        return this.module.type === this.moduleType.SUPER;
    }


    // @ngInject
    constructor($scope, moduleType) {
        this.moduleType = moduleType;
    }

    toggleSelection(id) {
        if (this.linkedToOtherModule(id)) return this.removeLinkedModule(id);

        return this.addLinkedModule(id);
    }

    linkedToOtherModule(id) {
        return this.getLinkedModules(this.module).find(linkedModuleId => linkedModuleId === id);
    }

    getLinkedModules(module) {
        if (module.type === this.moduleType.SUPER) return module.subModules;

        return module.superModules;
    }

    addLinkedModule(id) {
        if (this.isTypeSuper) return this.module.subModules.push(id);

        return this.module.superModules.push(id);
    }

    removeLinkedModule(id) {
        if (this.isTypeSuper) {
            this.module.subModules = this.module.subModules.filter(linkedSubModuleId => id !== linkedSubModuleId);
        } else {
            this.module.superModules = this.module.superModules.filter(linkedSuperModuleId => id !== linkedSuperModuleId);
        }
    }

}

export default {
    template: `
        <div class="row">
            <div class="col-sm-12">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">My {{$ctrl.isTypeSuper ? 'Sub' : 'Super'}}</th>
                            <th scope="col">{{$ctrl.isTypeSuper ? 'Sub' : 'Super'}} Module</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr ng-repeat="module in $ctrl.availableModules">
                            <td class="p-sub-modules-selector__select">
                                <input
                                    type="checkbox"
                                    value="{{module.id}}"
                                    ng-checked="$ctrl.linkedToOtherModule(module.id)"
                                    ng-click="$ctrl.toggleSelection(module.id)"
                                />
                            </td>
                            <td class="qs-selection-table-module-name">{{module.name}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    `,
    controller: SubModulesSelectorController,
    bindings: {
        module: '<',
        availableModules: '<',
    },
};
