/* @ngInject */
export default function MethodModelFactory($modelFactory) {
    return $modelFactory('shell/methods', {
        actions: {
            query: {
                afterRequest: (response) => {
                    // map inner methods array
                    return response.data;
                },
            },
            get: {
                afterRequest: (response) => {
                    // map inner methods array
                    return response.data[0];
                },
            },
        },
    });
}
