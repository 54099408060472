/* @ngInject */
export default function Digibook($modelFactory) {
    const model = $modelFactory('shell/digibooks', {
        actions: {
            query: {
                afterRequest: response => response.data,
            },
        },
    });
    return model;
}
