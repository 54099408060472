/* eslint-disable react/jsx-no-bind */
import { func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import api from '../../../services/api';
import InlineTextFieldEditor from './InlineTextFieldEditor';

function LicenseCodeSettings({
    ngDispatch,
    moduleId,
}) {
    const [licenseCodeSettings, setLicenseCodeSettings] = useState();

    useEffect(() => {
        setLicenseCodeSettings();

        if (moduleId) {

            const getLicenseCodeSettings = async () => {
                try {
                    const { data } = await api.get(`/shell/modules/${moduleId}/license-settings`);

                    setLicenseCodeSettings(data);
                } catch (err) {
                    ngDispatch({ type: 'unhandledHttpError', payload: err });
                }
            };

            getLicenseCodeSettings();
        }
    }, [ngDispatch, moduleId]);

    async function saveCodeSetting(code, done) {
        try {
            const data = {
                lwb: code,
            };

            await api.patch(`/shell/modules/${moduleId}/license-settings`, data);

            setLicenseCodeSettings(data);

            done();
        } catch (err) {
            ngDispatch({ type: 'unhandledHttpError', payload: err });
        }
    }

    return (
        <div className="panel panel-default" data-testid="panel-license-code-setting-ids">
            <div className="panel-body">
                <div className="col-sm-8">
                    <h3>License Code Setting ID&apos;s</h3>
                    <div data-testid="code-settings">
                        {moduleId && (
                            <>
                                <div className="col-md-6">License Code Settings ID for (leer)werkboek</div>
                                {licenseCodeSettings && (
                                    <InlineTextFieldEditor initialValue={licenseCodeSettings.lwb} save={saveCodeSetting} />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

LicenseCodeSettings.propTypes = {
    moduleId: string,

    ngDispatch: func.isRequired,
};

LicenseCodeSettings.defaultProps = {
    moduleId: undefined,
};

export default LicenseCodeSettings;
