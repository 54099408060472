// @ngInject
export default (tokenAccessorFactory, $document, $rootScope, $window) => {
    return {
        restrict: 'A',
        link: ($scope, element, attrs) => {
            $scope.getHref = href => {
                return tokenAccessorFactory.tokenGetter()
                    .then(accessToken => {
                        if (!accessToken) {
                            $rootScope.$broadcast('unauthenticated', {});
                            return undefined;
                        }

                        return href.includes('?') ? `${href}&access_token=${accessToken}` : `${href}?access_token=${accessToken}`;
                    });
            };

            element.bind('click', e => {
                e.preventDefault();

                $scope.getHref(attrs.ngSecureHref)
                    .then(securedHref => {
                        if ($rootScope.identity.isAuthenticated && securedHref) {
                            $window.open(securedHref, '_blank');
                        }
                    });
            });

            $scope.$on('$destroy', () => {
                element.unbind('click');
            });
        },
    };
};
