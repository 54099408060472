import './statistics.scss';

class StaticsCtrl {
    $onInit() {
        this.tabs = {
            USERS: 'users',
            BOOKMARKS: 'bookmarks',
            FAVORITES: 'favorites',
            GROUPS: 'groups',
            MARKETING_NOTIFICATIONS: 'marketing-notifications',
        };

        this.setTab(this.tabs.USERS);
    }

    setTab(tab) {
        this.randomTab = tab;
    }

    isTabSelected(tab) {
        return this.randomTab === tab;
    }
}

export default {
    template: `
        <div class="row">
            <div class="col-sm-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <ul class="nav nav-pills">
                            <li id="users" role="presentation" ng-class="{ active: $ctrl.isTabSelected($ctrl.tabs.USERS) }">
                                <a ng-click="$ctrl.setTab($ctrl.tabs.USERS)">Users</a>
                            </li>
                            <li id="bookmarks" role="presentation" ng-class="{ active: $ctrl.isTabSelected($ctrl.tabs.BOOKMARKS) }">
                                <a ng-click="$ctrl.setTab($ctrl.tabs.BOOKMARKS)">Custom media</a>
                            </li>
                            <li id="favorites" role="presentation" ng-class="{ active: $ctrl.isTabSelected($ctrl.tabs.FAVORITES) }">
                                <a ng-click="$ctrl.setTab($ctrl.tabs.FAVORITES)">Favorites</a>
                            </li>
                            <li id="groups" role="presentation" ng-class="{ active: $ctrl.isTabSelected($ctrl.tabs.GROUPS) }">
                                <a ng-click="$ctrl.setTab($ctrl.tabs.GROUPS)">Groups</a>
                            </li>
                            <li id="tab-marketing-notifications" role="presentation" ng-class="{ active: $ctrl.isTabSelected($ctrl.tabs.MARKETING_NOTIFICATIONS) }">
                                <a ng-click="$ctrl.setTab($ctrl.tabs.MARKETING_NOTIFICATIONS)">Marketing notifications</a>
                            </li>
                        </ul>
                    </div>
                    <div class="panel-body">
                        <div class="col-sm-6 ">
                            <user-statistics ng-if="$ctrl.isTabSelected($ctrl.tabs.USERS)"></user-statistics>
                            <bookmark-statistics ng-if="$ctrl.isTabSelected($ctrl.tabs.BOOKMARKS)"></bookmark-statistics>
                            <favorite-statistics ng-if="$ctrl.isTabSelected($ctrl.tabs.FAVORITES)"></favorite-statistics>
                            <group-statistics ng-if="$ctrl.isTabSelected($ctrl.tabs.GROUPS)"></group-statistics>
                            <marketing-notification-statistics ng-if="$ctrl.isTabSelected($ctrl.tabs.MARKETING_NOTIFICATIONS)"></marketing-notification-statistics>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    controller: StaticsCtrl,
};
