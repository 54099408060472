import './app-types.scss';
import appTypeDetailsTemplate from './app-type-details.html';

class AppTypesController {

    // @ngInject
    constructor(AppTypeModel, ngDialog, $scope, toaster, $q) {
        this.AppTypeModel = AppTypeModel;
        this.ngDialog = ngDialog;
        this.$scope = $scope;
        this.toaster = toaster;
        this.$q = $q;
    }

    $onInit() {
        return this.getAppTypes();
    }

    getAppTypes() {
        this.AppTypeModel
            .query({ orderBy: 'name' })
            .then((appTypes) => {
                this.appTypes = appTypes;
            });
    }

    showDetails(appType) {
        this.selectedAppType = appType;

        const promise = appType ? this.AppTypeModel.get(appType.id) : this.$q.resolve(new this.AppTypeModel());

        return promise
            .then(resolvedAppType => {
                this.currentAppType = resolvedAppType;
                // allow the selected app type to update as a result of the get
                if (this.selectedAppType) {
                    this.selectedAppType.$update(resolvedAppType);
                }
            })
            .then(() => {
                this.ngDialog.open({
                    template: appTypeDetailsTemplate,
                    plain: true,
                    scope: this.$scope,
                    className: 'ngdialog-theme-default ng-dialog__custom-width',
                });
            });
    }

    save() {
        this.currentAppType.$save()
            .then(appType => {
                if (angular.isUndefined(this.selectedAppType)) {
                    this.appTypes.push(appType);
                } else {
                    this.selectedAppType.$update(appType);
                }

                return this.ngDialog.close();
            });
    }

    delete(item) {
        return item.$destroy()
            .catch(err => {
                if (err.status === 409) {
                    this.toaster.pop('warning', 'Oops, something went wrong', err.data.message);
                } else {
                    return this.$q.reject(err);
                }
            });
    }

}

export default {
    template: `<div class="app-types-view">
    <div class="panel panel-default">
        <div class="panel-heading">
            <h3 class="panel-title app-types__title pull-left">App types</h3>
            <button type="button" class="btn btn-sm btn-success pull-right" ng-click="$ctrl.showDetails()"><span
                class="glyphicon glyphicon-plus-sign"></span> Add a new app type
            </button>
            <div class="clearfix"></div>
        </div>
        <div class="panel-body app-types__list">
            <div class="row app-types__no-result" ng-show="!$ctrl.appTypes || $ctrl.appTypes.length === 0">
                <span class="text-muted">No matching app type found</span>
            </div>
            <div
                class="row app-types__row"
                ng-repeat="(key, appType) in $ctrl.appTypes"
            >
                <div class="col-xs-8">
                    <div class="app-types__icon">
                        <square-box caption="appType.icon.initials" background-color="appType.icon.color"
                                    component-class="square-box-component--small"></square-box>
                    </div>
                    <div class="app-types__details">
                        <strong>{{ appType.name }}</strong>
                    </div>
                </div>
                <div class="col-xs-4">
                    <div class="app-types__actions pull-right">
                        <button type="button" class="btn btn-default btn-sm" ng-click="$ctrl.showDetails(appType)">
                            <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span> Edit
                        </button>
                        <button type="button" class="btn btn-danger btn-sm" ng-click-confirmed confirmed="$ctrl.delete(appType)" confirmation-title='Delete app type' confirmation-message="Are you sure you want to delete : '{{ appType.name }}'">
                            <span class="glyphicon glyphicon-remove" aria-hidden="true"></span> Delete
                        </button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>`,
    controller: AppTypesController,
};
