/* @ngInject */
export default function MediaContentTypeModelFactory($modelFactory) {
    const model = $modelFactory('shell/media-content-types', {
        actions: {
            query: {
                afterRequest: (response) => {
                    return response.data;
                },
            },
            update: {
                beforeRequest: (req) => {
                    delete req.data.id;
                    delete req.data.modules;
                },
            },
            linkAllForMethod: {
                method: 'POST',
                url: 'all/methods/{methodId}',
                invalidateCache: true,
                wrap: false,
            },
        },
        instance: {
            $link: function (module) { // eslint-disable-line object-shorthand
                return model.$call({
                    method: 'POST',
                    url: `/api/shell/media-content-types/${this.id}/modules/${module.id}`,
                    invalidateCache: true,
                    wrap: true,
                });
            },
            $unlink: function (module) { // eslint-disable-line object-shorthand
                return model.$call({
                    method: 'DELETE',
                    url: `/api/shell/media-content-types/${this.id}/modules/${module.id}`,
                    invalidateCache: true,
                    wrap: true,
                });
            },
        },

        toResource: (data) => {
            const cleaned = model.$strip(data);

            delete cleaned.$isDirty;
            delete cleaned.$link;
            delete cleaned.$unlink;

            return cleaned;
        },
    });

    return model;
}
