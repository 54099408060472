import set from 'lodash/set';
import get from 'lodash/get';
import DeepDiff from 'deep-diff';

/* @ngInject */
export default function AboutModelFactory($modelFactory, $q) {
    const model = $modelFactory('shell/about/{group}', {
        actions: {
            query: {
                afterRequest: response => response.data,
            },
        },
        instance: {
            $patch: function () { // eslint-disable-line object-shorthand
                const changedPaths = (this.$diff() || []).map(d => d.path.join('.'));
                if (changedPaths.length === 0) return $q.resolve(this);

                return model.$call({
                    method: 'PATCH',
                    url: `/api/shell/about/${this.id}`,
                    override: true,
                    data: this,
                    invalidateCache: true,
                    wrap: true,
                    beforeRequest: function (req) { // eslint-disable-line object-shorthand

                        const data = {};
                        changedPaths.forEach(p => {
                            set(data, p, get(req.data, p));
                        });
                        req.data = data;
                    },
                });
            },
            $revert: function () { // eslint-disable-line object-shorthand
                const changes = this.$diff();
                if (changes && changes.length > 0) {
                    changes.forEach(change => DeepDiff.revertChange(this, {}, change));
                }
            },
        },
    });

    return model;
}
