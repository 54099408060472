import moment from 'moment';

export default function () {
    return time => {
        if (!time || !moment(new Date(time)).isValid()) {
            return time;
        }

        return moment(time).fromNow();
    };
}
