import ngClickConfirmedTemplate from './ng-click-confirmed.html';

function openDialog($rootScope, ngDialog, title, message) {
    const newScope = $rootScope.$new();

    newScope.message = message || 'Are you sure?';
    newScope.title = title || 'Delete confirmation';

    const dialog = ngDialog.open({
        template: ngClickConfirmedTemplate,
        plain: true,
        controller: ['$scope', ($scope) => {
            $scope.no = () => {
                ngDialog.close(dialog.id, 'no');
            };

            $scope.yes = () => {
                ngDialog.close(dialog.id, 'yes');
            };
        }],
        closeByEscape: false,
        closeByNavigation: false,
        showClose: false,
        scope: newScope,
        className: 'ngdialog-theme-default ng-click-confirmed-modal',
    });

    return dialog.closePromise;
}

// @ngInject
export default (ngDialog, $rootScope) => {
    return {
        restrict: 'A',
        scope: {
            confirmed: '&confirmed',
        },
        link: (scope, element, attributes) => {
            element.bind('click', () => {
                openDialog($rootScope, ngDialog, attributes.confirmationTitle, attributes.confirmationMessage)
                    .then((dlgResult) => {
                        if (dlgResult && dlgResult.value === 'no') {
                            return null;
                        }

                        return scope.confirmed();
                    });
            });
        },
    };
};
