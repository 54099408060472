import getAccessToken from './BearerToken';

export default function refreshTokenInterceptor(config) {
    if (config.skipAuthorization || config.headers.Authorization) return Promise.resolve(config);

    return getAccessToken().then(accessToken => {
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`; // eslint-disable-line no-param-reassign
        }
        return config;
    });
}
