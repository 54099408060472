import angular from 'angular';
import slugValidator from './slug-validator';
import colorValidator from './color-validator';
import ngClickConfirmed from './ng-click-confirmed';


const module = angular.module('app.shell.directives', []);

module.directive('slug', slugValidator);
module.directive('colorValidator', colorValidator);
module.directive('ngClickConfirmed', ngClickConfirmed);

export default module.name;
