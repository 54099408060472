function cleanRequest(req) {
    if (req.data.cover) {
        if (!req.data.cover.id) {
            delete req.data.cover;
        } else {
            delete req.data.cover.url;
        }
    }

    if (req.data.linkedApp) {
        if (!req.data.linkedApp.id) {
            delete req.data.linkedApp;
        } else {
            delete req.data.linkedApp.name;
        }
    }

    if (!req.data.releaseDate) {
        delete req.data.releaseDate;
    }
}

/* @ngInject */
export default function AppModelFactory($modelFactory) {
    const model = $modelFactory('shell/apps', {
        actions: {
            query: {
                afterRequest: (response) => {
                    return response.data;
                },
            },
            post: {
                beforeRequest: (request) => {
                    return cleanRequest(request);
                },
            },
            update: {
                beforeRequest: (request) => {
                    return cleanRequest(request);
                },
            },
        },
        instance: {
            toResource: function () { // eslint-disable-line object-shorthand
                const cleaned = model.$strip(this);

                delete cleaned.$isDirty;
                delete cleaned.toResource;

                return cleaned;
            },
        },
    });

    return model;
}
