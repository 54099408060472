// @ngInject
export function routing($urlRouterProvider) {
    $urlRouterProvider
        .when('', '/')
        .otherwise('/home');
}

// @ngInject
export function errorHandler($provide) {
    // @ngInject
    $provide.decorator('$exceptionHandler', ($delegate, $injector) => {
        return function (exception, cause) {
            if ($injector.has('toaster')) {
                const toaster = $injector.get('toaster');
                toaster.pop('error', 'Program Error', exception.data || exception.message);
            }

            $delegate(exception, cause);
        };
    });
}

// @ngInject
export function disableDebugInfo($compileProvider) {
    // see https://docs.angularjs.org/guide/production#disabling-debug-data
    $compileProvider.debugInfoEnabled(ENVIRONMENT !== 'production');
}

// @ngInject
export function modelFactoryConfig($modelFactoryProvider, config) {
    const defaultOptions = $modelFactoryProvider.defaultOptions;

    defaultOptions.prefix = config.baseUrl;

    defaultOptions.instance.$isDirty = function () {
        return this.$diff() !== undefined;
    };
}

// @ngInject
export function ngDialogConfig(ngDialogProvider) {
    ngDialogProvider.setDefaults({
        className: 'ngdialog-theme-default',
        closeByDocument: false,
        closeByNavigation: true,
    });
}

// @ngInject
export function fileUploaderConfig($provide) {
    $provide.decorator('FileSelect', ['$delegate', function (FileSelect) {

        FileSelect.prototype.isEmptyAfterSelection = function () {
            // by default the component only clears the input type file if it has the multiple attribute
            // we want to clear it always so we can reupload the same file.
            return true;
        };

        return FileSelect;
    }]);
}

// @ngInject
export function interceptors($httpProvider) {
    $httpProvider.interceptors.push('apiInterceptor');
}

// @ngInject
export function hashPrefixConfig($locationProvider) {
    $locationProvider.hashPrefix('');
}

// @ngInject
export function storageProviderConfig(storeProvider) {
    storeProvider.setCaching(false);
}
