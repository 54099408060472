import set from 'lodash/set';
import get from 'lodash/get';
import DeepDiff from 'deep-diff';

/* @ngInject */
export default function AuthorFactory($modelFactory, $q) {
    const model = $modelFactory('shell/modules/{module}/authors', {
        actions: {
            query: {
                afterRequest: res => res.data,
            },

            queryActiveAuthors: {
                method: 'GET',
                url: '/api/shell/authors',
                isArray: true,
                override: true,
                afterRequest: res => res.data,
            },
        },
        instance: {
            $patch: function () { // eslint-disable-line object-shorthand
                const changedPaths = (this.$diff() || []).map(d => d.path.join('.')).filter(path => ['expiresAt'].indexOf(path) > -1);
                if (changedPaths.length === 0) return $q.resolve(this);
                return model.$call({
                    method: 'PATCH',
                    url: `/api/shell/modules/${this.module}/authors/${this.id}`,
                    data: this,
                    invalidateCache: true,
                    wrap: true,
                    beforeRequest: function (req) { // eslint-disable-line object-shorthand
                        const data = {};

                        changedPaths.forEach(p => {
                            set(data, p, get(req.data, p));
                        });
                        req.data = data;
                    },
                });
            },
            $revert: function () { // eslint-disable-line object-shorthand
                const changes = this.$diff();
                if (changes && changes.length > 0) {
                    changes.forEach(change => DeepDiff.revertChange(this, {}, change));
                }
            },
            sortName: function () { //eslint-disable-line object-shorthand
                return `${this.user.firstName} ${this.user.lastName} (${this.user.primaryEmail})`;
            },
            sortSubjectMethod: function () { //eslint-disable-line object-shorthand
                return `${this.module.subjectName} ${this.module.name}`;
            },
        },
    });
    return model;
}
