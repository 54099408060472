class ImportHistoryController {
    // @ngInject
    constructor(ImportHistoryModel) {
        ImportHistoryModel
            .query({
                orderBy: '-time',
                pageSize: 10,
            })
            .then((imports) => {
                this.importHistory = imports;
            });
    }

    showImportDetails(item) {
        this.selectedItem = item;
    }
}

export default {
    template: `
    <div class="row import-history__no-result" ng-if="!$ctrl.importHistory || $ctrl.importHistory.length === 0">
        <span class="text-muted">No import history found</span>
    </div>

    <div class="import-history__wrapper"  ng-if="$ctrl.importHistory && $ctrl.importHistory.length !== 0">
        <table class="table">
            <thead class="import-history__labels">
                <tr>
                    <th>Name</th>
                    <th>Time</th>
                    <th>File - Publishinghouse</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr id="import-history__item-{{key}}" class="import-history__item" ng-repeat="(key, item) in $ctrl.importHistory" ng-click="$ctrl.showImportDetails(item)"  ng-class="{ active : $ctrl.selectedItem === item }" >
                    <td class="import-history__name">{{ item.user }}</td>
                    <td class="import-history__time">{{ item.time | importHistoryTime }}</td>
                    <td class="import-history__file">{{ item.file }} - {{ item.publishinghouse }}</td>
                    <td class="import-history__status" ng-attr-title="{{ item.error }}">
                        <span class="glyphicon"
                            ng-class="{ 'glyphicon-ok text-success' : item.status,  'glyphicon-remove text-danger' : !item.status }"></span>
                        <span ng-show="!item.status" class="glyphicon glyphicon-info-sign text-danger"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    `,
    controller: ImportHistoryController,
    bindings: {
        selectedItem: '=',
    },
};
