'use strict';

const HighestRoleExtractor = require('./highest-role-extractor');

class Identity {

    /**
     * Identity constructor
     *
     * @param {string} userId
     * @param {string} [firstname]
     * @param {string} [lastname]
     * @param {string} [email]
     * @param {string[]} roles
     * @param {string[]} authorModules - array with module ids where user is author for
     * @param {Record<string, unknown>} data
     *
     * @returns Identity object
     */
    constructor(userId, firstname, lastname, email, roles = [], authorModules = [], data = {}) {
        this.userId = userId;
        this.firstname = firstname;
        this.lastname = lastname;
        this.email = email;
        this.roles = roles;
        this.author_modules = authorModules;
        this.data = data;

        Object.freeze(this);
    }

    get fullName() {
        const names = [];

        if (this.firstname) names.push(this.firstname);
        if (this.lastname) names.push(this.lastname);

        return names.join(' ');
    }

    get isAuthenticated() {
        return this.userId !== undefined;
    }

    /**
     * @param {string} role
     */
    isInRole(role) {
        return this.roles.indexOf(role) > -1;
    }

    /**
     * @param {string[]} roles
     */
    hasAnyRole(roles) {
        if (!roles) {
            return this.roles.length > 0;
        }

        return this.roles.some(r => roles.indexOf(r) > -1);
    }

    /**
     * @param {string} role
     */
    hasOnlyRole(role) {
        return this.roles.length === 1 && this.isInRole(role);
    }

    get highestRole() {
        return HighestRoleExtractor.extract(this.roles);
    }

    toString() {
        return this.fullName;
    }

    static anonymous() {
        return new Identity(undefined, 'Anonymous');
    }

    isAuthor() {
        return this.author_modules && this.author_modules.length > 0;
    }

    /**
     * @param {string | { id: string }} module
     */
    isAuthorForModule(module) {
        const id = module.id || module;
        return this.author_modules.indexOf(id) > -1;
    }
}

module.exports = Identity;
