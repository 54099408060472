import wordListsMediaTemplate from './import-word-lists-media.view.html';

import './import-word-lists-media.scss';

class ImportWordListsMediaController {
    // @ngInject
    constructor($scope, $q, fileUploadValidationService, WordModel, $http, config) {
        this.$scope = $scope;
        this.$q = $q;
        this.WordModel = WordModel;
        this.$http = $http;
        this.fileUploadValidationService = fileUploadValidationService;
        this.config = config;
    }

    $onInit() {
        this.uploadSuccess = false;
        this.uploadUrl = `${this.config.baseUrl}/shell/modules/${this.module}/import-word-lists-media`;
    }

    onUploadInitialized(uploader) {
        this.uploader = uploader;
        this.uploader.filters.push({
            name: 'allowedExtensions',
            fn: item => {
                return this.fileUploadValidationService.hasValidExtension(item.name, 'mp3')
                    || this.fileUploadValidationService.hasValidExtension(item.name, 'jpg')
                    || this.fileUploadValidationService.hasValidExtension(item.name, 'jpeg');
            },
        });
    }

    onItemFileNotFound(item, errors) {
        errors.push({
            name: item.file.name,
            message: 'This file can\'t be matched.',
        });
    }

    onAddingFileFailed(filter, item, errors) {
        if (filter.name === 'allowedExtensions') {
            const errorMessage = 'The file is not allowed. It has an extension we don\'t accept.';

            errors.push({
                name: item.name,
                message: errorMessage,
            });
        }
    }

    onUploadCompleted(errors) {
        return this.WordModel
            .$getUnmatchedWords(this.module)
            .then(unmatchedWords => {
                unmatchedWords.forEach(word => {

                    if (word.importFilenames.image) {
                        errors.push({
                            name: word.importFilenames.image,
                            message: 'No image file found to match.',
                        });
                    }

                    if (word.importFilenames.maleVoice) {
                        errors.push({
                            name: word.importFilenames.maleVoice,
                            message: 'No maleVoice file found to match.',
                        });
                    }

                    if (word.importFilenames.femaleVoice) {
                        errors.push({
                            name: word.importFilenames.femaleVoice,
                            message: 'No femaleVoice file found to match.',
                        });
                    }
                });
            })
            .then(() => {
                this.uploadSuccess = errors.length === 0;
            });
    }
}

export default {
    template: wordListsMediaTemplate,
    controller: ImportWordListsMediaController,
    bindings: {
        module: '<',
    },
};
