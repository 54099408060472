import get from 'lodash/get';
import set from 'lodash/set';
import without from 'lodash/without';

function cleanRequest(data) {
    if (data.file && data.file.linkedMedia === null) {
        data.file.linkedMedia = '';
    }
}

/* @ngInject */
export default function MediaLinkFactory($modelFactory) {
    const model = $modelFactory('shell/modules/{moduleId}/table-of-content/{nodeId}/medialinks', {
        actions: {
            query: {
                afterRequest: response =>
                    // map inner methods array
                    response.data,
            },
            get: {
                url: '/api/shell/modules/{moduleId}/table-of-content/{nodeId}/medialinks/{id}',
                override: true,
            },
            getByModule: {
                url: '/api/shell/modules/{moduleId}/medialinks?onlyAppCandidates={onlyAppCandidates}',
                override: true,
            },
            post: {
                beforeRequest: req => {
                    delete req.data.moduleId;
                    delete req.data.nodeId;

                    if (req.data.edumaticExercise && !req.data.edumaticExercise.showScore) req.data.edumaticExercise.showScore = false;
                    if (req.data.eduhintExercise && !req.data.eduhintExercise.showScore) req.data.eduhintExercise.showScore = false;
                },
            },
        },
        instance: {
            $patch: function () { // eslint-disable-line object-shorthand
                cleanRequest(this);

                const changedPaths = without((this.$diff() || []).map(d => d.path.join('.')), 'moduleId', 'nodeId', 'checked', '_contentTypeModel', 'tagsAsString', 'file');
                // The component handling tags (angular-multiselect) executes on initial render
                if (changedPaths.length === 0) return Promise.resolve(this);

                return model.$call({
                    method: 'PATCH',
                    url: `/api/shell/modules/${this.moduleId}/table-of-content/${this.nodeId}/medialinks/${this.id}`,
                    data: this,
                    invalidateCache: true,
                    wrap: true,
                    beforeRequest: function (req) { // eslint-disable-line object-shorthand
                        const data = {};

                        changedPaths.forEach(p => {
                            if (p === 'file.pdfLinkTarget') {
                                const pdfLinkTargetVal = get(req.data, p) || null;
                                return set(data, p, pdfLinkTargetVal);
                            }

                            set(data, p, get(req.data, p));
                        });
                        req.data = data;
                    },
                });
            },
            $unpublish: function () { // eslint-disable-line object-shorthand
                return model.$call({
                    method: 'DELETE',
                    url: `/api/shell/modules/${this.moduleId}/table-of-content/${this.nodeId}/medialinks/${this.id}/published`,
                    wrap: true,
                });
            },
            $publish: function () { // eslint-disable-line object-shorthand
                return model.$call({
                    method: 'PUT',
                    url: `/api/shell/modules/${this.moduleId}/table-of-content/${this.nodeId}/medialinks/${this.id}/published`,
                    wrap: true,
                });
            },
            $rank: function (before, after) { // eslint-disable-line object-shorthand
                return model.$call({
                    method: 'PUT',
                    url: `/api/shell/modules/${this.moduleId}/table-of-content/${this.nodeId}/medialinks/${this.id}/rank`,
                    invalidateCache: true,
                    data: {
                        before,
                        after,
                    },
                });
            },
            $move: function (destinationNodeId, destinationModuleId) { // eslint-disable-line object-shorthand
                return model.$call({
                    method: 'PUT',
                    url: `/api/shell/modules/${this.moduleId}/table-of-content/${this.nodeId}/medialinks/${this.id}/move`,
                    invalidateCache: true,
                    wrap: false,
                    data: {
                        destinationNodeId,
                        destinationModuleId,
                    },
                });
            },
            $copyMedialink: function (destinationNodeId, destinationModuleId) { // eslint-disable-line object-shorthand
                return model.$call({
                    method: 'PUT',
                    url: `/api/shell/modules/${this.moduleId}/table-of-content/${this.nodeId}/medialinks/${this.id}/copy`,
                    invalidateCache: true,
                    wrap: false,
                    data: {
                        destinationNodeId,
                        destinationModuleId,
                    },
                });
            },
            contentTypeModel: function (mediaContentType) { // eslint-disable-line object-shorthand
                if (!arguments.length) return this._contentTypeModel; //eslint-disable-line prefer-rest-params

                this._contentTypeModel = mediaContentType;

                this.contentType = mediaContentType ? mediaContentType.id : undefined;
                if (!this.contentType) delete this.contentType;

                if (mediaContentType && !mediaContentType.isExercise) delete this.exerciseCategories;
            },
            toResource: function () { // eslint-disable-line object-shorthand
                const cleaned = model.$strip(this);

                delete cleaned.$isDirty;
                delete cleaned.$patch;
                delete cleaned.$unpublish;
                delete cleaned.$publish;
                delete cleaned.$rank;
                delete cleaned.$move;
                delete cleaned.$copyMedialink;
                delete cleaned.contentTypeModel;
                delete cleaned._contentTypeModel;
                delete cleaned.tagsAsString;
                delete cleaned.toResource;

                return cleaned;
            },
        },
    });

    return model;
}
