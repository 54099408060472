import React from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';

const GLYPHICON_ROOT = 'glyphicon';

function GlyphIcon({
    className,
    'data-testid': dataTestId,
    iconName,
}) {
    return (
        <span
            className={classNames(GLYPHICON_ROOT, {
                [`${GLYPHICON_ROOT}-${iconName}`]: true,
            }, className)}
            data-testid={dataTestId}
        />
    );
}

GlyphIcon.propTypes = {
    className: string,
    iconName: string.isRequired,
    'data-testid': string,
};

GlyphIcon.defaultProps = {
    className: undefined,
    'data-testid': undefined,
};

export default GlyphIcon;
