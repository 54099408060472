import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { ExportState } from '../types/ExportState';
import shellApi from '../../../services/api';

type UseExportReturnType = {
    exportState?: ExportState;
    getExportState: () => Promise<void>;
    generateExport: () => Promise<void>;
    isExportPending: (state: ExportState) => boolean;
};

let timeout: ReturnType<typeof setTimeout> | undefined;

const REFETCH_TIMEOUT = 10000;
const ALLOWED_TYPES = ['files', 'meta-data'] as const;

export type AllowedTypes = typeof ALLOWED_TYPES[number];

export default function useExport(type: AllowedTypes, moduleId: string): UseExportReturnType {
    const [exportState, setExportState] = useState<ExportState>();

    useEffect(() => () => {
        if (timeout) clearTimeout(timeout);
    }, []);

    if (!ALLOWED_TYPES.includes(type)) {
        throw new Error('Invalid export type');
    }

    const exportUrl = `/shell/modules/${moduleId}/medialinks/export/${type}`;

    const isExportPending = useCallback((state: ExportState) => state.status === 'started' || state.status === 'requested', []);

    const getExportState = useCallback(async () => {
        if (timeout) clearTimeout(timeout);

        try {
            const { data } = await shellApi.get(exportUrl);
            setExportState(data);

            if (isExportPending(data)) {
                timeout = setTimeout(() => getExportState(), REFETCH_TIMEOUT);
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response?.status === 404) {
                return setExportState({
                    status: 'not-started',
                    downloadUrl: undefined,
                    name: undefined,
                });
            }

            throw e;
        }
    }, [exportUrl, isExportPending]);

    /**
     * Starts the export and updates the state with an optimistic update.
     * The export process is async, which means we can't immediately fetch the export state on success.
     * Instead, we'll wait for the next 10 seconds and then fetch the export state.
     */
    const generateExport = useCallback(async () => {
        setExportState({
            status: 'requested',
            downloadUrl: undefined,
            name: undefined,
        });
        try {
            await shellApi.post(exportUrl);
        } catch (e) {
            if (axios.isAxiosError(e) && e.response?.status === 409) {
                return setExportState({
                    status: 'started',
                    downloadUrl: undefined,
                    name: undefined,
                });
            }

            throw e;
        }

        setTimeout(() => getExportState(), REFETCH_TIMEOUT);
    }, [exportUrl, getExportState]);

    return {
        exportState,
        getExportState,
        generateExport,
        isExportPending,
    };
}
