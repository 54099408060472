import angular from 'angular';
import component from './exports.view';
import reactComponent from '../../../common/react-component';
import MonthlyExports from './MonthlyExports';

const module = angular.module('app.shell.modules.exports', []);

module
    .config($stateProvider => {
        // @ngInject
        $stateProvider.state('exports', {
            url: '/exports',
            template: `
                <div class="row">
                    <shell-exports></shell-exports>
                    <monthly-exports></monthly-exports>
                </div>
            `,
            security: permissionService => permissionService.canManageExports,
        });
    })
    .component('shellExports', component)
    .component('monthlyExports', reactComponent(MonthlyExports));

export default module.name;
