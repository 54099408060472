import './exercise-categories.scss';

class ExerciseCategoryController {
    // @ngInject
    constructor($q, toaster, ExerciseThemeModel, ExerciseCategoryModel, ModuleModel) {
        this.$q = $q;
        this.toaster = toaster;
        this.ExerciseThemeModel = ExerciseThemeModel;
        this.ExerciseCategoryModel = ExerciseCategoryModel;
        this.ModuleModel = ModuleModel;
    }

    $onInit() {
        this.commonTheme = {
            id: 'general',
            name: 'Algemeen',
            rank: 0,
        };

        this.tableOptions = {
            beforeDrop: (theme) => {
                const themes = angular.copy(this.themes);

                const destIsHigherThanSource = theme.dest.index > theme.source.index;
                const droppedOnIndex = themes[theme.dest.index];

                const rank = {
                    before: destIsHigherThanSource ? themes[theme.dest.index + 1] : droppedOnIndex,
                    after: destIsHigherThanSource ? droppedOnIndex : themes[theme.dest.index - 1],
                };

                if (theme.dest.index === 0) delete rank.after;
                if (theme.dest.index === themes.length - 1) delete rank.before;

                const themeToRank = this.themes[theme.source.index];

                return themeToRank.$rank(rank.before ? rank.before.id : undefined, rank.after ? rank.after.id : undefined)
                    .catch(err => {
                        if (err.status === 404) {
                            this.toaster.pop('warning', 'Oops, something went wrong', 'Someone removed the theme you are trying to drag');
                            return this.$q.resolve(false);
                        }

                        if (err.status === 409) {
                            this.toaster.pop('warning', 'Oops, something went wrong', 'Invalid state detected, try refreshing your page');
                            return this.$q.resolve(false);
                        }
                        return this.$q.reject(err);
                    });
            },
        };
    }

    onFilterChange(filter) {
        this.filter = filter;
        this.toggleAdd(false);
        return this.initialize(this.filter.method);
    }

    initialize(method) {
        this.commonTheme.method = method;

        const promise = !method
            ? this.$q.resolve([])
            : this.$q.all([
                this.ExerciseCategoryModel.query({ method }),
                this.ExerciseThemeModel.query({ method }),
                this.ModuleModel.query({ methodId: method, orderBy: 'name' }),
            ]);

        promise
            .then(([categories, themes, modules]) => {
                if (categories) {
                    categories.forEach(category => {
                        if (!category.theme) {
                            category.theme = this.commonTheme;
                        }
                    });
                }

                this.categories = categories;
                this.themes = themes;
                this.modules = modules;
            });
    }

    toggleAdd(toggled) {
        if (toggled) {
            if (!this.newTheme) {
                this.newTheme = new this.ExerciseThemeModel();
                this.newTheme.editMode = true;
                this.newTheme.name = '';
                this.newTheme.method = this.filter.method;
            }
        } else {
            this.newTheme = undefined;
        }
    }

    save(theme) {
        const isNew = !theme.id;

        return (isNew ? theme.$save() : theme.$patch())
            .then(x => {
                if (isNew) {
                    this.themes.push(x);
                    this.toggleAdd(false);
                } else {
                    theme.$update(x);
                    theme.$commit();
                }

            })
            .catch(err => {
                return err.status === 404
                    ? this.handleNotFound(theme, true)
                    : this.$q.reject(err);
            });
    }

    delete(theme) {
        return theme.$destroy()
            .catch(err => {
                switch (err.status) {
                    case 404:
                        return this.handleNotFound(theme);
                    case 409:
                        this.toaster.pop('warning', 'Forbidden', 'The theme has related categories');
                        break;
                    default:
                        return this.$q.reject(err);
                }
            });
    }

    handleNotFound(theme, warn) {
        if (warn === true) this.toaster.pop('warning', `The theme '${theme.name}' was removed`);

        const index = this.themes.indexOf(theme);
        if (index > -1) this.themes.splice(index, 1);
    }
}

export default {
    template: `
    <div class="exercise-categories-view col-xs-12">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3 class="panel-title exercise-categories__title">Exercise Themes & Categories Manager</h3>
        </div>
        <div class="panel-body exercise-categories__list">
            <hierarchy-filter
                class="pt-hierarchy-filter"
                levels="3"
                inline="true"
                filter-change="$ctrl.onFilterChange(filter)"
                >
            </hierarchy-filter>
            <hr/>
            <div class"panel panel-default">
                <div class="panel-heading">
                    <button type="button" class="btn btn-sm btn-success pull-right qs-add-theme" ng-disabled="!$ctrl.filter.method" ng-click="$ctrl.toggleAdd(true)"><span
                        class="glyphicon glyphicon-plus-sign"></span> Add new theme
                    </button>
                    <div class="clearfix"></div>
                </div>
                <hr/>
                <div class="panel-body">
                    <table class="table table-condensed exercise-theme__table table-bordered" ng-if="$ctrl.filter.method && $ctrl.modules.length > 0">
                        <thead>
                            <tr>
                                <th>
                                    <div class="th__name"/>
                                </th>
                                <th>
                                    <div class="th__difficulty"/>
                                </th>
                                <th class="th__module-name" ng-repeat="module in $ctrl.modules">
                                    <div class="th__module-name">
                                        {{module.name}}
                                    </div>
                                </th>
                            </tr>
                        <thead/>
                    </table>
                    <div ui-tree="$ctrl.tableOptions" ng-if="$ctrl.filter.method && $ctrl.themes.length > 0" class="custom-themes-table">
                        <ul ui-tree-nodes ng-model="$ctrl.themes">
                            <li ng-repeat="theme in $ctrl.themes" ui-tree-node>
                                <div>
                                    <shell-theme
                                        theme="theme"
                                        categories="$ctrl.categories"
                                        modules="$ctrl.modules"
                                        save="$ctrl.save(theme)"
                                        cancel="theme.$revert()"
                                        delete="$ctrl.delete(theme)"
                                    >
                                    </shell-theme>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <span ng-if="!$ctrl.filter.method" class="text-muted no-result col-xs-12">Please select a method</span>
                    <span ng-if="$ctrl.filter.method && $ctrl.themes.length === 0 && !$ctrl.newTheme" class="text-muted no-result col-xs-12">No themes found</span>
                    <div class="exercise-theme__new" ng-if="$ctrl.newTheme">
                        <shell-theme
                            theme="$ctrl.newTheme"
                            save="$ctrl.save(theme)"
                            cancel="$ctrl.toggleAdd(false)"
                            modules="$ctrl.modules"
                        />
                    </div>
                    <br/><br/>
                    <shell-theme
                        class="general-theme"
                        ng-if="$ctrl.filter.method"
                        theme="$ctrl.commonTheme"
                        modules="$ctrl.modules"
                        categories="$ctrl.categories"/>
                </div>
            </div>
        </div>
    </div>`,
    controller: ExerciseCategoryController,
};
