import getAccessToken, { RefreshTokenFailedError } from '../utils/BearerToken';

// @ngInject
export default function tokenAccessorFactory($q) {
    return {
        tokenGetter: () => {
            const deferred = $q.defer();

            getAccessToken()
                .then(token => {
                    deferred.resolve(token);
                })
                .catch(err => {
                    if (err instanceof RefreshTokenFailedError) deferred.resolve(null);
                    else deferred.reject(err);
                });

            return deferred.promise;
        },
    };
}
