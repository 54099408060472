import get from 'lodash/get';

class AuthorsGridController {

    // @ngInject
    constructor($exceptionHandler, toaster) {
        this.$exceptionHandler = $exceptionHandler;
        this.toaster = toaster;
    }

    onAuthorExpiryDateChanged(author) {
        return author.$patch()
            .then(res => {
                author.$update(res);
                author.$commit();
            })
            .catch(err => {
                if (err.status === 404 && get(err, 'data.message', '').indexOf("'Author'") > -1) {
                    this.handleNotFound(author, true);
                } else {
                    author.$revert();
                    this.$exceptionHandler(err);
                }
            });
    }

    delete(author) {
        return author
            .$destroy()
            .catch(err => {
                if (err.status === 404) {
                    return this.handleNotFound(author);
                }
                return this.$exceptionHandler(err);
            });
    }

    handleNotFound(author, warn) {
        if (warn === true) this.toaster.pop('warning', `The author '${author.sortName()}' was removed`);

        const index = this.data.indexOf(author);
        if (index > -1) this.data.splice(index, 1);
    }

}

export default {
    template: `
        <div class="row">
            <div class="col-lg-12 table-responsive">
                <table class="table table-condensed extensions__table table-bordered">
                    <thead>
                        <tr>
                            <th ng-if="$ctrl.readOnly">Subject</th>
                            <th ng-if="$ctrl.readOnly">Module</th>
                            <th class="col-lg-5">Author</th>
                            <th ng-if="!$ctrl.readOnly">Last login</th>
                            <th ng-if="!$ctrl.readOnly">Invited on</th>
                            <th>Expirydate</th>
                            <th ng-if="!$ctrl.readOnly"></th>
                        </tr>
                    <thead/>
                    <tbody>
                        <tr ng-if="!$ctrl.data || $ctrl.data.length === 0">
                            <td colspan=5 class="no-result text-muted qs-no-data">∞</td>
                        </tr>
                        <tr ng-repeat="author in $ctrl.data | orderBy:'[sortSubjectMethod(), sortName()]'" ng-attr-data-author-id="{{ author.id }}">
                            <td ng-bind="author.module.subjectName" ng-if="$ctrl.readOnly"></td>
                            <td ng-bind="author.module.name" ng-if="$ctrl.readOnly"></td>
                            <td ng-bind="author.sortName()"></td>
                            <td ng-if="!$ctrl.readOnly">{{ author.user.lastLoginAt | timeFromNow }}</td>
                            <td ng-if="!$ctrl.readOnly">{{ author.invitedAt | date:'dd-MM-yyyy' }}</td>
                            <td>
                                  <div uib-datepicker-popup="dd-MM-yyyy"
                                      datepicker-append-to-body="true"
                                      show-button-bar="false"
                                      ng-model="author.expiresAt"
                                      ng-change="$ctrl.onAuthorExpiryDateChanged(author)"
                                      is-open="author.opened">
                                      <span>{{ author.expiresAt | date:'dd-MM-yyyy' }}</span>
                                  </div>

                            </td>
                            <td class="text-right actions" ng-if="!$ctrl.readOnly">
                                      <button type="button" class="btn btn-default btn-xs qs-action-pick-date" ng-click="author.opened = !!! author.opened">
                                            <i class="glyphicon glyphicon-calendar"></i>
                                      </button>
                                 <button type="button" class="btn btn-danger btn-xs qs-action-delete" title="Delete"
                                         ng-click-confirmed
                                         confirmed="$ctrl.delete(author)"
                                         confirmation-title='Delete author'
                                         confirmation-message="Are you sure you want to delete : '{{ author.sortName() }}'?">
                                     <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                                 </button>
                            </td>
                        </tr>
                    </tbody>
                <table>
            </div>
        </div>
    `,
    controller: AuthorsGridController,
    bindings: {
        data: '<',
        readOnly: '<?',
    },
};
