/* eslint-disable jsx-a11y/control-has-associated-label  */
import React, { useEffect, useState } from 'react';
import { func } from 'prop-types';
import api from '../../services/api';

const MonthlyExports = ({ ngDispatch }) => {
    const [reports, setReports] = useState([]);

    useEffect(() => {
        const getReports = async () => {
            try {
                const { data } = await api.get('/monthly-exports');
                setReports(data.data);
            } catch (err) {
                ngDispatch({ type: 'unhandledHttpError', payload: err });
            }
        };

        getReports();
    }, [ngDispatch]);

    return (
        <div className="col-md-6 col-lg-4">
            <div className="col-md-12">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h3 className="panel-title">Monthly Reports</h3>
                    </div>
                    <div className="panel-body">
                        <div className="row panel-row">
                            <table className="table table-condensed qs-table__top">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {reports.map(report => (
                                        <tr className="qs-exports__custom-teacher-links" key={report.key}>
                                            <td>
                                                {report.key}
                                                &nbsp;
                                                {`(${report.month}-${report.year})`}
                                            </td>
                                            <td className="text-right">
                                                <a
                                                    className="btn btn-default btn-xs qs-download-document"
                                                    title="Download"
                                                    href={report.signedUrl}
                                                >
                                                    <span className="glyphicon glyphicon-cloud-download" aria-hidden="true" />
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

MonthlyExports.propTypes = {
    ngDispatch: func.isRequired,
};

export default MonthlyExports;
