import angular from 'angular';
import './mini-dialog-manager.scss';
import component from './mini-dialog-manager.view';
import MedialinksHierarchyFilter from './components/MiniDialogHierarchyFilter';
import reactComponent from '../../../common/react-component';

const module = angular.module('app.shell.modules.mini-dialog-manager', []);

module
    .config($stateProvider => {
        // @ngInject
        $stateProvider
            .state('mini-dialog-manager', {
                url: '/mini-dialog-manager',
                title: 'Pakket-p SHELL mini dialogue manager',
                template: `
                    <shell-mini-dialog-manager></shell-mini-dialog-manager>
                `,
                security: permissionService => permissionService.canManageMiniDialogs,
            });
    })
    .component('shellMiniDialogManager', component)
    .component('miniDialogHierarchyFilter', reactComponent(MedialinksHierarchyFilter, ['publishingHouseId', 'subjectId', 'methodId', 'superModuleId', 'moduleId', 'onModuleSelected']));

export default module.name;
