import angular from 'angular';

import component from './authors.view';
import invitationsPanel from './components/invitations-panel';
import inviteAuthorByEmail from './components/invite-author-by-email';
import authorsGrid from './components/authors-grid';
import allAuthorsPanel from './components/all-authors-panel';

const module = angular.module('app.shell.modules.authors', []);

module
    .config($stateProvider => {
        // @ngInject
        $stateProvider
            .state('authors', {
                url: '/authors',
                title: 'Authors - shell - p',
                template: `
                   <shell-authors></shell-authors>
                `,
                security: permissionService => permissionService.canManageAuthors,
            });
    })
    .component('shellAuthors', component)
    .component('invitationsPanel', invitationsPanel)
    .component('inviteAuthorByEmail', inviteAuthorByEmail)
    .component('authorsGrid', authorsGrid)
    .component('allAuthorsPanel', allAuthorsPanel);

export default module.name;
