import angular from 'angular';

import reactComponent from '../../common/react-component';

import navbarComponent from './navbar.component';
import sidebarComponent from './sidebar.component';
import sidebarMenuItemComponent from './sidebar-menu-item.component';
import colorPickerComponent from './color-picker.component';
import imageUploadComponent from './image-upload.component';
import datePickerComponent from './date-picker.component';
import hierarchyFilterComponent from './hierarchy-filter.component';
import publishingStateComponent from './publishing-state.component';
import editableText from './editable-text';
import tocSelector from './toc-selector.component';
import fileUploadComponent from './file-upload.component';
import upload from './upload.component';

import MultiSelectWithSearch from './MultiSelectWithSearch';

import './color-picker.component.scss';
import './image-upload.component.scss';
import './file-upload.component.scss';

const module = angular.module('app.shell.components', [])
    .component('navbar', navbarComponent)
    .component('sidebar', sidebarComponent)
    .component('sidebarMenuItem', sidebarMenuItemComponent)
    .component('colorPicker', colorPickerComponent)
    .component('datePicker', datePickerComponent)
    .component('imageUpload', imageUploadComponent)
    .component('hierarchyFilter', hierarchyFilterComponent)
    .component('editableText', editableText)
    .component('publishingStateComponent', publishingStateComponent)
    .component('tocSelector', tocSelector)
    .component('fileUpload', fileUploadComponent)
    .component('shellMediaLinkFileUpload', upload)
    .component('multiSelectWithSearch', reactComponent(MultiSelectWithSearch, ['items', 'selectedIds', 'onChange', 'disabled', 'placeholder']));

export default module.name;
