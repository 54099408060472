import { func, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import api from '../../../services/api';
import InlineTextFieldEditor from './InlineTextFieldEditor';

function LicenseProducts({
    ngDispatch,
    moduleId,
}) {
    const [licenseProducts, setLicenseProducts] = useState();
    const [licenseProductCodes, setLicenseProductCodes] = useState();

    useEffect(() => {
        const getLicensesProducts = async () => {
            try {
                const { data: { data } } = await api.get('/shell/licenseproducts');

                setLicenseProducts(data);
            } catch (err) {
                ngDispatch({ type: 'unhandledHttpError', payload: err });
            }
        };

        getLicensesProducts();
    }, [ngDispatch]);

    useEffect(() => {
        const getLicenseProductsFromModule = async () => {
            setLicenseProductCodes();

            if (moduleId) {
                try {

                    const { data: { data } } = await api.get(`/shell/modules/${moduleId}/licenseproducts`);

                    setLicenseProductCodes(data);
                } catch (err) {
                    ngDispatch({ type: 'unhandledHttpError', payload: err });
                }
            }
        };

        getLicenseProductsFromModule();
    }, [ngDispatch, moduleId]);

    const licenses = useMemo(() => {
        if (licenseProducts && moduleId && licenseProductCodes) {
            const enrichedLicenseProducts = licenseProducts.map(licenseproduct => {
                const result = licenseProductCodes.filter(lpc => lpc.licenseProduct === licenseproduct.id);

                return {
                    ...licenseproduct,
                    code: result.length ? result[0].code : undefined,
                };
            });

            return enrichedLicenseProducts;
        }

        return undefined;
    }, [licenseProductCodes, licenseProducts, moduleId]);

    async function handleSave(code, licenseProductId, done) {
        try {
            const existingProductCode = licenseProductCodes.some(lpc => lpc.licenseProduct === licenseProductId);

            const data = {
                code,
                licenseProduct: licenseProductId,
            };

            if (existingProductCode) {
                await api.put(`/shell/modules/${moduleId}/licenseproducts`, data);

                const oldProductCodes = licenseProductCodes.filter(lpc => lpc.licenseProduct !== licenseProductId);

                setLicenseProductCodes([...oldProductCodes, data]);

            } else {
                await api.post(`/shell/modules/${moduleId}/licenseproducts`, data);

                setLicenseProductCodes([...licenseProductCodes, data]);
            }

            done();
        } catch (err) {
            ngDispatch({ type: 'unhandledHttpError', payload: err });
        }
    }

    return (
        <div className="panel panel-default" data-testid="panel-license-product-ids">
            <div className="panel-body">
                <div className="col-sm-8">
                    <h3>License product ID&apos;s</h3>
                    {licenses && (
                        <div data-testid="licenses">
                            {licenses.map(license => (
                                <div key={license.id}>
                                    <div className="col-md-6">{license.name}</div>
                                    <InlineTextFieldEditor initialValue={license.code} save={(value, done) => handleSave(value, license.id, done)} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

LicenseProducts.propTypes = {
    moduleId: string,

    ngDispatch: func.isRequired,
};

LicenseProducts.defaultProps = {
    moduleId: undefined,
};

export default LicenseProducts;
