import angular from 'angular';

import component from './marketing-tools.view';
import notificationsPanel from './components/notifications-panel';

const module = angular.module('app.shell.modules.marketing-tools', []);

module
    .config($stateProvider => {
        // @ngInject
        $stateProvider
            .state('marketing-tools', {
                url: '/marketing-tools',
                title: 'Marketing tools - shell - p',
                template: `
                   <shell-marketing-tools></shell-marketing-tools>
                `,
                security: permissionService => permissionService.canManageMarketingTools,
            });
    })
    .component('shellMarketingTools', component)
    .component('notificationsPanel', notificationsPanel);

export default module.name;
