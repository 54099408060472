import color from 'color';

export default () => ({
    restrict: 'A',
    require: 'ngModel',
    link: (scope, element, attributes, ngModel) => {
        ngModel.$validators.color = function (value) {
            if (!value) return true;
            try {
                const crl = color(value);
                ngModel.$viewValue = crl.hexString();
                return true;
            } catch (e) {
                ngModel.$viewValue = null;
                return false;
            }
        };
    },
});
