/*
 Overwrites angular url validator.
 Tests with a pattern that expects two forwards slashes instead of any number.
 */

module.exports = () => {
    return {
        require: '?ngModel',
        link: (scope, element, attr, ctrl) => {

            const urlExpression = /^[a-z][a-z\d.+-]*:\/\/(?:[^:@]+(?::[^@]+)?@)?(?:[^\s:/?#]+|\[[a-f\d:]+\])(?::\d+)?(?:\/[^?#]*)?(?:\?[^#]*)?(?:#.*)?$/i;

            // only apply the validator if ngModel is present and Angular has added the url validator
            if (ctrl && ctrl.$validators.url) {

                // this will overwrite the default Angular url validator
                ctrl.$validators.url = function (modelValue) {
                    return ctrl.$isEmpty(modelValue) || urlExpression.test(modelValue);
                };
            }
        },
    };
};
