import angular from 'angular';
import AppModel from './app.model';
import UserModel from './user.model';
import SubjectModel from './subject.model';
import MethodModel from './method.model';
import PublishingHouseModel from './publishinghouse.model';
import ModuleModel from './module.model';
import ImportHistoryModel from './import-history.model';
import AppTypeModel from './app-type.model';
import LicenseProductModel from './license-product.model';
import LicenseProductModuleModel from './licenseproduct-module.model';
import TagModel from './tag.model';
import MediaContentTypeModel from './media-content-type.model';
import TableOfContentModel from './table-of-content.model';
import MediaLinkModel from './media-link.model';
import FAQModel from './faq-model';
import FAQCategoryModel from './faq-category-model';
import FAQTutorialModel from './faq-tutorial.model';
import EdumaticScheduleModel from './edumatic-schedule.model';
import EdumaticPublicationModel from './edumatic-publication.model';
import FileConstraintModel from './file-constraint.model';
import ExerciseThemeModel from './exercise-theme.model';
import ExerciseCategoryModel from './exercise-category.model';
import AuthorModel from './author.model';
import LanguageModel from './language.model';
import MarketingNotificationModel from './marketing-notification.model';
import AboutModel from './about.model';
import MiniDialogModel from './mini-dialog.model';
import WordListModel from './word-list.model';
import WordModel from './word.model';
import DigibookModel from './digibook.model';

const module = angular.module('app.common.models', []);

module.factory('AppModel', AppModel);
module.factory('UserModel', UserModel);
module.factory('SubjectModel', SubjectModel);
module.factory('MethodModel', MethodModel);
module.factory('PublishingHouseModel', PublishingHouseModel);
module.factory('ModuleModel', ModuleModel);
module.factory('ImportHistoryModel', ImportHistoryModel);
module.factory('AppTypeModel', AppTypeModel);
module.factory('LicenseProductModel', LicenseProductModel);
module.factory('LicenseProductModuleModel', LicenseProductModuleModel);
module.factory('TagModel', TagModel);
module.factory('MediaContentTypeModel', MediaContentTypeModel);
module.factory('TableOfContentModel', TableOfContentModel);
module.factory('MediaLinkModel', MediaLinkModel);
module.factory('FAQModel', FAQModel);
module.factory('FAQCategoryModel', FAQCategoryModel);
module.factory('FAQTutorialModel', FAQTutorialModel);
module.factory('EdumaticPublicationModel', EdumaticPublicationModel);
module.factory('EdumaticScheduleModel', EdumaticScheduleModel);
module.factory('FileConstraintModel', FileConstraintModel);
module.factory('ExerciseThemeModel', ExerciseThemeModel);
module.factory('ExerciseCategoryModel', ExerciseCategoryModel);
module.factory('AuthorModel', AuthorModel);
module.factory('LanguageModel', LanguageModel);
module.factory('MarketingNotificationModel', MarketingNotificationModel);
module.factory('AboutModel', AboutModel);
module.factory('MiniDialogModel', MiniDialogModel);
module.factory('WordListModel', WordListModel);
module.factory('WordModel', WordModel);
module.factory('DigibookModel', DigibookModel);

export default module.name;
