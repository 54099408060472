function cleanRequest(req) {
    if (req.data.dutch && req.data.dutch.moreInfo) {
        if (req.data.dutch.moreInfo.url !== undefined && req.data.dutch.moreInfo.url.trim() === '') {
            delete req.data.dutch.moreInfo.url;
        }

        if (req.data.dutch.moreInfo.label !== undefined && req.data.dutch.moreInfo.label.trim() === '') {
            delete req.data.dutch.moreInfo.label;
        }
    }

    if (req.data.french && req.data.french.moreInfo) {
        if (req.data.french.moreInfo.url !== undefined && req.data.french.moreInfo.url.trim() === '') {
            delete req.data.french.moreInfo.url;
        }

        if (req.data.french.moreInfo.label !== undefined && req.data.french.moreInfo.label.trim() === '') {
            delete req.data.french.moreInfo.label;
        }
    }
}

/* @ngInject */
export default function MarketingNotificationFactory($modelFactory) {
    const model = $modelFactory('shell/marketing-tools/{group}/notifications', {
        actions: {
            query: {
                afterRequest: res => res.data,
            },
            get: {
                url: '/api/shell/marketing-tools/{group}/notifications/{id}',
                override: true,
            },
            post: {
                beforeRequest: req => cleanRequest(req),
            },
            update: {
                beforeRequest: req => cleanRequest(req),
            },
        },
    });

    return model;
}
