class NavBar {
    // @ngInject
    constructor($rootScope, config) {
        this.$rootScope = $rootScope;
        this.config = config;
    }
}

module.exports = {
    template: `
        <nav class="navbar navbar-inverse navbar-fixed-top">
          <div class="container-fluid">
            <div class="navbar-header">
              <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
              <a class="navbar-brand" href={{$ctrl.config.studioUrl}}><span class="glyphicon glyphicon-home"></span> P</a>
              <a class="navbar-brand" ui-sref="home"><span class="glyphicon glyphicon-chevron-right"></span> Content Beheer</a>
            </div>
            <div id="navbar" class="navbar-collapse collapse">
              <ul class="nav navbar-nav navbar-right">
                <li ng-show="$ctrl.$rootScope.identity.isAuthenticated"><a href="#">Hi, {{ $ctrl.$rootScope.identity.fullName }}</a></li>
              </ul>
              </div>
          </div>
        </nav>
       `,
    controller: NavBar,
};
