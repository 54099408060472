class App {
    /* @ngInject */
    constructor($log, config) {
        this.title = "Hello it's me.";
        this.$log = $log;

        this.$log.info(config);
    }
}

export default {
    template: `
        <div class="app">
            <div ui-view></div>
        </div>
    `,
    controller: App,
};
