/* eslint-disable import/prefer-default-export */
import shellApi from '../../../services/api';

export const copyTableOfContent = async (
    sourceModuleId: string,
    targetModuleId: string
): Promise<void> => {
    await shellApi.post(`/shell/modules/${sourceModuleId}/table-of-content/copy`, {
        sourceModuleId,
        targetModuleId,
    });
};
