import DeepDiff from 'deep-diff';
import get from 'lodash/get';
import set from 'lodash/set';

/* @ngInject */
export default function ExerciseThemeModelFactory($modelFactory) {
    const model = $modelFactory('shell/methods/{method}/exercise-themes', {
        actions: {
            query: {
                isArray: true,
                afterRequest: res => {
                    return res.data;
                },
            },
            post: {
                beforeRequest: req => {
                    delete req.data.editMode;
                },
            },
        },
        instance: {
            $patch: function () { // eslint-disable-line object-shorthand
                const changedPaths = (this.$diff() || []).map(d => d.path.join('.')).filter(path => ['name'].indexOf(path) > -1);

                return model.$call({
                    method: 'PATCH',
                    url: `/api/shell/methods/${this.method}/exercise-themes/${this.id}`,
                    data: this,
                    invalidateCache: true,
                    wrap: true,
                    beforeRequest: function (req) { // eslint-disable-line object-shorthand
                        const data = {};

                        changedPaths.forEach(p => {
                            set(data, p, get(req.data, p));
                        });
                        req.data = data;
                    },
                });
            },
            $rank: function (before, after) { // eslint-disable-line object-shorthand
                return model.$call({
                    method: 'PUT',
                    url: `/api/shell/methods/${this.method}/exercise-themes/${this.id}/rank`,
                    invalidateCache: true,
                    data: {
                        before,
                        after,
                    },
                });
            },
            $revert: function () { // eslint-disable-line object-shorthand
                const changes = this.$diff();
                if (changes && changes.length > 0) {
                    changes.forEach(change => DeepDiff.revertChange(this, {}, change));
                }
            },
        },
    });

    return model;
}
