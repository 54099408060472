// @ngInject
export default function featureToggleFactory($http, config) {
    let urlBase = '/api/feature-toggles';
    if (config.studioGroup) urlBase += `?group=${config.studioGroup}`;

    return $http.get(urlBase,
        {
            // This makes it so that this request doesn't send the JWT
            skipAuthorization: true,
        })
        .then(featureToggles => {
            const toggles = {};
            featureToggles.data.forEach(toggle => {
                toggles[toggle.name] = toggle.enabled;
            });
            return toggles;
        });
}
