import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import './hierarchy-filter.scss';

class HierarchyFilter {

    // @ngInject
    constructor($scope, PublishingHouseModel, SubjectModel, MethodModel, ModuleModel, studioGroup, $document) {
        this.$scope = $scope;

        this.PublishingHouseModel = PublishingHouseModel;
        this.SubjectModel = SubjectModel;
        this.MethodModel = MethodModel;
        this.ModuleModel = ModuleModel;
        this.studioGroup = studioGroup;

        this.$document = $document;
    }

    $onInit() {
        this.filter = {};
        this.inline = angular.isUndefined(this.inline) ? false : this.inline;
        this.locked = angular.isUndefined(this.locked) ? false : this.locked;

        this.navigateAfterCopy = this.setFilterAfterCopy.bind(this);

        /**
         Levels :
         1 -> publishinghouses only
         2 -> subject
         3 -> method
         4 -> modules (default)
         */
        this.levels = angular.isUndefined(this.levels) ? 4 : this.levels;

        this.$document[0].addEventListener('set-hierarchy-filter', this.navigateAfterCopy);

        return this.getPublishingHouses();
    }

    $onDestroy() {
        this.$document[0].removeEventListener('set-hierarchy-filter', this.navigateAfterCopy);
    }

    setFilterAfterCopy(evt) {
        const { subjectId, methodId, moduleId } = evt.detail;
        const subject = this.subjects.find(x => x.id === subjectId);

        this.subject = subject;

        this.getMethods().then(() => {
            const method = this.methods.find(x => x.id === methodId);
            this.method = method;

            this.getModules().then(() => {
                const module = this.modules.find(x => x.id === moduleId);
                this.module = module;
                this.setFilter(this.publishingHouse, subject, method, module);
            });
        });
    }

    getPublishingHouses() {
        return this.PublishingHouseModel
            .query({ orderBy: 'rank' })
            .then(publishingHouses => {
                this.publishingHouses = publishingHouses.map(x => {
                    x.groupName = this.studioGroup[x.group];
                    return x;
                });
            });
    }

    getSubjects() {
        if (this.levels < 2) {
            return false;
        }

        return this.SubjectModel
            .query({
                publishingHouseId: this.publishingHouse.id,
                responsible: this.strictResponsible,
                orderBy: 'name',
            })
            .then(subjects => {
                this.subjects = subjects;
            });
    }

    getMethods() {
        if (this.levels < 3) {
            return false;
        }

        return this.MethodModel
            .query({ subjectId: this.subject.id, orderBy: 'name' })
            .then(methods => {
                this.methods = methods;
            });
    }

    getModules() {
        if (this.levels < 4) {
            return false;
        }

        return this.ModuleModel
            .query({ methodId: this.method.id, orderBy: 'name' })
            .then(modules => {
                if (this.onModulesRetrieved) this.onModulesRetrieved({ modules });
                this.modules = modules;
            });
    }

    setFilter(publishingHouse, subject, method, module) {
        this.publishingHouse = publishingHouse;
        this.subject = subject;
        this.method = method;
        this.module = module;

        this.filterChange({
            filter: omitBy({
                publishingHouse: this.publishingHouse ? this.publishingHouse.id : undefined,
                subject: this.subject ? this.subject.id : undefined,
                method: this.method ? this.method.id : undefined,
                module: this.module ? this.module.id : undefined,
            }, isUndefined),
        });
    }

    onPublishinghouseChange() {
        if (this.onPublishingHouseRetrieved) this.onPublishingHouseRetrieved({ publishingHouse: this.publishingHouse });
        this.setFilter(this.publishingHouse);
        return this.getSubjects();
    }

    onSubjectChange() {
        this.setFilter(this.publishingHouse, this.subject);
        return this.getMethods();
    }

    onMethodChange() {
        this.setFilter(this.publishingHouse, this.subject, this.method);
        return this.getModules();
    }

    onModuleChange() {
        return this.setFilter(this.publishingHouse, this.subject, this.method, this.module);
    }

}

export default {
    template: `
        <div class="row hierarchy-filter">
            <div class="col-sm-12 hierarchy-filter__wrapper" ng-class="{ 'inline' : $ctrl.inline }">
                <h4 ng-if="!$ctrl.inline" id="title">Hierarchy filter</h4>
                <select id="selectedPublishingHouseId"
                        ng-class="{ 'widthCalc4' : ($ctrl.levels === 4), 'widthCalc3' : ($ctrl.levels === 3), 'widthCalc2' : ($ctrl.levels === 2) }"
                        class="form-control hierarchy-select"
                        ng-model="$ctrl.publishingHouse"
                        ng-options="pubHouse.name group by pubHouse.groupName for pubHouse in $ctrl.publishingHouses track by pubHouse.id"
                        ng-change="$ctrl.onPublishinghouseChange()"
                        ng-disabled="$ctrl.locked"
                        >
                    <option disabled value="" >Select a publishing house...</option>
                </select>
                <select id="selectedSubjectId"
                        ng-class="{ 'widthCalc4' : ($ctrl.levels === 4), 'widthCalc3' : ($ctrl.levels === 3), 'widthCalc2' : ($ctrl.levels === 2) }"
                        ng-if="$ctrl.publishingHouse && $ctrl.levels > 1"
                        class="form-control hierarchy-select"
                        ng-model="$ctrl.subject"
                        ng-options="subject.name for subject in $ctrl.subjects track by subject.id"
                        ng-change="$ctrl.onSubjectChange()"
                        ng-disabled="$ctrl.locked">
                    <option disabled value="" >Select a subject...</option>
                </select>
                <select id="selectedMethodId"
                        ng-class="{ 'widthCalc4' : ($ctrl.levels === 4), 'widthCalc3' : ($ctrl.levels === 3) }"
                        ng-if="$ctrl.subject && $ctrl.levels > 2"
                        class="form-control hierarchy-select"
                        ng-model="$ctrl.method"
                        ng-options="method.name for method in $ctrl.methods track by method.id"
                        ng-change="$ctrl.onMethodChange()"
                        ng-disabled="$ctrl.locked">
                    <option disabled value="" >Select a method...</option>
                </select>
                <select id="selectedModuleId"
                        ng-class="{ 'widthCalc4' : ($ctrl.levels === 4) }"
                        ng-if="$ctrl.method && $ctrl.levels > 3"
                        class="form-control hierarchy-select"
                        ng-model="$ctrl.module"
                        ng-options="module.name for module in $ctrl.modules track by module.id"
                        ng-change="$ctrl.onModuleChange()"
                        ng-disabled="$ctrl.locked">
                    <option disabled value="" >Select a module...</option>
                </select>
                <p ng-if="$ctrl.locked" class="text-muted lock-message">{{$ctrl.lockMessage}}</p>
            </div>
        </div>
    `,
    controller: HierarchyFilter,
    bindings: {
        filterChange: '&',
        onModulesRetrieved: '&?',
        onPublishingHouseRetrieved: '&?',
        levels: '<?',
        inline: '<?',
        locked: '<?',
        lockMessage: '@?',
        strictResponsible: '<?',
    },
};
