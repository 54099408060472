import MedialinkKind from '../utitilies/medialink-kind';

class SuggestionList {

    typeTranslation(type) {
        switch (type) {
            case MedialinkKind.MINI_DIALOG:
                return 'Dialogue';
            case MedialinkKind.WORD_LIST:
                return 'Word list';
            default: throw new Error(`Unsupported type: ${type}`);
        }
    }
}

export default {
    template:
        `<div>
            <div class="row"  ng-if="$ctrl.suggestions.length === 0">
                <div class="text-muted small qs-no-suggestions">No suggestions available.</div>
            </div>
        <div class="row qs-mini-dialog-suggestions" ng-if="!($ctrl.suggestions.length === 0)" >
            <div class="col-sm-12">
                <table class="table table-condensed mini-dialog-suggestions--table">
                    <thead>
                        <tr>
                            <th width="138px"></th>
                            <th class="col-md-10">Name</th>
                            <th class="col-md-2">Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr ng-repeat="suggestion in $ctrl.suggestions">
                            <td>
                                <button
                                    ng-click="$ctrl.addSuggestion({suggestion})"
                                    class="btn btn-success mini-dialog-suggestion--add-button">
                                    <span class="glyphicon glyphicon-link"></span> Add as medialink</button>
                            </td>

                            <td class="mini-dialog-suggestion--name">
                                <p>{{suggestion.name}}</p>
                            </td>

                            <td class="mini-dialog-suggestion--type">
                                <p>{{$ctrl.typeTranslation(suggestion.type)}}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
</div>`,
    controller: SuggestionList,
    bindings: {
        addSuggestion: '&',
        suggestions: '<',
    },
};
