import angular from 'angular';
import component from './app-library.view';
import appList from './components/app-list.component';
import appItem from './components/app-item.component';
import appTag from '../../../common/components/square-box.component';

const module = angular.module('app.shell.modules.app-library', []);

module
    .config(($stateProvider) => {
        // @ngInject
        $stateProvider.state('app-library', {
            url: '/app-library',
            template: `
                <shell-app-library></shell-app-library>
            `,
            security: (permissionService) => permissionService.canManageApps,
        });
    })
    .component('shellAppLibrary', component)
    .component('shellAppList', appList)
    .component('shellAppItem', appItem)
    .component('shellAppTag', appTag);

export default module.name;
