class InvitationsPanelController {

    // @ngInject
    constructor($q, AuthorModel) {
        this.$q = $q;
        this.AuthorModel = AuthorModel;
    }

    onHierarchyChanged(filter) {
        this.selectedModule = filter.module;

        return (this.selectedModule
            ? this.AuthorModel
                .query({
                    module: this.selectedModule,
                })
            : this.$q.resolve([]))
            .then(authors => {
                this.authors = authors;
            });
    }

    onAuthorCreated(author) {
        this.authors.push(author);
    }

}

export default {
    template: `
        <div class="panel panel-default">
            <div class="panel-body">
                <hierarchy-filter inline="true" strict-responsible="true" filter-change="$ctrl.onHierarchyChanged(filter)"></hierarchy-filter>
                <hr />
                <span ng-if="!$ctrl.selectedModule" class="text-muted no-result col-xs-12 qs-no-module">Please select a module</span>
                <div ng-if="$ctrl.selectedModule">
                    <div class="row">
                        <div class="col-lg-6">
                            <invite-author-by-email module-id="$ctrl.selectedModule" author-created="$ctrl.onAuthorCreated(author)"></invite-author-by-email>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-lg-12">
                            <authors-grid data="$ctrl.authors"></authors-grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    controller: InvitationsPanelController,
};
