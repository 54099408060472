/* eslint-disable class-methods-use-this */
export default class TranslateServiceStub {
    proposedLanguage() {
        return 'none';
    }

    instant() {
        throw new Error('No $translate configured');
    }
}
