import React, { useEffect } from 'react';
import Panel from '../../../../components/Panel';
import useExport from '../../hooks/useExport';
import ExportButton from '../ExportButton';

import '../medialink-export-tab.scss';

type FileExportTabProps = {
    moduleId: string;
};

export default function FileExportTab({ moduleId }: FileExportTabProps): JSX.Element {
    const {
        exportState, generateExport, isExportPending, getExportState,
    } = useExport('files', moduleId);

    useEffect(() => {
        getExportState();
    }, [getExportState]);

    return (
        <div className="medialink-export-tab row">
            <Panel>
                <Panel.Heading title="Export media link files from module" />
                {
                    exportState && (
                        <Panel.Body>
                            <div className="mb-15">
                                <ExportButton
                                    onClick={generateExport}
                                    disabled={isExportPending(exportState)}
                                />
                            </div>

                            {exportState.status === 'done' && (
                                <div className="mb-15">
                                    <a
                                        href={exportState.downloadUrl}
                                        data-testid="download-export-button"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {exportState.name}
                                    </a>
                                </div>
                            )}

                            {exportState.status === 'failed' && (
                                <div className="alert alert-danger" role="alert">
                                    Failed to export media links. Please try again.
                                </div>
                            )}
                        </Panel.Body>
                    )
                }
            </Panel>
        </div>
    );
}
