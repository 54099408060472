import './marketing-tools.scss';

class ShellMarketingToolsController {

    // @ngInject
    constructor($scope) {
        this.$scope = $scope;
    }

    $onInit() {
        this.activeTab = 'notifications';
    }
}

export default {
    template: `
        <div class="row">
            <div class="col-lg-12">
                <ul class="nav nav-tabs">
                    <li id="tab-notifications" role="presentation" ng-class="{ 'active' : $ctrl.activeTab === 'notifications' }"  ng-click="$ctrl.activeTab = 'notifications'"><a>Marketing notification</a></li>
                </ul>
            </div>

            <div class="col-lg-12 qs-notifications-container" ng-show="$ctrl.activeTab === 'notifications'">
                <notifications-panel></notifications-panel>
            </div>

        </div>
    `,
    controller: ShellMarketingToolsController,
};
