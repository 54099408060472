import angular from 'angular';
import component from './statistics.view';
import subComponents from './components';

const module = angular.module('app.shell.modules.statistics', [
    subComponents,
]);

module
    .config($stateProvider => {
        // @ngInject
        $stateProvider.state('statistics', {
            url: '/statistics',
            template: `
                <shell-statistics></shell-statistics>
            `,
            security: (permissionService) => permissionService.canManageStatistics,
        });
    })
    .component('shellStatistics', component);

export default module.name;
