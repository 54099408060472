import angular from 'angular';
import component from './users.view';
import userSearchCriteria from './components/user-search-criteria';
import userGrid from './components/user-grid';

const module = angular.module('app.shell.modules.users', []);

module
    .config(($stateProvider) => {
        // @ngInject
        $stateProvider
            .state('users', {
                url: '/users',
                title: 'Pakket-p SHELL users',
                template: `
                    <shell-users></shell-users>
                `,
                security: permissionService => permissionService.canManageUsers,
            });
    })
    .component('shellUsers', component)
    .component('userGrid', userGrid)
    .component('userSearchCriteria', userSearchCriteria);

export default module.name;
