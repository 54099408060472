function cleanRequest(req) {
    if (req.data.styling) {
        if (req.data.styling.primaryColor === '') delete req.data.styling.primaryColor;
        if (req.data.styling.secundaryColor === '') delete req.data.styling.secundaryColor;
    }

    if (req.data.id) delete req.data.id;
    if (!req.data.releaseDate) delete req.data.releaseDate;
    if (!req.data.externalContentUrl) delete req.data.externalContentUrl;
    if (!req.data.alternativeStudentSiteUrl) delete req.data.alternativeStudentSiteUrl;
    if (req.data.bookType === '') delete req.data.bookType;
}

/* @ngInject */
export default function ModuleModelFactory($modelFactory) {
    const model = $modelFactory('shell/modules', {
        actions: {
            query: {
                afterRequest: response => response.data,
            },
            update: {
                beforeRequest: req => cleanRequest(req),
            },
        },

        $getPath: function (moduleId) { // eslint-disable-line object-shorthand
            return model.$call({
                method: 'GET',
                url: `/api/shell/modules/${moduleId}/path`,
            });
        },
    });

    return model;
}
