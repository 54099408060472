export default {
    template: `<div class="klopotek-import__wrapper">

     <div class="row">
        <div class='page-header'>
            <h2>Klopotek Import</h2>
        </div>
    </div>
    <div class="row">

    <div class="col-md-5">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3 class="panel-title">Import History</h3>
            </div>
            <div class="panel-body import-history">
                <import-history selected-item="item"></import-history>
            </div>
        </div>
    </div>
    <div class="col-md-7" ng-show="item && item.metaData">
        <div class="panel panel-default">
            <div class="panel-heading details-header">
                <h3 class="panel-title pull-left">Detail</h3>
                <span class="glyphicon glyphicon-remove-circle pull-right" ng-click="item = null" role="button"></span>
            </div>
            <div class="panel-body import-details">
                <import-details selected-item="item"></import-details>
            </div>
        </div>
    </div>
</div>

    `,
};
