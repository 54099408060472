/* @ngInject */
export default function AppTypeModelFactory($modelFactory) {
    return $modelFactory('shell/app-types', {
        actions: {
            query: {
                afterRequest: response => response.data,
            },
        },
    });
}
