// @ngInject
module.exports = ($window) => {
    return (scope, element, attrs) => {
        const windows = angular.element($window);

        windows.bind('scroll', () => {
            const buttons = angular.element(element[0].querySelector(`.${attrs.elementClass}`));
            const className = attrs.offsetReachedClass || '';
            const offset = attrs.offset || 0;

            element[0].getBoundingClientRect().top <= offset ? buttons.addClass(className) : buttons.removeClass(className);
            scope.$apply();
        });

        scope.$on('$destroy', () => {
            windows.unbind('scroll');
        });
    };
};
