import './square-box.component.scss';

export default {
    template: `<div class="square-box-component" ng-class="$ctrl.componentClass || 'square-box-component--default'" ng-style="{'background-color': $ctrl.backgroundColor || '#d4d4d4'}">
    <div class="square-box__content">
        <div><span ng-bind="$ctrl.caption || '___'"></span></div>
    </div>
</div>
 `,
    bindings: {
        backgroundColor: '<',
        caption: '<',
        componentClass: '@?',
    },
};
