'use strict';

const Role = require('./role');
const roleByWeight = require('./role-by-weight');

class HighestRoleExtractor {

    /**
     * Extracts the highest role out of an array of roles.
     * @static
     * @param {String[]} roles
     * @returns
     *
     * @memberof HighestRoleExtractor
     */
    static extract(roles) {
        const distinctRoles = new Set([Role.STUDENT]);

        if (roles) {
            if (roles instanceof Array) roles.forEach(role => distinctRoles.add(role));
            else distinctRoles.add(roles);
        }

        return [...distinctRoles]
            .sort(roleByWeight)
            .slice(-1)[0];
    }
}

module.exports = HighestRoleExtractor;
