import get from 'lodash/get';
import set from 'lodash/set';

/* @ngInject */
export default function UserModelFactory($modelFactory) {
    const model = $modelFactory('shell/users', {
        actions: {
            query: {
                afterRequest: (response) => {
                    const users = response.data;
                    users.total = response.total;
                    return users;
                },
            },
            queryEditors: {
                method: 'GET',
                url: 'editors',
                wrap: true,
                isArray: true,
                afterRequest: (response) => {
                    const users = response.data;
                    return users;
                },
            },
            queryExternals: {
                method: 'GET',
                url: 'externals',
                wrap: true,
                isArray: true,
                afterRequest: (response) => {
                    const users = response.data;
                    return users;
                },
            },
        },
        instance: {
            $patch: function () { // eslint-disable-line object-shorthand
                const changedPaths = [...new Set((this.$diff() || []).map(d => d.path.join('.'))
                    .map(path => (path.indexOf('roles.') > -1 ? 'roles' : path)))];

                if (changedPaths.length === 0) return Promise.resolve(this);
                return model.$call({
                    method: 'PATCH',
                    url: `/api/shell/users/${this.id}`,
                    data: this,
                    invalidateCache: true,
                    wrap: true,
                    beforeRequest: function (req) { // eslint-disable-line object-shorthand
                        const data = {};

                        changedPaths.forEach(p => {
                            set(data, p, get(req.data, p));
                        });
                        req.data = data;
                    },
                });
            },
        },
    });

    return model;
}
