import './app-library.scss';
import appItemSettingsTemplate from './app-item-settings.html';

class AppLibrary {
    // @ngInject
    constructor(AppModel, AppTypeModel, LicenseProductModel, WordListModel, appPublishingStates, platforms, ngDialog, $scope, $q, DigibookModel, toaster, linkedAppType, MediaLinkModel) {
        this.DigibookModel = DigibookModel;
        this.WordListModel = WordListModel;
        this.MediaLinkModel = MediaLinkModel;
        this.ngDialog = ngDialog;
        this.$scope = $scope;
        this.AppModel = AppModel;
        this.AppTypeModel = AppTypeModel;
        this.LicenseProductModel = LicenseProductModel;
        this.appPublishingStates = appPublishingStates;
        this.platforms = platforms;
        this.$q = $q;
        this.toaster = toaster;
        this.linkedAppType = linkedAppType;
    }

    $onInit() {
        this.linkableApps = [];
        this.apps = [];

        return this.$q.all([
            this.getAppTypes(),
            this.getLicenseProducts(),
        ]);
    }

    onFilterChange(filter) {
        this.filter = filter;
        this.linkableApps = [];

        if (this.filter.module) {
            return this.$q.all([
                this.getApps(this.filter.module),
                this.getWordLists(this.filter.module),
                this.getDigibooks(this.filter.module),
                this.getMedialinks(this.filter.module),
            ]);
        }
    }

    openDetails(app) {
        this.selectedApp = app;

        const promise = app ? this.AppModel.get(app.id) : this.$q.resolve(
            new this.AppModel(
                {
                    name: '',
                    state: 'draft',
                    target: 'student',
                    type: {},
                    moduleId: this.filter.module,
                    moduleSpecific: true,
                    ownedByPelckmans: true,
                    platformType: this.platforms.WEB_INTERN,
                    platformUrl: '',
                    weight: 0,
                }
            )
        );

        return promise
            .then(resolvedApp => {
                this.currentApp = resolvedApp;
                const currentLinkedApp = resolvedApp && resolvedApp.linkedApp && resolvedApp.linkedApp.id;
                this.linkableAppsForCurrent = this.linkableApps.filter(x => !x.isLinkedToApp || x.id === currentLinkedApp);
                if (app) {
                    app.$update(resolvedApp);
                    app.$commit();
                }
            })
            .then(() => {
                this.ngDialog.open({
                    template: appItemSettingsTemplate,
                    plain: true,
                    scope: this.$scope, // we need this so we can access the function of our Home controller
                    className: 'ngdialog-theme-default ng-dialog__custom-width',
                });
            });
    }

    saveMethod() {
        if (this.currentApp.platformType === this.platforms.P_INTERN) {
            delete this.currentApp.platformUrl;
        }
        this.currentApp.$save()
            .then(app => {
                const index = this.apps.findIndex(a => a.id === app.id);

                if (index < 0) {
                    this.apps.push(app);
                } else {
                    this.apps[index] = app;
                }

                this.getDigibooks(this.filter.module);
            })
            .then(() => this.ngDialog.close())
            .catch(e => {
                const { data: { message, statusCode } } = e;
                if (statusCode === 409) this.toaster.pop('error', message);
                else throw e;
            });
    }

    getApps(moduleId) {
        return this.AppModel
            .query({ moduleId })
            .then(apps => {
                this.apps = apps;
            });
    }

    getAppTypes() {
        return this.AppTypeModel
            .query()
            .then(appTypes => {
                this.appTypes = appTypes;
            });
    }

    getLicenseProducts() {
        return this.LicenseProductModel
            .query()
            .then(licenseProducts => {
                this.licenseProducts = licenseProducts;
            });
    }

    getDigibooks(moduleId) {
        this.linkableApps = this.linkableApps.filter(app => app.type !== this.linkedAppType.DIGIBOOK);

        return this.DigibookModel
            .query({ moduleId, onlyReadyForReviewPublishing: true })
            .then(digibooks => {
                digibooks.forEach(db => this.linkableApps.push({
                    type: this.linkedAppType.DIGIBOOK,
                    id: db.id,
                    name: db.name,
                    isLinkedToApp: db.isLinkedToApp,
                }));
            });
    }

    getWordLists(moduleId) {
        this.linkableApps = this.linkableApps.filter(app => app.type !== this.linkedAppType.WORDLIST);

        return this.WordListModel
            .$getList(moduleId, 'general-only')
            .then(wordLists => {
                wordLists.map(wl => this.linkableApps.push({
                    type: this.linkedAppType.WORDLIST,
                    id: wl.id,
                    name: wl.name,
                }));
            });
    }

    getMedialinks(moduleId) {
        this.linkableApps = this.linkableApps.filter(app => app.type !== this.linkedAppType.MEDIALINK);

        return this.MediaLinkModel
            .getByModule({ moduleId, onlyAppCandidates: true })
            .then(mediaLinks => {
                mediaLinks.data.map(ml => this.linkableApps.push({
                    type: this.linkedAppType.MEDIALINK,
                    id: ml.id,
                    name: ml.name,
                }));
            });
    }

    onLinkedAppChanged() {
        // the select changed, but it only changes the id on the this.currentApp.linkedApp
        // therefore we need to change the type here
        const newLinkedApp = this.linkableApps.find(a => a.id === this.currentApp.linkedApp.id);
        if (newLinkedApp) {
            this.currentApp.linkedApp.type = newLinkedApp.type;
        }
    }

    onAppDeleted() {
        return this.getDigibooks(this.filter.module);
    }
}

export default {
    template: `<div class="app-library-view">
    <div class="row">
        <div class='page-header'>
            <h2>App library</h2>
        </div>
    </div>
    <div class="row">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="col-sm-3">
                   <h3>Filter Apps</h3>
                   <hierarchy-filter
                        filter-change="$ctrl.onFilterChange(filter)">
                    </hierarchy-filter>
                </div>

                <div class="col-xs-9">
                    <div class="row">
                        <h3 class="col-sm-6">Apps</h3>
                        <div class="col-sm-6">
                            <button
                                ng-disabled="!$ctrl.filter.module"
                                ng-click="$ctrl.openDetails()"
                                id="add-app"
                                class="btn btn-success">
                                    <span class="glyphicon glyphicon-plus-sign"></span> Add a new app</button>
                        </div>
                    </div>
                    <div class="row">
                        <shell-app-list app-list="$ctrl.apps"
                                        edit-app="$ctrl.openDetails(app)"
                                        selected-module="$ctrl.filter.module"
                                        class="shell-app-list"
                                        on-app-deleted="$ctrl.onAppDeleted()">
                        </shell-app-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `,
    controller: AppLibrary,
};
