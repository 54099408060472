class ImportDetailsController {

    $onChanges(context) {
        const selectedItem = context.selectedItem.currentValue;

        if (selectedItem) {

            if (selectedItem.error === 'InconsistentDatabase') {
                this.details = angular.fromJson(selectedItem.metaData);
                return;
            }

            this.details = [selectedItem.metaData];
        }
    }
}

export default {
    template: `
    <div class="import-details-container">
        <div id="import-detail__item-{{key}}" class="row import-detail__item"  ng-repeat="(key, item) in $ctrl.details track by $index">
            {{ item }}
        </div>
    </div>
    `,
    controller: ImportDetailsController,
    bindings: {
        selectedItem: '<',
    },
};
