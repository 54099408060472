import './authors.scss';
import Role from '../../../../core/security/role';

class ShellAuthorsController {

    // @ngInject
    constructor($scope) {
        this.$scope = $scope;
    }

    $onInit() {
        this.activeTab = 'invitations';

        this.$scope.$watch('$root.identity', identity => {
            this.hideAllAuthorsTab = identity && identity.highestRole === Role.EDITOR;
        });
    }
}

export default {
    template: `
        <div class="row">
            <div class="col-lg-12">
                <ul class="nav nav-tabs">
                    <li id="tab-invitations" role="presentation" ng-class="{ 'active' : $ctrl.activeTab === 'invitations' }"  ng-click="$ctrl.activeTab = 'invitations'"><a>Invitations</a></li>
                    <li id="tab-all-authors" role="presentation" ng-if="!$ctrl.hideAllAuthorsTab" ng-class="{ 'active' : $ctrl.activeTab === 'authors' }"  ng-click="$ctrl.activeTab = 'authors'"><a>All authors</a></li>
                </ul>
            </div>

            <div class="col-lg-12 qs-invitations-container" ng-show="$ctrl.activeTab === 'invitations'">
                <invitations-panel></invitations-panel>
            </div>
            <div class="col-lg-12 qs-all-authors-container" ng-if="$ctrl.activeTab === 'authors'">
                <all-authors-panel></all-authors-panel>
            </div>
        </div>
    `,
    controller: ShellAuthorsController,
};
