import slug from '../../../../../core/slug';
import methodSettingsTemplate from '../method-settings.html';

class MethodsController {
    // @ngInject
    constructor(MethodModel, $scope, ngDialog, states, toaster, educationalTypes, lessonDisplays) {
        this.MethodModel = MethodModel;
        this.$scope = $scope;
        this.ngDialog = ngDialog;
        this.states = states;
        this.methods = [];
        this.toaster = toaster;
        this.educationalTypes = educationalTypes;
        this.lessonDisplays = lessonDisplays;
    }

    $onInit() {
        this.$scope.$watch('$ctrl.selectedSubject', value => {
            if (!value) return false;
            this.getMethods();
        });
    }

    getMethods() {
        const query = {
            subjectId: this.selectedSubject.id,
            orderBy: 'name',
        };

        return this.MethodModel
            .query(query)
            .then(methods => {
                this.methods = methods;
                this.methodsLoaded({ methods });
            });
    }

    onSubjectChanged() {
        // it also gets new methods from inside the watch
        this.subjectChanged({ subject: this.selectedSubject });
    }

    onMethodClicked(method) {
        this.selectedMethod = method;
        this.methodChanged({ method });
    }

    openDetails(method) {
        // keep reference to subject that we want to edit so we can update this subject after save with the new value
        this.methodToEdit = method;

        // if no method, create new with default state to draft and default subject
        // otherwise take copy so our view does not gets updated automatically
        this.currentMethod = method ? method.$copy() : new this.MethodModel({
            publishingState: 'draft',
            lessonDisplay: this.lessonDisplays.NEUTRAL,
            subject: this.selectedSubject ? this.selectedSubject.id : null,
            weight: 0,
        });

        return this.ngDialog.open({
            template: methodSettingsTemplate,
            plain: true,
            scope: this.$scope,
            className: 'ngdialog-theme-default ng-dialog__custom-width method-settings__modal',
            preCloseCallback: (value) => {
                if (value) {
                    this.currentMethod = null;
                }
            },
        });
    }

    createSlug(method) {
        if (method && method.name && !method.slug) {
            method.slug = slug(method.name);
        }
    }

    saveMethod(valid) {
        if (!valid) return;

        let index;
        if (this.currentMethod.$diff()) {
            this.currentMethod.$diff().forEach(el => {
                if (el.path[0] === 'subject') {
                    this.methods.forEach((val, key) => {
                        if (val.id === this.currentMethod.id) index = key;
                    });
                }
            });
        }

        const isNew = angular.isUndefined(this.currentMethod.id);
        return this.currentMethod.$save()
            .then(method => {
                if (isNew) {
                    this.methods.push(method);
                } else {
                    this.methodToEdit.$update(method);
                    this.methodToEdit.$commit();
                    if (index !== undefined && this.selectedSubject) {
                        this.methods.splice(index, 1);
                    }
                }

                return this.ngDialog.close();
            });
    }

    deleteMethod(method) {
        return method.$destroy()
            .then((meth) => {
                const index = this.methods.map((x) => x.id).indexOf(meth.id);
                if (index > -1) this.methods.splice(index, 1);
                return this.ngDialog.close();
            })
            .catch(err => {
                if (err.status === 409) {
                    this.toaster.pop('error', err.data.message);
                }
            });
    }
}

export default {
    template: `<div class="panel panel-default" ng-show="$ctrl.selectedSubject">
    <div class="panel-heading">
        <div class="row">
            <h3 class="panel-title col-sm-4 subjects-methods-component__title">Methods</h3>
            <div class="col-sm-8">
                <select id="selectedSubjectId"
                        class="form-control form-inline subjects-methods-component__filter qs-subject-filter"
                        ng-model="$ctrl.selectedSubject"
                        ng-options="subject.name for subject in $ctrl.subjects track by subject.id"
                        ng-change="$ctrl.onSubjectChanged()">
                </select>
            </div>
        </div>
    </div>
    <div class="panel-body method-list">
        <div class="row method-list__no-result qs-method-list__no-result" ng-if="!$ctrl.methods || $ctrl.methods.length === 0">
            <span class="text-muted">No matching methods found</span>
        </div>
        <div class="row method-list__item qs-method-list__item"
             ng-repeat="(key, method) in $ctrl.methods"
             ng-class="{'method-list__item--active': $ctrl.selectedMethod.id === method.id}"
        >
            <div class="col-xs-10 method-item__info method-item__col"
                 ng-click="$ctrl.onMethodClicked(method)"
            >
                <div class="col-xs-7 qs-method-item__name">{{method.name}}</div>
                <div class="col-xs-5 qs-method-item__publishingstate">
                    <publishing-state-component class="pull-right"
                                                publishing-state="method.publishingState"></publishing-state-component>
                </div>
            </div>
            <div class="col-xs-1 method-item__col">
            <span class="label label-info method_weight">
                {{method.weight}}
                </span>
            </div>
            <div class="col-xs-1 pull-right method-list__actions method-item__col">
                <span class="glyphicon glyphicon-cog subjects-methods__action--edit qs-method__edit"
                      ng-click="$ctrl.openDetails(method)"></span>
            </div>
        </div>
    </div>
    <div class="panel-footer clearfix">
        <button class="btn btn-success col-xs-12 qs-method__manually" ng-click="$ctrl.openDetails()"><i
            class="glyphicon glyphicon-plus"></i> Add method manually
        </button>

        <p class="text-muted small subjects-methods-footer__note">
            NOTE: Please try to use the klopotek import function before adding a method manually
        </p>
    </div>
</div>
    `,
    bindings: {
        methodChanged: '&',
        methodsLoaded: '&',
        subjects: '<',
        selectedMethod: '<',
        selectedSubject: '<',
        subjectChanged: '&',
    },
    controller: MethodsController,
};
