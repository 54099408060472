class FaqCategoryManager {
    // @ngInject
    constructor($scope, ngDialog, FAQCategoryModel, toaster, $q) {
        this.$scope = $scope;
        this.ngDialog = ngDialog;
        this.FAQCategoryModel = FAQCategoryModel;
        this.toaster = toaster;
        this.$q = $q;

        this.tableOptions = {
            beforeDrop: category => {
                const categories = angular.copy(this.categories);

                const destIsHigherThanSource = category.dest.index > category.source.index;
                const droppedOnIndex = categories[category.dest.index];

                const rank = {
                    before: destIsHigherThanSource ? categories[category.dest.index + 1] : droppedOnIndex,
                    after: destIsHigherThanSource ? droppedOnIndex : categories[category.dest.index - 1],
                };

                if (category.dest.index === 0) {
                    delete rank.after;
                }

                if (category.dest.index === categories.length - 1) {
                    delete rank.before;
                }

                const categoryToRank = this.categories[category.source.index];
                return categoryToRank.$rank(rank.before ? rank.before.id : undefined, rank.after ? rank.after.id : undefined)
                    .catch(err => {
                        if (err.status === 404) {
                            this.toaster.pop('warning', 'Oops, something went wrong', 'Someone removed the category you are trying to drag');
                            return this.$q.resolve(false);
                        }

                        if (err.status === 409) {
                            this.toaster.pop('warning', 'Oops, something went wrong', 'Invalid state detected, try refreshing your page');
                            return this.$q.resolve(false);
                        }
                        return this.$q.reject(err);
                    });
            },
        };
    }

    toggleEdit(category) {
        category.editable = !category.editable;
    }

    save(category) {
        return category
            .$save()
            .then(cat => {
                category.editable = false;
                category.$update(cat);
                category.$commit();
            });
    }

    cancel(category) {
        if (!category.id) category.$destroy();
        else category.$rollback();
    }

    addCategory() {
        this.categories.push(new this.FAQCategoryModel({
            group: this.studioGroup,
            editable: true,
            used: 0,
        }));
    }

    deleteCategory(category) {
        return category
            .$destroy()
            .catch(err => {
                if (err.status === 400) {
                    return this.toaster.pop('warning', 'Oops, something went wrong', 'The category you are trying to delete has related FAQ\'s/Tutorials');
                }

                return this.$q.reject(err);
            });
    }


}

export default {
    template: `
    <div class="panel panel-default" pt-faq-category-manager ui-tree="$ctrl.tableOptions">
    <div class="panel-heading">

        <p class="panel-title panel-category-title">Manage Categories</p>

        <div class="pull-right">
            <button type="button" class="btn btn-sm btn-success" pt-category__add ng-click="$ctrl.addCategory()"><span
                    class="glyphicon glyphicon-plus-sign"></span> Add a new category
            </button>
            <div class="clearfix"></div>
        </div>
    </div>

    <div class="panel-body" ui-tree-nodes ng-model="$ctrl.categories">
        <table class="table table-condensed categories__table table-bordered">
            <thead>
                <tr>
                    <th class="fit"></th>
                    <th class="fit text-center">#</th>
                    <th class="col-xs-5">NL Label</th>
                    <th class="col-xs-5">FR Label</th>
                    <th class="col-xs-2 text-right">Actions</th>
                </tr>
                <thead/>
                <tbody>
                    <tr ng-if="!$ctrl.categories || $ctrl.categories.length === 0">
                        <td colspan=6 class="categories__no-result text-muted">No results found</td>
                    </tr>
                    <tr ng-repeat="category in $ctrl.categories" ng-form="editForm" ng-class="{ 'tr-edit': category.editable }" pt-category__item ui-tree-node ng-attr-id="{{category.id}}">
                        <td ng-attr-ui-tree-handle>
                            <span class="glyphicon glyphicon-option-vertical"></span>
                        </td>
                        <td class="text-center">
                            <span class="label label-warning" pt-category__usage>{{category.used}}</span>
                        </td>
                        <td ng-class="{ 'has-error': category.editable && editForm.name.$invalid }">
                            <p class="form-control-static" ng-if="!category.editable" ng-bind="category.name" pt-category__name></p>
                            <input ng-if="category.editable" type="text" class="form-control" name="name" ng-required="true" pt-category__name minlength="1" ng-model="category.name"
                                focus-if ng-keydown="($event.which === 27)? $ctrl.cancel(category) : ($event.which === 13) ? editForm.$valid && $ctrl.save(category) : 0"
                            />
                        </td>
                        <td ng-class="{ 'has-error': category.editable && editForm.frenchName.$invalid }">
                            <p class="form-control-static" ng-if="!category.editable" ng-bind="category.frenchName" pt-category__french_name></p>
                            <input ng-if="category.editable" type="text" class="form-control" pt-category__french_name name="frenchName" ng-required="true" minlength="1" ng-model="category.frenchName"
                                ng-keydown="($event.which === 27)? $ctrl.cancel(category) : ($event.which === 13) ? editForm.$valid && $ctrl.save(category) : 0"
                            />
                        </td>
                        <td class="text-right">
                            <button ng-if="!category.editable" type="button" class="btn btn-default btn-xs" pt-category__edit title="Edit" ng-click="category.editable = true">
                                <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                            </button>
                            <button ng-if="category.editable" type="submit" class="btn btn-primary btn-xs" pt-category__save title="Save" ng-click="editForm.$valid && $ctrl.save(category)">
                                <span class="glyphicon glyphicon-ok" aria-hidden="true"></span>
                            </button>
                            <button ng-if="!category.editable" type="button" class="btn btn-danger btn-xs" pt-category__delete title="Delete" ng-click-confirmed
                                confirmed="$ctrl.deleteCategory(category)"
                                confirmation-title='Delete Category' confirmation-message="Are you sure you want to delete '{{ category.name }}'?">
                                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                            </button>
                            <button ng-if="category.editable" type="button" class="btn btn-default btn-xs" pt-category__cancel title="Cancel" ng-click="$ctrl.cancel(category)">
                                <span class="glyphicon glyphicon-remove"></span>
                           </button>
                        </td>
                    </tr>
                </tbody>
        </table>
    </div>
</div>
    `,
    controller: FaqCategoryManager,
    bindings: {
        studioGroup: '<',
        categories: '<',
    },
};
