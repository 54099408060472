const Role = require('./role');

module.exports = (a, b) => {
    const rolesInOrder = [
        Role.STUDENT,
        Role.TEACHER,
        Role.EXTERNAL,
        Role.PUBLISHER,
        Role.EDITOR,
        Role.SALES,
        Role.PRODUCTION,
        Role.SUPPORT,
        Role.SYSADMIN,
    ];

    const indexOfA = rolesInOrder.indexOf(a);
    const indexOfB = rolesInOrder.indexOf(b);

    if (indexOfA < indexOfB) return -1;
    if (indexOfA > indexOfB) return 1;
    return 0;
};
