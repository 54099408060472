class AboutManager {
    // @ngInject
    constructor($scope, AboutModel, $q) {
        this.$scope = $scope;
        this.AboutModel = AboutModel;
        this.$q = $q;
    }

    $onChanges() {
        this.editable = false;

        return this.AboutModel.query({ group: this.studioGroup })
            .then(aboutItems => {
                this.aboutItems = aboutItems;
                this.selectedUserTarget = 'student';
                this.onUserTargetChanged();
            });
    }

    onUserTargetChanged() {
        const cancelEdit = this.editable ? this.cancel() : this.$q.resolve();

        return cancelEdit
            .then(() => {
                this.currentAbout = this.aboutItems.filter(item => item.userTarget === this.selectedUserTarget)[0];
            });
    }

    saveAbout() {
        return this.currentAbout.$patch()
            .then(aboutItem => {
                this.currentAbout.$update(aboutItem);
                this.currentAbout.$commit();
                this.editable = false;
            });
    }

    cancel() {
        return new this.$q(resolve => {
            this.currentAbout.$revert();
            this.editable = false;
            return resolve();
        });
    }
}

export default {
    template: `
    <form name="aboutSettingsForm" about-settings-form class="form-horizontal about-manager"
    novalidate>
    <div class="panel panel-default">
        <div class="panel-heading">
            <p class="panel-title panel-category-title">Manage about items</p>
            <div class="pull-right">
                <button type="button" pt-about-edit ng-if="!$ctrl.editable" class="btn btn-sm btn-success" ng-click="$ctrl.editable = true;">
                    <span class="glyphicon glyphicon-pencil"></span> Edit
                </button>
                <button ng-if="$ctrl.editable" type="submit" pt-about-save class="btn btn-primary btn-xs" title="Save" ng-click="aboutSettingsForm.$valid && $ctrl.saveAbout()">
                    <span class="glyphicon glyphicon-ok" aria-hidden="true"></span>
                </button>
                <button ng-if="$ctrl.editable" type="button" pt-about-cancel class="btn btn-default btn-xs" title="Cancel" ng-click="$ctrl.cancel()">
                    <span class="glyphicon glyphicon-remove"></span>
                </button>
                <select id="aboutUserTarget" ng-change="$ctrl.onUserTargetChanged()" ng-model="$ctrl.selectedUserTarget" name="aboutUserTarget" class="form-control input-sm pull-right">
                    <option value="student">Students</option>
                    <option value="teacher">Teachers</option>
                </select>
                <div class="clearfix"></div>
            </div>
        </div>

        <div class="panel-body">
            <span class="label label-default">NL</span>
            <br/>
            <br/>
            <div class="form-group">
                <div class="col-sm-12 pull-right">
                    <div id="aboutContent" name="aboutContent" markdown-wysiwyg ng-model="$ctrl.currentAbout.content" editable="$ctrl.editable"></div>
                </div>
            </div>
            <hr>
            <span class="label label-default">FR</span>
            <br/>
            <br/>
            <div class="form-group">
                <div class="col-sm-12 pull-right">
                    <div id="aboutFrenchContent" name="aboutFrenchContent" markdown-wysiwyg ng-model="$ctrl.currentAbout.frenchContent" editable="$ctrl.editable"></div>
                </div>
            </div>
        </div>
    </div>
    </form>
    `,
    controller: AboutManager,
    bindings: {
        studioGroup: '<',
    },
};
