import each from 'lodash/each';
import MaintenanceError from '../maintenance-error';

// @ngInject
export function httpErrorInterceptor($log, $q, toaster) {
    return {
        responseError: (response) => {
            const softTitle = 'Oops, Something went wrong';
            const hardTitle = 'Whaw, unexpected response';

            $log.info(`Response received with HTTP status code: ${response.status}`);

            if (response.status <= 0) {
                toaster.pop('warning', softTitle, 'The connection to the server is lost.');
            } else if (response.status === 400) {
                $log.info(response);
                let msg = '';

                if (response.data.message === 'One or more validation failed') {
                    let count = response.data.details.length;
                    each(response.data.details, (error) => {
                        let message = `${error.key} ${error.message}`;

                        if (count > 1) {
                            message += ', ';
                            count -= 1;
                        }

                        msg += message;

                    });

                    toaster.pop('warning', response.data.message, msg);
                }
            } else if (response.status > 400 && response.status < 500) {
                let message;

                message = `The request was invalid or cannot be served [ ${response.status} ]`;

                if (response.status === 409) {
                    message = 'The request could not be completed, target resource has related resources.';
                }

                toaster.pop('warning', softTitle, message);
            } else if (response.status >= 500) {
                toaster.pop('error', hardTitle, `Something is broken at the server [ ${response.status} ]`);
            }
        },
    };
}


// @ngInject
export function maintenanceInterceptor($q) {
    return {
        responseError: response => {
            if (response.status === 503) return $q.reject(new MaintenanceError());

            return $q.reject(response);
        },
    };
}

// @ngInject
export function apiInterceptor(config) {
    return {
        request: cnfg => {
            // pass trough urls which were set via $sce trustAsResourceUrl since they are always external.
            if (cnfg.url.$$unwrapTrustedValue) return cnfg;

            if (cnfg.url.indexOf('http') === 0) return cnfg;
            if (cnfg.url.indexOf('api') < 0) return cnfg;

            if (cnfg.url.indexOf('/') !== 0) cnfg.url = `/${cnfg.url}`;

            if (cnfg.url.indexOf('/api') === 0) cnfg.url = cnfg.url.slice(4);

            cnfg.url = config.baseUrl + cnfg.url;

            return cnfg;
        },
    };
}
