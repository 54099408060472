import Role from '../../../../../core/security/role';
import Identity from '../../../../../core/security/identity';
import roleByWeight from '../../../../../core/security/role-by-weight';
import roleSettingsTemplate from '../role-settings.html';

class UserGrid {

    // @ngInject
    constructor($scope, ngDialog, authService) {
        this.$scope = $scope;
        this.ngDialog = ngDialog;
        this.authService = authService;
    }

    $onInit() {
        this.$scope.$watch('$root.identity', identity => {
            this.currentIdentity = identity || Identity.Anonymous;
        });
    }

    canManageRole(role) {
        return roleByWeight(role, this.currentIdentity.highestRole) <= 0;
    }

    editRole(user) {
        return this.ngDialog
            .open({
                template: roleSettingsTemplate,
                plain: true,
                scope: this.$scope.$new(),
                // @ngInject
                controller: $scope => {
                    $scope.user = user.$copy();
                    $scope.availableRoles = [];

                    $scope.availableRoles = Object.values(Role).map(role => ({
                        value: role,
                        disabled: ((role === Role.STUDENT) || (role === Role.TEACHER)) || !this.canManageRole(role),
                    }));

                    $scope.updateUser = () => $scope.user.$patch()
                        .then(updatedUser => {
                            user.$update(updatedUser);
                            user.$commit();
                            return $scope.closeThisDialog();
                        });

                    $scope.initUserSelect = () => {
                        // we want to set the role to an empty string when student/teacher so our select functions correctly
                        $scope.user.role = ($scope.user.role === Role.STUDENT || $scope.user.role === Role.TEACHER) ? '' : $scope.user.role;
                    };
                },
                className: 'ngdialog-theme-default user-role__dialog',
            });
    }

}

export default {
    template: `
        <div class="table-responsive">
            <table class="users__table table table-condensed table-bordered">
                <thead>
                    <tr>
                        <th>MP Id</th>
                        <th>First name</th>
                        <th>Last name</th>
                        <th>Email</th>
                        <th>First login</th>
                        <th>Latest login</th>
                        <th>Role</th>
                        <th>Provider</th>
                        <th class="text-center col-fit">Edit role</th>
                    </tr>
                </thead>
                <tbody>
                    <tr ng-if="!$ctrl.users || $ctrl.users.length === 0">
                        <td colspan="9" class="no-result text-muted text-center">∞</td>
                    </tr>
                    <tr ng-repeat="user in $ctrl.users" class="user-list__user user-info">
                        <td class="col-fit">
                            <button type="button" class="btn btn-default btn-xs qs-action--impersonate"
                                title="Impersonate"
                                ng-click="$ctrl.authService.impersonate(user.pelckmansId)"
                            >
                                🎭
                            </button>
                            {{user.pelckmansId}}
                        </td>
                        <td ng-bind="user.firstName"></td>
                        <td ng-bind="user.lastName"></td>
                        <td ng-bind="user.primaryEmail"></td>
                        <td class="col-fit">{{ user.firstLoginAt | date:'dd-MM-yyyy' }}</td>
                        <td>{{user.lastLoginAt | timeFromNow }}</td>
                        <td class="role" ng-bind="user.role"></td>
                        <td class="provider" ng-bind="user.provider"></td>
                        <td class="text-center">
                            <button type="button" class="btn btn-default btn-xs qs-action--change-role" title="Change Role"
                                ng-click="$ctrl.editRole(user)"
                                ng-disabled="!$ctrl.canManageRole(user.role)">
                                <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    `,
    controller: UserGrid,
    bindings: {
        users: '<',
    },
};
