import React, { useState, useEffect } from 'react';
import {
    string,
    bool,
    node,
} from 'prop-types';

import './CollapsePanel.scss';
import Panel from './Panel';
import GlyphIcon from './GlyphIcon';

function CollapsePanel({
    'data-testid': dataTestId,
    defaultCollapsed,
    endAdornment,
    title,
    children,
}) {
    const [collapsed, setCollapsed] = useState(defaultCollapsed);

    // We want to reset the state of the collapse panel whenever defaultCollapsed changes
    useEffect(() => setCollapsed(defaultCollapsed), [defaultCollapsed]);

    return (
        <div data-testid={dataTestId}>
            <Panel>
                <Panel.Heading
                    data-testid="collapse-heading"
                    onClick={() => setCollapsed(toggle => !toggle)}
                >
                    <Panel.Heading.Title>
                        {title}
                        <div className="collapse-panel__adornment">
                            {endAdornment}
                            <GlyphIcon data-testid="collapse-indicator" className="collapse-panel__indicator" iconName={collapsed ? 'menu-down' : 'menu-up'} />
                        </div>
                    </Panel.Heading.Title>
                </Panel.Heading>
                <div
                    data-testid="collapse-body"
                    style={{
                        display: collapsed ? 'none' : 'block',
                    }}
                >
                    <Panel.Body>
                        {children}
                    </Panel.Body>
                </div>
            </Panel>
        </div>
    );
}

CollapsePanel.propTypes = {
    'data-testid': string,
    title: string.isRequired,
    children: node,
    defaultCollapsed: bool,
    endAdornment: node,
};

CollapsePanel.defaultProps = {
    'data-testid': undefined,
    children: undefined,
    defaultCollapsed: false,
    endAdornment: undefined,
};

export default CollapsePanel;
