import { RefreshTokenFailedError } from '../common/utils/BearerToken';

// @ngInject
export default function startup($rootScope, $log, $state, authService, $window, permissionService, $location, $exceptionHandler) {
    // log state changes
    $rootScope.$on('$stateNotFound', (event, unfoundState) => {
        $log.warn('State not found', unfoundState.to);
    });
    $rootScope.$on('$stateChangeError', (event, toState, toParams, fromState, fromParams, error) => $exceptionHandler(error, '$stateChangeError'));

    $rootScope.$on('$stateChangeSuccess', () => {
        // Set classes on body to know on which state of the app you are
        const currentState = $state.$current.toString();
        $rootScope.bodyClasses = `shell-${currentState}`;

        switch ($location.host()) {
            case 'p.pelckmans.be':
            case 'shell.pelckmansportaal.be':
                $rootScope.bodyClasses += ' shell-production';
                break;
            case 'localhost':
                // keep default
                break;
            default:
                $rootScope.bodyClasses += ' shell-no-production';
                break;
        }
    });

    $rootScope.$on('$stateChangeStart', (event, toState) => {
        if (toState.security && !toState.security(permissionService)) {
            event.preventDefault();
            $window.location.href = '/#/forbidden';
        }
    });

    const handleUnauthenticated = () => {
        authService.login('pelckmans', 'nl', $state.href('home', {}, { absolute: true }));
    };

    $rootScope.$on('unauthenticated', handleUnauthenticated);

    // pass errors thrown by react http requests to global error handler
    $rootScope.$on('unhandledHttpError', (_, error) => {
        if (error instanceof RefreshTokenFailedError) handleUnauthenticated();
        else $exceptionHandler(error, 'unhandledHttpError');
    });

    authService.init();
}
