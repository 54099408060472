// This component is created because IE10-IE11 cannot handle CSS filter:grayscale.
// Replace back with <img> if IE minimum major version > 11.
// See [PAKP-1492].
import disabledImageTemplate from './disabled-image.component.pug';

export default {
    template: disabledImageTemplate,
    bindings: {
        imgSrc: '<',
        imgDisabled: '<',
        imgAlt: '<',
        imgWidth: '@',
        imgHeight: '@',
        cssClass: '@',
    },
};
