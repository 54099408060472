import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';

import './users.scss';

class Users {

    // @ngInject
    constructor(UserModel) {
        this.UserModel = UserModel;
    }

    $onInit() {
        this.criteria = {
            page: 1,
            pageSize: 10,
        };

        this.users = [];
    }

    onCriteriaChanged(criteria) {
        this.criteria = Object.assign({}, this.criteria, criteria);
        this.getUsers();
    }

    getUsers() {
        return this.UserModel
            .query(omitBy({
                page: this.criteria.page - 1,
                pageSize: this.criteria.pageSize,
                search: this.criteria.search,
                role: this.criteria.role,
            }, isUndefined))
            .then(users => {
                this.users = users;
                this.total = users.total;
            });
    }
}

export default {
    template: `
        <div class="users-view">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h3 class="panel-title users__title pull-left">Users</h3>
                    <div class="clearfix"></div>
                </div>

                <div class="panel-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <user-search-criteria on-searched="$ctrl.onCriteriaChanged(criteria)"></user-search-criteria>

                            <hr />

                            <user-grid users="$ctrl.users"></user-grid>

                            <div class="users_pager pull-right">
                                <uib-pagination
                                        ng-model="$ctrl.criteria.page"
                                        items-per-page="$ctrl.criteria.pageSize"
                                        total-items="$ctrl.total"
                                        class="pagination-sm"
                                        boundary-link-numbers="true"
                                        max-size="5"
                                        ng-change="$ctrl.onCriteriaChanged()"
                                >
                                </uib-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    controller: Users,
};
