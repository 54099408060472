import angular from 'angular';
import reactComponent from '../../../common/react-component';
import ExtensionsContainer from './extensions-container.component';
import ExtensionManager from './ExtensionManager';

const module = angular.module('app.shell.modules.extensions', []);

module
    .config($stateProvider => {
        // @ngInject
        $stateProvider.state('extensions', {
            url: '/extensions',
            template: `
                <div class="col-xs-8">
                    <extensions-container></extensions-container>
                </div>
            `,
            security: permissionService => permissionService.canManageExtensions,
        });
    })
    .component('extensionsContainer', ExtensionsContainer)
    .component('shellExtensions', reactComponent(ExtensionManager));

export default module.name;
