import indexOf from 'lodash/indexOf';
import without from 'lodash/without';

import './screenshot-manager.component.scss';

class ScreenShotManagerController {

    $onInit() {
        this.screenshots = this.screenshots || [];
        this.maxAmount = this.maximum || 5;
    }

    get showAdd() {
        return this.screenshots.length < this.maxAmount;
    }

    onImageAdded(id) {
        this.screenshot = undefined;
        this.screenshots.push(id);
    }

    onImageUpdated(id, previous) {
        const index = indexOf(this.screenshots, previous);

        if (index > -1) this.screenshots.splice(index, 1, id);
    }

    onImageRemoved(id) {
        this.screenshots = without(this.screenshots, id);
    }
}

export default {
    template: `<div class="screenshot-manager-container well">
    <div class="screenshot-manager-screenshots">
        <div ng-repeat="item in $ctrl.screenshots track by $index" class="screenshot-manager-screenshot">
            <image-upload ng-model="item"
                          ng-id="'image_' + item"
                          file-api-url="/files"
                          image-cleared="$ctrl.onImageRemoved(id)"
                          image-uploaded="$ctrl.onImageUpdated(id, item)">
            </image-upload>
        </div>
        <div ng-if="$ctrl.showAdd" class="screenshot-manager-edit">
            <image-upload ng-model="$ctrl.screenshot"
                          ng-id="'image_edit'"
                          file-api-url="/files"
                          image-uploaded="$ctrl.onImageAdded(id)">
            </image-upload>
        </div>
    </div>


</div>
    `,
    controller: ScreenShotManagerController,
    bindings: {
        screenshots: '=',
        maximum: '<?',
    },
};
