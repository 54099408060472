/* @ngInject */
export default function Word($modelFactory) {
    const model = $modelFactory('shell/modules/{moduleId}/table-of-content/{nodeId}/word-lists/{wordListId}/words', {
        actions: {
            post: {
                beforeRequest: request => {
                    if (request.data.image) {
                        request.data.image = request.data.image.id;
                    }

                    if (request.data.maleVoice) {
                        request.data.maleVoice = request.data.maleVoice.id;
                    }

                    if (request.data.femaleVoice) {
                        request.data.femaleVoice = request.data.femaleVoice.id;
                    }

                    if (request.data.filters) {
                        if (request.data.filters.number === null) {
                            delete request.data.filters.number;
                        }
                    }
                },
            },
            update: {
                beforeRequest: request => {
                    if (request.data.image) {
                        request.data.image = request.data.image.id;

                        if (request.data.image === '') {
                            delete request.data.image;
                        }
                    }

                    if (request.data.maleVoice) {
                        request.data.maleVoice = request.data.maleVoice.id;

                        if (request.data.maleVoice === '') {
                            delete request.data.maleVoice;
                        }
                    }

                    if (request.data.femaleVoice) {
                        request.data.femaleVoice = request.data.femaleVoice.id;

                        if (request.data.femaleVoice === '') {
                            delete request.data.femaleVoice;
                        }
                    }

                    if (request.data.filters) {
                        if (request.data.filters.number === null) {
                            delete request.data.filters.number;
                        }
                    }
                },
            },
        },
        $getUnmatchedWords: function (moduleId) { // eslint-disable-line object-shorthand
            return model.$call({
                method: 'GET',
                url: `/api/shell/modules/${moduleId}/import-word-lists-media/unmatched-words`,
                afterRequest: response => response.data,
            });
        },
        instance: {
            $rank: function (before, after) { // eslint-disable-line object-shorthand
                return model.$call({
                    method: 'PUT',
                    url: `/api/shell/modules/${this.moduleId}/table-of-content/${this.nodeId}/word-lists/${this.wordListId}/words/${this.id}/rank`,
                    invalidateCache: true,
                    data: {
                        before,
                        after,
                    },
                });
            },
        },
    });
    return model;
}
