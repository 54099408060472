import importWordListsTemplate from './import-word-lists.view.html';

import './import-word-lists.scss';

class ImportWordListsController {
    // @ngInject
    constructor($scope, $q, toaster, FileUploader, fileUploadValidationService, store, WordListModel, $http, config) {
        this.$scope = $scope;
        this.$q = $q;
        this.toaster = toaster;
        this.store = store;
        this.fileUploadValidationService = fileUploadValidationService;
        this.FileUploader = FileUploader;
        this.WordListModel = WordListModel;
        this.$http = $http;
        this.config = config;
    }

    $onInit() {
        this.initializeUploader();
        return this.fileUploadValidationService.initialize();
    }

    initializeUploader() {
        this.fileUploader = new this.FileUploader({
            autoUpload: true,
            removeAfterUpload: false,
            url: `${this.config.baseUrl}/shell/modules/${this.module}/parse-word-lists`,
            onBeforeUploadItem: item => {

                // reset
                this.parsedWordLists = [];
                this.validationErrors = [];
                this.uploadSuccess = undefined;
                this.parseError = undefined;

                const token = this.store.get('p-access_token');
                if (token) {
                    item.headers = {
                        Authorization: `Bearer ${token}`,
                    };
                }
            },
            onCompleteItem: (item, response, status) => {
                this.uploadSuccess = false;

                if (item.isSuccess && status === 200) {
                    this.fileProgress = 0;
                    this.uploadSuccess = true;
                    this.parsedWordLists = response.data;

                    this.setDefaults();

                } else if (status === 400) {

                    // when server does not send JSON array => parseError
                    try {
                        this.validationErrors = JSON.parse(response.message);
                    } catch (err) {
                        this.parseError = response.message || err;
                    }
                }
            },
            onProgressItem: (item, progress) => {
                this.fileProgress = progress;
            },
            onWhenAddingFileFailed: (item, filter) => {
                if (filter.name === 'csvExtension') {
                    const errorMessage = 'We only accept files with csv extension.';
                    this.toaster.pop('warning', errorMessage);
                }
                if (filter.name === 'extensionAndSize') {
                    const errorMessage = this.fileUploadValidationService.validate(item.name, item.size);
                    this.toaster.pop('warning', errorMessage);
                }
            },
        });

        // file extension and size filter
        this.fileUploader.filters.push({
            name: 'extensionAndSize',
            fn: item => this.fileUploadValidationService.isValid(item.name, item.size),
        });

        // file extension
        this.fileUploader.filters.push({
            name: 'csvExtension',
            fn: item => this.fileUploadValidationService.hasValidExtension(item.name, 'csv'),
        });
    }

    setDefaults() {
        this.parsedWordLists.forEach(wl => {
            wl.filters = {
                wordLists: {
                    name: 'Woordenlijsten',
                    active: true,
                },
                theme: {
                    name: 'Domeinen',
                    active: true,
                },
                wordType: {
                    name: 'Woordsoorten',
                    active: true,
                },
                number: {
                    name: 'nummer',
                    active: true,
                },
                important: {
                    name: 'belangrijke woorden',
                    active: true,
                },
            };
        });
    }

    setLanguages() {
        this.parsedWordLists.forEach(wl => {
            wl.targetLanguage = {
                name: this.importSettings.targetLanguage.name,
                abbreviation: this.importSettings.targetLanguage.abbreviation,
            };

            wl.sourceLanguage = {
                name: this.importSettings.sourceLanguage.name,
                abbreviation: this.importSettings.sourceLanguage.abbreviation,
            };
        });
    }

    importWordLists() {
        this.setLanguages();

        return this
            .saveWordLists()
            .then(savedWordLists => this.saveWords(savedWordLists))
            .then(() => this.onImportComplete())
            .catch(err => this.toaster.pop('error', 'Import failed. Corrupt data!', `error: '${err.statusText}'.`));
    }

    saveWordLists() {
        const wordLists = [];

        return this.parsedWordLists
            .reduce((p, wl) => p
                .then(() => {
                    const wordlist = new this.WordListModel(wl);
                    wordLists.push(wordlist);
                    return wordlist.$save();
                }), this.$q.resolve())
            .then(() => wordLists);
    }

    saveWords(savedWordLists) {
        const promises = [];
        this.parsedWordLists.forEach((wl, index) => {
            const wordListId = savedWordLists[index].id;
            if (wordListId) promises.push(this.saveWordsForWordList(wl.words, wordListId, this.saveWord));
        });
        return this.$q.all(promises);
    }

    // words should be saved in order of import file!
    saveWordsForWordList(words, wordListId, fn) {
        return words.reduce((p, word) => {
            word.wordListId = wordListId;
            return p.then(() => fn(word, this.$http));
        }, this.$q.resolve());
    }

    saveWord(word, $http) {
        return $http.post(`/api/shell/modules/${word.moduleId}/table-of-content/${word.nodeId}/word-lists/${word.wordListId}/words`, word);
    }

}

export default {
    template: importWordListsTemplate,
    controller: ImportWordListsController,
    bindings: {
        module: '<',
        onImportComplete: '&',
    },
};
