import React, { ReactNode } from 'react';
import classNames from 'classnames';

const ROOT = 'label';

function Label({
    className,
    children,
    'data-testid': dataTestId,
    variant = 'default',

}: {
    className?: string
    children?: ReactNode
    'data-testid'?: string
    variant?: 'default' | 'primary' | 'success' | 'info' | 'warning' | 'danger'
}): JSX.Element {
    return (
        <div
            className={classNames(ROOT, {
                [`${ROOT}-${variant}`]: true,
            }, className)}
            data-testid={dataTestId}
        >
            {children}
        </div>
    );
}

export default Label;
