import each from 'lodash/each';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import Role from '../../../../../core/security/role';

class UserSearchCriteria {

    $onInit() {
        this.roles = [];

        each(Role, role => {
            this.roles.push({
                value: role,
            });
        });
    }

    onSearchChanged() {
        const criteria = omitBy({
            page: 1,
            role: this.role,
            search: this.search,
        }, isUndefined);

        return this.onSearched({ criteria });
    }
}

export default {
    template: `
            <div class="row users__search">
                <div class="col-xs-5">
                   <am-multiselect
                        class="input-sm"
                        multiple="true"
                        ng-model="$ctrl.role"
                        options="item.value as item.value for item in $ctrl.roles">
                    </am-multiselect>
                </div>
                <div class="col-xs-6">
                    <input type="text" class="form-control user-search__input" ng-model="$ctrl.search" placeholder="Search ...">
                </div>
                <div class="col-xs-1">
                    <button type="button" class="btn btn-success pull-right user-search__button" ng-click="$ctrl.onSearchChanged()">
                        <span class="glyphicon glyphicon-search"></span> Search
                    </button>
                </div>
            </div>
    `,
    controller: UserSearchCriteria,
    bindings: {
        onSearched: '&',
    },
};
