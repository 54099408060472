import './exports.view.scss';

export default {
    template: `

            <div class="col-md-6 col-lg-4">
                    <div class="col-md-12">
    <div class="panel panel-default">
        <div class="panel-heading">
            <h3 class="panel-title">Exports</h3>
        </div>
        <div class="panel-body">
             <div class="row panel-row">
                <table class="table table-condensed qs-table__top">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="qs-exports__custom-teacher-links">
                            <td>custom teacher links</td>
                            <td class="text-right">
                            <a
                            type="button"
                            class="btn btn-default btn-xs qs-download-document"
                            title="Download"
                            ng-secure-href="${PORTAAL_API_URL}/api/exports/export-custom-teacher-links?attachment=export-custom-teacher-links">
                                <span class="glyphicon glyphicon-cloud-download" aria-hidden="true"></span>
                            </a>
                            </td>
                        </tr>
                        <tr class="qs-exports__file-medialinks">
                            <td>file medialinks</td>
                            <td class="text-right">
                            <a
                            type="button"
                            class="btn btn-default btn-xs qs-download-document"
                            title="Download"
                            ng-secure-href="${PORTAAL_API_URL}/api/exports/export-file-medialinks?attachment=export-file-medialinks">
                                <span class="glyphicon glyphicon-cloud-download" aria-hidden="true"></span>
                            </a>
                            </td>
                        </tr>
                        <tr class="qs-exports__weblink-medialinks">
                            <td>weblink medialinks</td>
                            <td class="text-right">
                            <a
                            type="button"
                            class="btn btn-default btn-xs qs-download-document"
                            title="Download"
                            ng-secure-href="${PORTAAL_API_URL}/api/exports/export-external-media-medialinks?attachment=export-external-media-medialinks">
                                <span class="glyphicon glyphicon-cloud-download" aria-hidden="true"></span>
                            </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
      </div>
        </div>

    `,
};
