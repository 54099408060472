class StudioLanguagesController {

    //@ngInject
    constructor(LanguageModel) {
        this.LanguageModel = LanguageModel;
    }

    $onInit() {
        return this.LanguageModel
            .query()
            .then(languages => {
                this.languages = languages;
            });
    }

}

export default {
    template: `
        <div class="row">
            <div class="col-lg-12">
                <div class="page-header">
                    <h2>Studio languages</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6" ng-repeat="lang in $ctrl.languages">
                <language-panel title="{{ lang.publishingHouseName() }}" language="lang"></language-panel>
            </div>
        </div>
    `,
    controller: StudioLanguagesController,
};
