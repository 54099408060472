import angular from 'angular';
import component from './tags.view';
import tagList from './components/tag-list.component';
import tagItem from './components/tag-item.component';

const module = angular.module('app.shell.modules.tags', []);

module
    .config(($stateProvider) => {
        // @ngInject
        $stateProvider.state('tags', {
            url: '/tags',
            template: `
                <shell-tags></shell-tags>
            `,
            security: (permissionService) => permissionService.canManageApps,
        });
    })
    .component('shellTagList', tagList)
    .component('shellTagItem', tagItem)
    .component('shellTags', component);

export default module.name;
