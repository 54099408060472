function cleanRequest(req) {
    delete req.data.id;
}

/* @ngInject */
export default function LicenseproductModuleModelFactory($modelFactory) {
    return $modelFactory('shell/modules/{moduleId}/licenseproducts', {
        actions: {
            query: {
                afterRequest: (response) => {
                    return response.data;
                },
            },
            post: {
                url: '/api/shell/modules/{moduleId}/licenseproducts',
                override: true,
                beforeRequest: (request) => {
                    return cleanRequest(request);
                },
            },
            update: {
                url: '/api/shell/modules/{id}/licenseproducts',
                override: true,
                beforeRequest: (request) => {
                    return cleanRequest(request);
                },
            },
        },
    });
}
