/* @ngInject */
export default function EdumaticPublicationModelFactory($modelFactory) {
    const model = $modelFactory('shell/assessmentq/publications', {
        actions: {
            getPartsByPublicationId: {
                method: 'GET',
                url: '{publicationId}/parts',
                wrap: false,
                afterRequest: response => response.data,
            },
        },
    });

    return model;
}
