import './bookmark-statistics.component.scss';

class BookmarkStatistics {

    // @ngInject
    constructor($http, $q) {
        this.$http = $http;
        this.$q = $q;
    }

    $onInit() {
        this.unsharedColor = '#EAA28C';
        this.sharedWithGroupColor = '#E4886B';
        this.sharedWithEveryoneColor = '#DE6E4B';

        this.chartColors = [this.unsharedColor, this.sharedWithGroupColor, this.sharedWithEveryoneColor];

        return this.$q
            .all([
                this.$http
                    .get('/api/shell/statistics/bookmarks'),
                this.$http
                    .get('/api/shell/statistics/bookmarks-per-module'),
            ])
            .then(([bookmarks, bookmarksPerModule]) => {
                this.statistics = bookmarks.data;
                this.statisticsPerModule = bookmarksPerModule.data.data;
            });
    }
}

export default {
    template: `
    <div class="panel panel-default">
        <div class="panel-heading">
            <h3 class="panel-title">Bookmark stats - Total: {{$ctrl.statistics.total}}</h3>
        </div>
        <div class="panel-body">
            <div class="row panel-row text-center">
                <canvas class="chart chart-pie qs-chart"
                    chart-data="[$ctrl.statistics.amountOfUnshared, $ctrl.statistics.amountOfSharedWithGroups, $ctrl.statistics.amountOfSharedWithEveryone]"
                    chart-labels="['Unshared', 'Shared with group(s)', 'Shared with everyone']"
                    chart-colors="$ctrl.chartColors"
                >
                </canvas>
            </div>
             <div class="row panel-row">
                <table class="table table-condensed qs-table__state bookmarks-state">
                    <thead>
                        <tr>
                            <th>State</th>
                            <th class="text-right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span class="label label-legend" ng-style="{ 'backgroundColor': $ctrl.unsharedColor }"> </span>Unshared</td>
                            <td class="text-right">{{ $ctrl.statistics.amountOfUnshared }}</td>
                        </tr>
                        <tr>
                            <td><span class="label label-legend" ng-style="{ 'backgroundColor': $ctrl.sharedWithGroupColor }"> </span>Shared with group(s)</td>
                            <td class="text-right">{{ $ctrl.statistics.amountOfSharedWithGroups }}</td>
                        </tr>
                        <tr>
                            <td><span class="label label-legend" ng-style="{ 'backgroundColor': $ctrl.sharedWithEveryoneColor }"> </span>Shared with everyone</td>
                            <td class="text-right">{{ $ctrl.statistics.amountOfSharedWithEveryone }}</td>
                        </tr>
                    </tbody>
                </table>

                <table class="table table-condensed qs-table__top bookmarks-top" ng-if="$ctrl.statisticsPerModule.length">
                    <thead>
                        <tr>
                            <th>Top 5 Modules</th>
                            <th class="text-right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr ng-repeat="module in $ctrl.statisticsPerModule">
                            <td>{{ module.module }}</td>
                            <td class="text-right">{{ module.total }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </div>
    </div>
    `,
    controller: BookmarkStatistics,
};
