import { Part } from '../../../../../../api/MediaLinksApi';
import { CheckboxState, CHECKBOX_STATES } from './Checkbox';

type TransformedNode = {
    name: string;
    children?: TransformedNode[];
    parent: string;
    id: string;
};

const transformNode = (node: Part, parent: string): TransformedNode => {
    if (node.nodes && node.nodes.length > 0) {
        return {
            id: node.id,
            name: node.name,
            parent,
            children: node.nodes.map(childNode => transformNode(childNode, node.id)),
        };
    }
    return {
        id: node.id,
        name: node.name,
        parent,
    };

};

export const transformData = (data: Part[]): TransformedNode[] => data.map(node => transformNode(node, '0'));

export const determineCheckboxState = (isSelected: boolean, isHalfSelected: boolean): CheckboxState => {
    if (isSelected) return CHECKBOX_STATES.Checked;
    if (isHalfSelected) return CHECKBOX_STATES.Indeterminate;
    return CHECKBOX_STATES.Empty;
};

export const findPartsByIds = (data: Part[], ids: string[]): Part[] => {
    const idSet = new Set(ids);

    const recursive = (nodes: Part[]): Part[] => nodes.reduce((result: Part[], node: Part) => {
        if (idSet.has(node.id)) result.push(node);
        if (node.nodes) result.push(...recursive(node.nodes));

        return result;
    }, []);

    return recursive(data);
};

export const findParentIds = (data: Part[], targetId: string): string[] => {
    const recursiveFind = (nodes: Part[], path: string[]): string[] => {
        const result = nodes.map(node => {
            const newPath = [...path, node.id];
            if (node.id === targetId) {
                return newPath;
            }

            if (node.nodes) {
                return recursiveFind(node.nodes, newPath);
            }

            return [];
        });

        return result.find(r => r.length > 0) || [];
    };

    return recursiveFind(data, []);
};
