/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import classNames from 'classnames';
import { string, node, func } from 'prop-types';

const PANEL_ROOT = 'panel';

function Panel({
    className,
    children,
    'data-testid': dataTestId,
}) {
    return (
        <div
            className={classNames(PANEL_ROOT, {
                [`${PANEL_ROOT}-default`]: true,
            }, className)}
            data-testid={dataTestId}
        >
            {children}
        </div>
    );
}

Panel.propTypes = {
    className: string,
    children: node,
    'data-testid': string,
};

Panel.defaultProps = {
    className: undefined,
    children: undefined,
    'data-testid': undefined,
};

function PanelBody({
    className,
    children,
    'data-testid': dataTestId,
}) {
    return (
        <div
            className={classNames({
                [`${PANEL_ROOT}-body`]: true,
            }, className)}
            data-testid={dataTestId}
        >
            {children}
        </div>
    );
}

PanelBody.propTypes = {
    className: string,
    children: node,
    'data-testid': string,
};

PanelBody.defaultProps = {
    className: undefined,
    children: undefined,
    'data-testid': undefined,
};

function PanelHeading({
    className,
    children,
    onClick,
    title,
    'data-testid': dataTestId,
}) {
    return (
        <div
            className={classNames({
                [`${PANEL_ROOT}-heading`]: true,
            }, className)}
            data-testid={dataTestId}
            role={onClick && 'button'}
            onClick={onClick}
        >
            {title && <PanelTitle>{title}</PanelTitle>}
            {children}
        </div>
    );
}

PanelHeading.propTypes = {
    className: string,
    children: node,
    'data-testid': string,
    onClick: func,
    title: string,
};

PanelHeading.defaultProps = {
    className: undefined,
    children: undefined,
    'data-testid': undefined,
    onClick: undefined,
    title: undefined,
};

function PanelTitle({ children }) {
    return (
        <h4 className={`${PANEL_ROOT}-title`}>
            {children}
        </h4>
    );
}

PanelTitle.propTypes = {
    children: node.isRequired,
};

PanelHeading.Title = PanelTitle;
Panel.Heading = PanelHeading;
Panel.Body = PanelBody;

export default Panel;
