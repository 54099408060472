import angular from 'angular';
import './media-links.scss';

import reactComponent from '../../../common/react-component';
import MetaDataTab from './components/meta-data-tab/MetaDataTab';
import FileExportTab from './components/file-export-tab/FileExportTab';

import component from './media-links.view';
import suggestionList from './components/suggestion-list';
import elementScrollOffset from './directives/element-scroll-offset';
import medialinksTabComponent from './components/medialinks-tab.component';
import MedialinksHierarchyFilter from './components/move-copy/MedialinksHierarchyFilter';
import AssessmentQSettings from './components/assessmentq/AssessmentQSettings';

const module = angular.module('app.shell.modules.mediaLinks', []);

module
    .config($stateProvider => {
        // @ngInject
        $stateProvider.state('media-links', {
            url: '/media-links',
            template: `
                <shell-media-links></shell-media-links>
            `,
            security: permissionService => permissionService.canManageMediaLinks,
        });
    })
    .component('shellMediaLinks', component)
    .component('suggestionList', suggestionList)
    .component('medialinksTab', medialinksTabComponent)
    .component('medialinkMetaDataTab', reactComponent(MetaDataTab, ['moduleId']))
    .component('medialinksHierarchyFilter', reactComponent(MedialinksHierarchyFilter, ['publishingHouseId', 'subjectId', 'methodId', 'superModuleId', 'moduleId', 'onModuleSelected']))
    .component('medialinkExportTab', reactComponent(FileExportTab, ['moduleId']))
    .component('assessmentQSettings', reactComponent(AssessmentQSettings, ['onClose', 'onSave', 'moduleId', 'nodeId', 'moduleName', 'medialink', 'medialinks']))
    .directive('elementScrollOffset', elementScrollOffset);

export default module.name;
