import angular from 'angular';
import reactComponent from '../../../common/react-component';
import component from './license-settings.view';
import LicenseProducts from './components/LicenseProducts';
import LicenseCodeSettings from './components/LicenseCodeSettings';

const module = angular.module('app.shell.modules.license-settings', []);

module
    .config($stateProvider => {
        // @ngInject
        $stateProvider
            .state('licenseSettings', {
                url: '/license-settings',
                title: 'Pakket-p SHELL license settings',
                template: `
                    <shell-license-settings></shell-license-settings>
                `,
                security: permissionService => permissionService.canManageLicenseSettings,
            });
    })
    .component('shellLicenseSettings', component)
    .component('licenseProducts', reactComponent(LicenseProducts, ['moduleId']))
    .component('licenseCodeSettings', reactComponent(LicenseCodeSettings, ['moduleId']));

export default module.name;
