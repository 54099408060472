import faqSettingsTemplate from '../faq-settings.html';

class FaqManager {
    // @ngInject
    constructor($scope, FAQModel, ngDialog, $filter, toaster, $q, faqType) {
        this.$scope = $scope;
        this.FAQModel = FAQModel;
        this.ngDialog = ngDialog;
        this.toaster = toaster;
        this.$q = $q;
        this.faqType = faqType;
        this.treeFilter = $filter('uiTreeFilter');

        this.tableOptions = {
            beforeDrop: faq => {
                const faqs = angular.copy(this.faqs);

                const destIsHigherThanSource = faq.dest.index > faq.source.index;
                const droppedOnIndex = faqs[faq.dest.index];

                const rank = {
                    before: destIsHigherThanSource ? faqs[faq.dest.index + 1] : droppedOnIndex,
                    after: destIsHigherThanSource ? droppedOnIndex : faqs[faq.dest.index - 1],
                };

                if (faq.dest.index === 0) {
                    delete rank.after;
                }

                if (faq.dest.index === faqs.length - 1) {
                    delete rank.before;
                }

                const faqToRank = this.faqs[faq.source.index];
                return faqToRank.$rank(rank.before ? rank.before.id : undefined, rank.after ? rank.after.id : undefined)
                    .catch(err => {
                        if (err.status === 404) {
                            toaster.pop('warning', 'Oops, something went wrong', 'Someone removed the faq you are trying to drag');
                            return this.$q.resolve(false);
                        }

                        if (err.status === 409) {
                            toaster.pop('warning', 'Oops, something went wrong', 'Invalid state detected, try refreshing your page');
                            return this.$q.resolve(false);
                        }
                        return this.$q.reject(err);
                    });
            },
        };
    }

    frenchIsEditable() {
        return this.currentFAQ.targetLanguage !== 0; // dutch
    }

    $onChanges() {
        if (!this.studioGroup) return this.$q.resolve();

        return this.$q
            .all([
                this.FAQModel.query({ group: this.studioGroup }),
                this.FAQModel.queryVotes(),
            ])
            .then(([faqs, votes]) => {
                this.faqs = faqs;
                this.votes = votes;
            });
    }

    showSettings(question) {
        this.selectedFAQ = question;

        const promise = question ? this.FAQModel.get(question.id) : this.$q.resolve(
            new this.FAQModel({
                type: 0,
                targetLanguage: 0,
                userTarget: 'student',
                category: {
                    id: this.filter || undefined,
                },
            }));

        return promise
            .then(resolvedFAQ => {
                this.currentFAQ = resolvedFAQ;

                if (question) {
                    question.$update(resolvedFAQ);
                    question.$commit();
                }
            })
            .then(() => this.ngDialog.open({
                template: faqSettingsTemplate,
                plain: true,
                scope: this.$scope,
                className: 'ngdialog-theme-default faq-settings-dialog',
            }));
    }

    saveFAQ() {
        const isNew = angular.isUndefined(this.selectedFAQ);

        return this.currentFAQ.$save()
            .then(faq => {
                if (!isNew) {
                    this.selectedFAQ.$update(faq);
                    this.selectedFAQ.$commit();
                } else {
                    this.faqs.push(faq);
                }
                return this.ngDialog.close();
            })
            .catch(err => {
                if (err.status === 409) this.toaster.pop('error', err.data.message);
                else return this.$q.reject(err);
            });
    }

    deleteFaq(faq) {
        return faq
            .$destroy()
            .catch(err => {
                if (err.status === 409) this.toaster.pop('error', err.data.message);
                else return this.$q.reject(err);
            });
    }

    getHelpfulVotesCaption(faq) {
        const vote = this.votes.find(x => x.id === faq.id) || {
            helpful: {
                student: 0,
                other: 0,
            },
        };

        return `${vote.helpful.student + vote.helpful.other} (S: ${vote.helpful.student} / O: ${vote.helpful.other})`;
    }

    getNotHelpfulVotesCaption(faq) {
        const vote = this.votes.find(x => x.id === faq.id) || {
            notHelpful: {
                student: 0,
                other: 0,
            },
        };

        return `${vote.notHelpful.student + vote.notHelpful.other} (S: ${vote.notHelpful.student} / O: ${vote.notHelpful.other})`;
    }
}

export default {
    template: `<div class="panel panel-default" pt-faq__list ui-tree="$ctrl.tableOptions">
     <div class="panel-heading">

      <p class="panel-title panel-category-title">Manage FAQ's & Tutorials</p>

      <div class="pull-right">
         <button type="button" class="btn btn-sm btn-success" pt-faq-add ng-click="$ctrl.showSettings()"><span
                class="glyphicon glyphicon-plus-sign"></span> Add a new item
        </button>
        <select
                        pt-faq-filter
                        id="faqCategoryFilter"
                        name="faqCategoryFilter"
                        class="form-control input-sm pull-right"
                        ng-model="$ctrl.filter"
                        ng-options="category.id as category.name for category in $ctrl.categories | orderBy:'name'"
                        required
                        ng-if="$ctrl.faqs.length > 0"
                >
                    <option value="">-- Select category --</option>
                </select>
        <div class="clearfix"></div>
    </div>
    </div>

    <div class="panel-body faq__list" ui-tree-nodes ng-model="$ctrl.faqs">
        <div ng-if="$ctrl.faqs.length === 0" pt-faq__none class="text-muted text-center">
            Er zijn nog geen FAQ.
        </div>

        <div class="panel panel-default" ng-repeat="faq in $ctrl.faqs" pt-faq__item ng-if="$ctrl.treeFilter(faq, $ctrl.filter, ['category.id'])"  ui-tree-node>
            <div class="panel-body faq-item">
                <div class="drag-drop-handle faq-item__dragdrop" ui-tree-handle>
                    <span class="glyphicon glyphicon-option-vertical"></span>
                </div>

                <div class="faq-item__info">
                    <div ng-if="faq.targetLanguage !== 1">
                        <span class="label label-default label-language">NL</span>
                        <div>
                            <h3 class="panel-title faq-item__question" pt-faq__question>{{ faq.question | limitTo: 100 }}{{ faq.question.length > 100 ? '...' : '' }}</h3>
                        </div>
                        <div>
                            <article class="faq-item__answer" pt-faq__answer>{{ faq.answer | limitTo: 400 }}{{ faq.answer.length > 400 ? '...' : '' }}</article>
                        </div>
                        <div class="margin-top-sm" ng-if="faq.vimeoId">
                            Vimeo ID: <a class="faq-item__vimeoId" target="_blank" ng-href="https://vimeo.com/{{faq.vimeoId}}">{{ faq.vimeoId }}</a>
                        </div>
                    </div>
                    <hr ng-if="faq.targetLanguage === 2">
                    <div ng-if="faq.targetLanguage !== 0">
                        <span class="label label-default label-language">FR</span>
                        <div>
                            <h3 class="panel-title faq-item__question" pt-faq__question-french>{{ faq.frenchQuestion | limitTo: 100 }}{{ faq.frenchQuestion.length > 100 ? '...' : '' }}</h3>
                        </div>
                        <div>
                            <article class="faq-item__answer" pt-faq__answer-french>{{ faq.frenchAnswer | limitTo: 400 }}{{ faq.frenchAnswer.length > 400 ? '...' : '' }}</article>
                        </div>
                        <div class="margin-top-sm" ng-if="faq.frenchVimeoId">
                            Vimeo ID: <a class="faq-item__frenchVimeoId" target="_blank" ng-href="https://vimeo.com/{{faq.frenchVimeoId}}">{{ faq.frenchVimeoId }}</a>
                        </div>
                    </div>
                    <div class="faq__footer">
                        <span class="label label-success faq-item__type">{{ faq.type === $ctrl.faqType.TUTORIAL ? 'Tutorial': 'FAQ' }}</span>
                        <span class="label label-warning faq-item__category" pt-faq__category>{{ faq.category.name }}</span>
                        <span class="label label-info faq-item__usertarget">{{ faq.userTarget }}</span>
                        <div class="faq-actions pull-right">
                            <span>votes:</span>
                            <span class="label label-success faq-helpful-votes">{{ $ctrl.getHelpfulVotesCaption(faq) }}</span>
                            <span class="label label-danger faq-not-helpful-votes">{{ $ctrl.getNotHelpfulVotesCaption(faq) }}</span>
                            <button class="btn btn-xs btn-default" pt-faq-edit ng-click="$ctrl.showSettings(faq)">
                                <span class="glyphicon glyphicon-pencil"></span>
                            </button>
                            <button class="btn btn-xs btn-danger" pt-faq-delete
                                ng-click-confirmed
                                confirmed="$ctrl.deleteFaq(faq)"
                                confirmation-title='Delete item'
                                confirmation-message="Are you sure you want to delete '{{ faq.question }}'?">
                                <span class="glyphicon glyphicon-trash"></span>
                            </button>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>

                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</div>

    `,
    controller: FaqManager,
    bindings: {
        studioGroup: '<',
        categories: '<',
    },
};
