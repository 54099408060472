/* @ngInject */
export default function EdumaticScheduleModelFactory($modelFactory) {
    const model = $modelFactory('shell/assessmentq/schedules', {
        actions: {
            query: {
                afterRequest: response => response.data,
            },
            getPublicationsByScheduleId: {
                method: 'GET',
                url: '{scheduleId}/publications',
                wrap: false,
                afterRequest: response => response.data,
            },
        },
    });

    return model;
}
