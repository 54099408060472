import React from 'react';
import {
    oneOf,
    oneOfType,
    arrayOf,
    node,
} from 'prop-types';

export default function Alert({
    variant,
    children,
}) {
    return (
        <div className={`alert alert-${variant}`} role="alert">
            {children}
        </div>
    );
}

Alert.propTypes = {
    variant: oneOf([
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'light',
        'dark',
    ]),
    children: oneOfType([
        arrayOf(node),
        node,
    ]).isRequired,
};

Alert.defaultProps = {
    variant: 'primary',
};
