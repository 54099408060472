class AllAuthorsPanelController {

    // @ngInject
    constructor(AuthorModel) {
        this.AuthorModel = AuthorModel;
    }

    $onInit() {
        return this.AuthorModel
            .queryActiveAuthors()
            .then(authors => {
                this.authors = authors;
            });
    }
}

export default {
    template: `
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="row">
                        <div class="col-lg-12">
                            <authors-grid data="$ctrl.authors" read-only="true"></authors-grid>
                        </div>
                    </div>
            </div>
        </div>
    `,
    controller: AllAuthorsPanelController,
};
