/* @ngInject */
export default function TagsModelFactory($modelFactory) {
    return $modelFactory('shell/methods/{methodId}/tags', {
        actions: {
            query: {
                afterRequest: response => response.data,
            },
            get: {
                url: '/api/shell/methods/{methodId}/tags/{id}',
                override: true,
            },
        },

    });
}
