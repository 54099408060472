import DeepDiff from 'deep-diff';

/* @ngInject */
export default function FileConstraintModel($modelFactory) {
    return $modelFactory('shell/file-constraints', {
        actions: {
            query: {
                afterRequest: response => response.data,
            },
            update: {
                beforeRequest: request => {
                    delete request.data.isEditing;
                },
            },
        },
        list: {
            getConstraintByFileName: function (fileName) { // eslint-disable-line object-shorthand
                const extensionRegEx = /(?:\.([^.]+))?$/;
                const extension = extensionRegEx.exec(fileName)[1];
                return this.find(x => x.extension === extension);
            },
        },
        instance: {
            $revert: function () { // eslint-disable-line object-shorthand
                const changes = this.$diff();
                if (changes && changes.length > 0) {
                    changes.forEach(change => DeepDiff.revertChange(this, {}, change));
                }
            },
            isSatisfiedBy: function (fileSize) { // eslint-disable-line object-shorthand
                const maxSize = this.maxSizeInKb * 1024;
                return fileSize <= maxSize;
            },
        },
    });
}
