import angular from 'angular';
import components from './components';
import modules from './modules';
import directives from './directives';
import services from './services';
import filters from './filters';
import models from './models';

const module = angular.module('app.shell', [
    components,
    modules,
    directives,
    services,
    filters,
    models,
]);

export default module.name;
