import moment from 'moment';
import notificationSettingsTemplate from './modals/notification-settings.html';

class NotificationsPanelController {

    // @ngInject
    constructor(MarketingNotificationModel, $scope, $http, $q, ngDialog, studioGroup) {
        this.MarketingNotificationModel = MarketingNotificationModel;
        this.$scope = $scope;
        this.$http = $http;
        this.$q = $q;
        this.ngDialog = ngDialog;
        this.studioGroup = { ...studioGroup, VO: 'VOLWASSENEN' };

        this.modulesChanged = this.modulesChanged.bind(this);
    }

    onStudioGroupChanged() {
        return this.$q.all([
            this.MarketingNotificationModel
                .query({
                    group: this.selectedStudioGroup,
                }),
            this.$http.get(`/api/shell/studio-groups/${this.selectedStudioGroup}/modules?superModuleOnly=true`),
        ])
            .then(([notifications, { data: { data: modules } }]) => {
                this.notifications = notifications;
                this.modules = modules;
            });
    }

    // eslint-disable-next-line class-methods-use-this
    deleteNotification(notification) {
        return notification.$destroy();
    }

    save() {
        const isNew = angular.isUndefined(this.selectedNotification);
        return this.currentNotification
            .$save()
            .then(notification => {
                this.ngDialog.close();

                if (!isNew) {
                    this.selectedNotification.$update(notification);
                    this.selectedNotification.$commit();
                    this.selectedNotification = undefined;
                } else {
                    this.notifications.push(notification);
                }
            });
    }

    validToChanged(newDate) {
        this.currentNotification.validTo = moment(newDate).endOf('day').toDate();
    }

    addNotification() {
        this.currentNotification = new this.MarketingNotificationModel({
            targetLanguage: 0,
            group: this.selectedStudioGroup,
            loginProvider: 'any',
            modules: [],
        });
        this.resetLanguageSection();
        this.openNotificationSettings();
    }

    editNotification(notification) {
        this.selectedNotification = notification;

        if (!this.selectedNotification.modules) this.selectedNotification.modules = [];

        return this.MarketingNotificationModel
            .get({ id: notification.id, group: this.selectedStudioGroup })
            .then(notific => {
                this.currentNotification = notific;

                if (!this.currentNotification.modules) this.currentNotification.modules = [];

                this.openNotificationSettings();
            });
    }

    openNotificationSettings() {
        return this.ngDialog
            .open({
                template: notificationSettingsTemplate,
                plain: true,
                scope: this.$scope.$new(),
                appendClassName: 'notification-settings-dialog',
            });
    }

    modulesChanged(newModules) {
        this.$scope.$apply(() => {
            this.currentNotification.modules = newModules;
        });
    }

    onTargetLanguageChanged() {
        this.resetLanguageSection();
    }

    resetLanguageSection() {
        switch (this.currentNotification.targetLanguage) {
            case 0: { // NL
                this.currentNotification.dutch = { moreInfo: { visible: false } };
                delete this.currentNotification.french;
                break;
            }
            case 1: { // FR
                delete this.currentNotification.dutch;
                this.currentNotification.french = { moreInfo: { visible: false } };
                break;
            }
            case 2: { // NL & FR
                this.currentNotification.dutch = { moreInfo: { visible: false } };
                this.currentNotification.french = { moreInfo: { visible: false } };
                break;
            }
            default:
        }
    }
}

export default {
    template: `
    <div class="panel panel-default notifications-panel">
        <div class="panel-heading">
            <button type="button" class="btn btn-success btn-add-notification" ng-click="$ctrl.addNotification()" ng-disabled="!$ctrl.selectedStudioGroup">Add new notification</button>
            <select id="studioGroup" class="form-control input-sm pull-right" ng-change="$ctrl.onStudioGroupChanged()" ng-model="$ctrl.selectedStudioGroup" ng-options="key as value for (key , value) in $ctrl.studioGroup">
                <option disabled value="">-- Select a studio --</option>
            </select>
            <div class="clearfix"></div>
        </div>

        <div class="panel-body">
            <div class="table-responsive">
                <table class="notifications__table table table-condensed table-bordered">
                        <thead>
                            <tr>
                                <th>Event</th>
                                <th>Target</th>
                                <th>State</th>
                                <th>From</th>
                                <th>Until</th>
                                <th class="text-center col-fit">Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr ng-if="!$ctrl.selectedStudioGroup">
                                <td colspan="9" class="no-group text-muted text-center">Please select a studio</td>
                            </tr>
                            <tr ng-if="(!$ctrl.notifications || $ctrl.notifications.length === 0) && $ctrl.selectedStudioGroup">
                                <td colspan="9" class="no-result text-muted text-center">∞</td>
                            </tr>
                            <tr ng-repeat="notification in $ctrl.notifications" class="notifications-list__notification notification-info">
                                <td ng-bind="notification.event"></td>
                                <td ng-bind="notification.teacherOnly ? 'Teacher' : 'All'"></td>
                                <td ng-bind="notification.published ? 'Published' : 'Draft'"></td>
                                <td>{{ notification.validFrom | date:'dd-MM-yyyy' }}</td>
                                <td>{{ notification.validTo | date:'dd-MM-yyyy' }}</td>
                                <td class="text-center col-fit">
                                    <button type="button" class="btn btn-default btn-xs notification-edit" title="Edit Notification"
                                        ng-click="$ctrl.editNotification(notification)"
                                    >
                                        <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                                    </button>
                                    <button type="button" class="btn btn-danger btn-xs notification-delete" title="Delete Notification"
                                        ng-click-confirmed
                                        confirmed="$ctrl.deleteNotification(notification)"
                                        confirmation-title='Delete Notification'
                                        confirmation-message="Are you sure you want to delete : '{{ notification.event }}'?"
                                    >
                                        <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
            </div>
        </div>
    </div>
    `,
    controller: NotificationsPanelController,
};
