import React from 'react';
import Button from '../../../components/Button';

type ExportButtonProps = {
    onClick: () => void;
    disabled: boolean;
};

export default function ExportButton({ onClick, disabled }: ExportButtonProps): JSX.Element {
    return (
        <Button
            variant="success"
            data-testid="generate-export-button"
            onClick={onClick}
            disabled={disabled}
        >
            {
                disabled
                    ? (
                        <div data-testid="loading-indicator">
                            <span className="glyphicon glyphicon-refresh spin" role="status" />
                            <span>Export in progress</span>
                        </div>
                    ) : 'Generate Export'
            }
        </Button>

    );
}
