class AppItem {
    edit() {
        return this.editClick({ app: this.app });
    }

    deleteApp() {
        return this.deleteClick({ app: this.app });
    }
}

export default {
    template: `
        <div class="row app-item">
            <div class="col-sm-1">
                <shell-app-tag  background-color="$ctrl.app.appType.icon.color"
                                caption="$ctrl.app.appType.icon.initials">
                </shell-app-tag>
            </div>
            <div class="col-sm-11">
                <div class="col-sm-6">
                    <b>Title:</b> {{ $ctrl.app.name }}<br>
                     <b>Type:</b> {{ $ctrl.app.appType.name }}<br>
                     <div ng-if="$ctrl.app.platformUrl">
                        <b>Product URL:</b> <a target="_blank" ng-href="{{ $ctrl.app.platformUrl }}">{{ $ctrl.app.platformUrl }}</a><br>
                     </div>
                     <div ng-if="$ctrl.app.linkedApp.id">
                        <b>Linked App Id:</b> <span>{{ $ctrl.app.linkedApp.id }}</span><br>
                     </div>
                        <b>Publishing State:</b> <publishing-state-component publishing-state="$ctrl.app.state"
                                                        release-date="$ctrl.app.releaseDate"></publishing-state-component>
                </div>
                <div class="col-sm-3">
                     <b>Platform:</b> {{ $ctrl.app.platformType }} <br>
                     <b>Target:</b> {{ $ctrl.app.target }} <br>
                     <b>Weight:</b> {{ $ctrl.app.weight }}
                </div>
                <div class="col-sm-3 padding-btn-group">
                    <button ng-click="$ctrl.edit()" class="app-item__edit btn btn-default">
                        <span class="glyphicon glyphicon-pencil"></span> Edit
                    </button>
                    <button class="btn btn-danger app-item__delete"
                            ng-click-confirmed confirmed="$ctrl.deleteApp()"
                            confirmation-title='Delete app'
                            confirmation-message="Are you sure you want to delete : '{{ $ctrl.app.name }}'"
                            ng-disabled="$ctrl.app.state === 'published'">
                        <span class="glyphicon glyphicon-trash"></span> Delete
                    </button>
                </div>
            </div>
        </div>
    `,
    controller: AppItem,
    bindings: {
        app: '<',
        editClick: '&',
        deleteClick: '&',
    },
};
