class MenuItem {

    // We need this to let our require of the parent work!!!!
    $onInit() {
    }
}

module.exports = {
    template: `
        <li ng-class="{'sidebar-menu__item--active': $ctrl.parent.state.includes($ctrl.state)}"  class="sidebar-menu__item">
            <a ui-sref="{{::$ctrl.state}}" class="sidebar-menu__link">{{::$ctrl.title}}</a>
        </li>
    `,
    controller: MenuItem,
    require: {
        parent: '^sidebar',
    },
    bindings: {
        state: '<',
        title: '<',
    },
};
