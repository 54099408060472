import find from 'lodash/find';

export default function () {
    return (arrayOfValues = [], pluralNameText, mappingArray) => {

        let nameArr = [];

        if (mappingArray) {
            arrayOfValues.forEach(value => {
                if (find(mappingArray, mapObj => { return mapObj.id === value; })) {
                    nameArr.push(find(mappingArray, mapObj => { return mapObj.id === value; }).name);
                }
            });
        } else {
            nameArr = arrayOfValues;
        }

        const valueName = pluralNameText || 'items';

        switch (nameArr.length) {
            case 0:
                return '';
            case 1:
                return `${nameArr[0]}`;
            case 2:
                return `${nameArr[0]}, ${nameArr[1]}`;
            default:
                return `${nameArr.length} ${valueName} selected`;

        }
    };
}
