import React from 'react';
import shellApi from '../../../services/api';

type ExportButtonProps = {
    selectedModule: string;
    baseLinkUrl: string;
    disabled?: boolean;
};

export default function ExportButton({
    selectedModule,
    disabled,
    baseLinkUrl,
}: ExportButtonProps): JSX.Element {
    const exportTOC = async () => {
        const response = await shellApi.post(`/shell/modules/${selectedModule}/table-of-content/export`, { baseLinkUrl });
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${selectedModule}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <button type="button" className="btn btn-success export-toc" disabled={disabled} onClick={exportTOC}>
            Export table of contents
        </button>
    );
}
