import angular from 'angular';
import appComponent from './app.component';
import squareBoxComponent from './square-box.component';
import disabledImageComponent from './disabled-image.component';

const module = angular.module('app.common.components', [
])
    .component('app', appComponent)
    .component('squareBox', squareBoxComponent)
    .component('disabledImage', disabledImageComponent);

export default module.name;
