import angular from 'angular';
import component from './subjects-methods.view';
import subjectsComponent from './components/subjects.component';
import methodsComponent from './components/methods.component';
import modulesComponent from './components/modules.component';
import subModulesSelector from './components/sub-modules-selector.component';

const module = angular.module('app.shell.modules.subjects-methods', []);

module
    .config(($stateProvider) => {
        // @ngInject
        $stateProvider.state('subjects-methods', {
            url: '/subjects-methods',
            template: `
                <shell-subjects-methods></shell-subjects-methods>
            `,
            security: (permissionService) => permissionService.canManageSubjectsAndMethods,
        });
    })
    .component('shellSubjectsMethods', component)
    .component('shellSubjectsComponent', subjectsComponent)
    .component('shellMethodsComponent', methodsComponent)
    .component('shellModulesComponent', modulesComponent)
    .component('shellSubModulesSelector', subModulesSelector);

export default module.name;
