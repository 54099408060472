import angular from 'angular';

import permissionService from './permission-service';
import fileUploadValidationService from './file-upload-validation-service';
import translateServiceStub from './translate-service-stub';

const module = angular.module('app.shell.modules.services', [
]);

module.service('permissionService', permissionService);
module.service('fileUploadValidationService', fileUploadValidationService);

// TODO: move auth service to studio. gatrackerservice is studio only.
module.service('gaTrackerService', () => { });

// Shell has no $translate functionality (see react-component)
module.service('$translate', translateServiceStub);

export default module.name;
