import angular from 'angular';
import importHistoryTimeFilter from './import-history-time.filter';
import timeFromNowFilter from './time-from-now-filter';

const module = angular.module('app.common.filters', [

]);

module.filter('importHistoryTime', importHistoryTimeFilter);
module.filter('timeFromNow', timeFromNowFilter);
module.filter('uriEncode', [() => window.encodeURIComponent]);

export default module.name;
